import React, { useState, useEffect, useContext } from "react";
import { BiArrowBack, BiPlus } from "react-icons/bi";
import "./UtilitypageTwo.scss";
import dUtilitySetSingleEntry from "../../model/UtilitySetSingleEntry.json";
import midDataTable from "../../model/UtilitySetMidleTable.json";
import { useNavigate } from "react-router-dom";
import ExecutedTable from "../../components/ExecutedTable/ExecutedTable";
import Toast from "../../components/toast/Toast";
import { UserContext } from "../../hooks/UserContext";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
const UtilityPageTwo = () => {
  const { t } = useTranslation("utility");

  const { encodedUtilityId } = useParams();
  // console.log(encodedUtilityId)
  let navigate = useNavigate();
  const [midTableData, setMidTableData] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [utilitySetTables, setUtilitySetTables] = useState(
    dUtilitySetSingleEntry
  );
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const { isLoading, setLoading } = useContext(UserContext);
  const [blueColour, setBlueColour] = useState(false);
  const [selectedTableItem, setSelectedTableItem] = useState({});
  const handleInputChange = (index, field, value) => {
    const updatedData = [...editedData];
    updatedData[index][field] = value;
    setEditedData(updatedData);
  };
  const hideToast = () => {
    setShowToast(false);
  };
  const aFieldsTableRows =
    selectedTableItem.columns &&
    selectedTableItem.columns.map((row, index) => {
      return (
        <tr key={row.columnName}>
          <td className="data-1st-col">
            <div className="ano-col s font-4">{selectedTableItem.tableId}</div>
          </td>
          <td className="data-2nd-col">
            <div className="dash s"> - </div>
            <div className="col-data-2nd">
              <div className="d1 s font-4">
                {row.columnName}{" "}
                <span className="text s font-4">Text(100)</span>
              </div>
              <div className="d2 font-4">{row.description}</div>
            </div>
          </td>
          <td className="entries-col font-4">
            <div className="drop-1 one-1">
              <select
                className="select drop-edit font-4 s"
                onChange={(e) =>
                  handleInputChange(index, "name", e.target.value)
                }
              >
                <option value="1" className="drop-select font-4">
                  {row.utilityParameter}
                </option>
                <option value="2" className="drop-select font-4">
                  CONSISTENT TEXT
                </option>
                <option value="3" className="drop-select font-4">
                  SHUFFLE
                </option>
                <option value="4" className="drop-select font-4">
                  DATE SAME YEAR
                </option>
                <option value="5" className="drop-select font-4">
                  CLEAR VALUES
                </option>
                <option value="6" className="drop-select font-4">
                  PHONE
                </option>
                <option value="7" className="drop-select font-4">
                  EMAIL
                </option>
              </select>
            </div>
          </td>
          <td className="w-5 field-col font-4">
            <div className="drop-1 one-1">
              <select
                className="select w-5 drop-edit font-4 s"
                onChange={(e) =>
                  handleInputChange(index, "name", e.target.value)
                }
              >
                <option value="1" className="drop-select font-4">
                  {row.conditions}
                </option>
                <option value="2" className="drop-select font-4">
                  Conditions 1
                </option>
                <option value="3" className="drop-select font-4">
                  Conditions 2
                </option>
                <option value="4" className="drop-select font-4">
                  Conditions 3
                </option>
              </select>
            </div>
          </td>
          <td>
            <div className="mid-table-col-5">
              <button className="action-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M0.833252 9.9987C0.833252 9.9987 4.16658 3.33203 9.99992 3.33203C15.8333 3.33203 19.1666 9.9987 19.1666 9.9987C19.1666 9.9987 15.8333 16.6654 9.99992 16.6654C4.16658 16.6654 0.833252 9.9987 0.833252 9.9987Z"
                    stroke="#667085"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.99992 12.4987C11.3806 12.4987 12.4999 11.3794 12.4999 9.9987C12.4999 8.61799 11.3806 7.4987 9.99992 7.4987C8.61921 7.4987 7.49992 8.61799 7.49992 9.9987C7.49992 11.3794 8.61921 12.4987 9.99992 12.4987Z"
                    stroke="#667085"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <button className="action-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <g clipPath="url(#clip0_6470_50369)">
                    <path
                      d="M12.5001 7.5013L7.50008 12.5013M7.50008 7.5013L12.5001 12.5013M18.3334 10.0013C18.3334 14.6037 14.6025 18.3346 10.0001 18.3346C5.39771 18.3346 1.66675 14.6037 1.66675 10.0013C1.66675 5.39893 5.39771 1.66797 10.0001 1.66797C14.6025 1.66797 18.3334 5.39893 18.3334 10.0013Z"
                      stroke="#667085"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6470_50369">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
          </td>
        </tr>
      );
    });
  //Mid Table Action
  useEffect(() => {
    fetchMidTableData(); // name of the function
  }, []);

  const fetchMidTableData = async () => {
    // console.log("hii");
    if (midDataTable.rule === "Is: [List]") {
      setBlueColour(true);
    }
    const jsonData = await midDataTable(); //middatatable name import in line number
    setMidTableData(jsonData); // setmidtabledata name use usestate
    // console.log(midTableData);
  };

  const handleSave = () => {
    alert("Data has been saved");

    // console.log('Save button clicked');
    navigate("/newrun");
  };
  return (
    <>
      <Toast message={toastMessage} show={showToast} hideToast={hideToast} />
      <div className="main-section">
        <div className="header-section-maya">
          <div className="page-header">
            <div
              className="cursor-pointer res-back-btn font-5"
              onClick={() => navigate(-1)}
            >
              <BiArrowBack /> {t("Utility Sets")}
            </div>
            <div className="heading1 font-5">{t("step2")}</div>
          </div>
          <div className="header-button"></div>
        </div>
        <div className="body-section-maya">
          <ExecutedTable encodedUtilityId={encodedUtilityId} />
        </div>
      </div>
    </>
  );
};
export default UtilityPageTwo;
