import React, { useEffect, useState } from 'react';
import { BiEdit, BiSave, BiTrash } from 'react-icons/bi';
import TablePro from '../../../components/newTable/TablePro';
import User_sql_access from './User_sql_access';
import { useUserContext } from "../../../hooks/UserContext";
import { RxCross2 } from 'react-icons/rx';
import { IoMdCheckmark } from "react-icons/io";
import { get_sql_By_Pass, get_sql_queriess, get_user_name } from './permission_constant';
import Select from 'react-select';
import { selectDropdownStyle } from '../PII/PiiData';
import { reset_Sql_queries, revoke_User } from '../../../hooks/apis/settings';

const AdminPermission = () => {
    const { showToast } = useUserContext();
    const [addUserAccess, setAddUserAccess] = useState(false);
    const [editAccessBID, setEditAccessBID] = useState({ show: false, id: '' });
    const [sqlByPassHistory, setSqlByPassHistory] = useState([]);
    const [sqlByPassQueries, setSqlByPassQueries] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState({});
    const getHistory = async () => {
        const sqlByPassHistory = await get_sql_By_Pass();
        setSqlByPassHistory(sqlByPassHistory);
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                await getHistory()

                const sqlByPassQueries = await get_sql_queriess();
                setSqlByPassQueries(sqlByPassQueries);


            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const handleSelectChange = (selectedOptions, userId) => {
        setSelectedOptions(prevState => ({
            ...prevState,
            [userId]: selectedOptions
        }));
    };
    const handleSaveSqlQueries = (userId, email) => {
        const selectedForUser = selectedOptions[userId] || [];
        if (selectedForUser.length === 0) {
            setEditAccessBID({ show: false, id: null })
            return
        }
        const query = selectedForUser?.map(option => option.value);
        const dto = {
            email: email,
            queries: query
        }
        reset_Sql_queries(dto).then(res => {
            console.log("response after succesfullty setset quesries: ", res.message)
            showToast(res.message, 'success')
            getHistory();
            setEditAccessBID({ show: false, id: null })
        }).catch(err => {
            showToast(err.message)
        })

    }
    const onHandleDelete = (email) => {
        revoke_User(email).then(res => {
            getHistory()
            showToast(res.message, 'success')
        }).catch(err => {
            showToast(err.message, 'fail')
        })
    }
    const admin_permission_column = [
        {
            name: "Users",
            field: "userName",
            width: '10%',
        },
        {
            name: "Email",
            field: "email",
            width: '10%',
        },
        {
            name: "By Pass Sqls",
            width: '50%',
            type: "customControl",
            fnRenderCustomControl: (i) => {
                const initialSelectedOptions = i?.byPassSqls?.map(group => ({
                    value: group,
                    label: group
                }));

                return (
                    <>
                        {editAccessBID.show && editAccessBID.id === i.id ? (
                            <Select
                                value={selectedOptions[i.id] || initialSelectedOptions}
                                options={sqlByPassQueries}
                                className="select-dropdown"
                                placeholder={"select..."}
                                styles={selectDropdownStyle}
                                isMulti
                                menuPlacement="auto"
                                menuPortalTarget={document.body}
                                onChange={(selectedOptions) => handleSelectChange(selectedOptions, i.id)}
                            />
                        ) : (
                            <div className='font-4'>{i?.byPassSqls.join(', ')}</div>
                        )}
                    </>
                );
            }
        },
        {
            width: '10%',
            name: 'Actions',
            type: "customControl",
            fnRenderCustomControl: (i) => {
                return (
                    <div className='d-flex gap-3'>
                        {editAccessBID.show && editAccessBID.id === i?.id ? (
                            <>
                                <div className=" level-text cursor-pointer" onClick={() => handleSaveSqlQueries(i?.id, i?.email)}>
                                    <IoMdCheckmark size={23} />
                                </div>
                                <div className="level-text cursor-pointer" onClick={() => setEditAccessBID({ show: false, id: null })}>
                                    <RxCross2 size={23} />
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="level-text cursor-pointer" onClick={() => setEditAccessBID({ show: true, id: i.id })}>
                                    <BiEdit size={23} />
                                </div>
                                <div className="level-text cursor-pointer" onClick={() => onHandleDelete(i?.email)}>
                                    <BiTrash size={23} />
                                </div>
                            </>
                        )}
                    </div>
                );
            }
        }
    ];

    const displayTable = () => {
        if (!sqlByPassHistory) {
            return <div className='font-5'>No data available</div>;
        }

        if (typeof sqlByPassHistory !== 'object') {
            return <div className='font-5'>{sqlByPassHistory}</div>;
        }

        if (sqlByPassHistory.length === 0) {
            return <div className='font-5'>No records found</div>;
        }
        return <TablePro columns={admin_permission_column} data={sqlByPassHistory.sort(
            (a, b) =>
                new Date(b.createdAt) - new Date(a.createdAt)
        )} minRows={5}
            showRecords={true}
        />
    }

    return (
        <div className="admin-permissions pt-3">
            {addUserAccess && <User_sql_access isShow={setAddUserAccess} refresh={getHistory} />}
            <div className="d-flex justify-content-between">
                <h2 className="font-4">Admin Permissions</h2>
                <div className="d-flex gap-2">
                    <button className="btn-purple" onClick={() => {
                        if (editAccessBID.show) {
                            showToast("You allready trying to edit somthing")
                        }
                        else setAddUserAccess(true)
                    }}><BiSave size={20} /> Add Permissions</button>
                </div>
            </div>
            <div className="pt-2">
                {displayTable()}
            </div>
        </div>
    );
};

export default AdminPermission;
