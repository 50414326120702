import React, { useContext, useEffect, useState } from 'react'
import { Dropdown, dropdownConfigs, get_sql_queriess, get_user_name } from './permission_constant'
import { RxCross2 } from 'react-icons/rx';
import { BiSave } from 'react-icons/bi';
import { save_Sql_bypass } from '../../../hooks/apis/settings';
import { UserContext, useUserContext } from '../../../hooks/UserContext';

const User_sql_access = ({ isShow, refresh }) => {
    const { showToast } = useUserContext();
    const { setLoading } = useContext(UserContext);
    const [sqlByPassQueries, setSqlByPassQueries] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [errorInDropdown, setErrorInDropdown] = useState(false);

    const [userDetails, setuserDetails] = useState({});

    const handleSelectChange = (selectedOption, { name }) => {
        setSelectedOptions(prevOptions => ({
            ...prevOptions,
            [name]: selectedOption
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const sqlByPassQueries = await get_sql_queriess();
                setSqlByPassQueries(sqlByPassQueries);
                const users = await get_user_name()
                const formattedOptions = users.map(item => ({
                    label: item.name,
                    value: item.userEmailId
                }));
                setuserDetails(formattedOptions)
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const handleSaveClick = (e) => {
        e.preventDefault();

        if (selectedOptions.length === 0) {
            setErrorInDropdown(true);
            console.log("length is null")
            return;
        } else if (selectedOptions.length > 0) {
            if (selectedOptions?.byPassSqls?.length === 0 || selectedOptions?.action?.length === 0 || selectedOptions?.users?.length === 0) {
                setErrorInDropdown(true);
                return;

            } else {
                setErrorInDropdown(false)
            }
        }

        // Prepare DTO only if validation passes
        const dto = {
            byPassSqls: selectedOptions?.byPassSqls?.map(sql => sql.value),
            action: selectedOptions?.action?.value,
            users: selectedOptions?.users?.map(user => ({
                email: user.value,
                name: user.label
            }))
        };

        // Call save API
        setLoading(true)
        save_Sql_bypass(dto)
            .then(res => {
                refresh()
                showToast(res.message, 'success');
                handleResetClick()
            })
            .catch(err => {
                showToast(err.message, 'fail');
                console.log("Error on saving SQL:", err);
            }).finally(() => {
                setLoading(false)
            });
    };

    const handleResetClick = () => {
        setSelectedOptions({});  // Clear all selected dropdown options
        setErrorInDropdown(false);  // Clear any error message
    };

    return (
        <div className="panel-utility pl-2 h-100" style={{ padding: "16px 15px 12px 12px" }}>
            <div className="h-100 panel-screen gap-4 d-flex flex-column">
                <div className="w-100 d-flex justify-content-between align-items-center">
                    <h3 className="font-4">Add Permission</h3>
                    <div className="cursor-pointer"><RxCross2 size={25} onClick={() => isShow(false)} /></div>
                </div>
                <form onSubmit={handleSaveClick} className="h-100 d-flex flex-column justify-content-between pt-3">
                    {errorInDropdown && <label className="text-danger font-4 label-text">Please fill required field</label>}
                    <div className="d-flex flex-column gap-3">
                        {dropdownConfigs(sqlByPassQueries, userDetails).map((config, index) => (
                            <Dropdown
                                key={index}
                                {...config}
                                error={errorInDropdown}
                                onChange={(selectedOption, actionMeta) => handleSelectChange(selectedOption, actionMeta)}
                                value={selectedOptions[config.name] || null}
                            />
                        ))}
                    </div>
                    <div className="d-flex gap-4">
                        <button type='submit' className="btn-purple w-auto" ><BiSave size={18} /> Save</button>
                        <button type="button" className="btn-low-purple w-auto" onClick={handleResetClick}>Reset</button>
                    </div>

                </form>
            </div>
        </div>
    );

}


export default User_sql_access;
