import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const Basic_Info_Filesafe = ({show, setShow}) => {
  return (
    <>
          <Modal
              show={show}
              onHide={() => setShow(false)}
              dialogClassName="modal-90w"
              aria-labelledby="info-modal-title"
          >
              <Modal.Header closeButton>
                  <Modal.Title id="info-modal-title">Information</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <p>
                      This is a demo version to showcase the capability. To get your
                      file data anonymized, please engage with{" "}
                      <a href="mailto:info@mayadataprivacy.eu">
                          info@mayadataprivacy.eu
                      </a>{" "}
                      to start the process. Meanwhile, you may download the file in{" "}
                      <a
                          href="https://mayaforbusiness.eu/wp-content/uploads/2022/06/MAYA_demo_sample.csv"
                          download
                      >
                          link
                      </a>{" "}
                      and see one sample of how the data may look like after being
                      privacy preserved using MAYA software.
                  </p>
              </Modal.Body>
              <Modal.Footer>
                  <Button
                      className="btn btn-primary"
                      onClick={() => setShow(false)}
                  >
                      Close
                  </Button>
              </Modal.Footer>
          </Modal>
          </>
  )
}

export default Basic_Info_Filesafe
