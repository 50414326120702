import React, { useState } from "react";
import "./CircleProgressBar.scss";

/**
 *  default parameters defined for single circle bar use
 *  increasing number of circleCount will render more inner circles if parameters
 *  innerCircleIndicatorColors, innerCircleIndicatorProgress are set
 *
 */

const CircleProgressBar = (props) => {
  let {
    size = 150,
    progress = 0,
    trackWidth = 10,
    trackColor = `#ddd`,
    indicatorWidth = 10,
    indicatorColor = `#07c`,
    indicatorCap = `round`,
    label = `Loading...`,
    labelColor = `#333`,
    spinnerMode = false,
    spinnerSpeed = 1,
    hideLabel = false,
    circleCount = 3,
    innerCircleRadiusSpace = 20,
    innerCircleIndicatorColors = [],
    innerCircleIndicatorProgress = [],
    totalProgress = 0,
  } = props;

  if (
    circleCount !== 1 &&
    innerCircleIndicatorProgress.length !== circleCount - 1 &&
    innerCircleIndicatorColors.length !== circleCount - 1
  ) {
    circleCount = 1;
    console.error(
      "Incorrectly defined parameters: circleCount, innerCircleIndicatorColors, innerCircleIndicatorProgress! If you are not intending to render inner CircleProgressBars then remove CircleCount parameter."
    );
  }

  if (circleCount === 1) {
    totalProgress = progress;
  } else {
    innerCircleIndicatorProgress.forEach((element) => {
      totalProgress = totalProgress + element;
    });
    // totalProgress += progress;
  }

  if (!hideLabel) {
    hideLabel = size < 100 || !label.length || spinnerMode ? true : false;
  }

  const center = size / 2,
    radius =
      center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
    dashArray = 2 * Math.PI * radius;
  // let dashOffset;

  const elements = [];
  const [hoveredCircleIndex, setHoveredCircleIndex] = useState(null);

  for (let index = 0; index < circleCount; index++) {
    // let r = radius;
    // if (index === 0) {
    //   dashOffset = dashArray * ((100 - progress) / 100);
    // } else {
    //   dashOffset =
    //     dashArray * ((100 - innerCircleIndicatorProgress[index - 1]) / 100);
    //   r = radius - innerCircleRadiusSpace * index;
    //   indicatorColor = innerCircleIndicatorColors[index - 1];
      
    // } let r = radius - innerCircleRadiusSpace * index;
    let r = radius - innerCircleRadiusSpace * index;
    let currentProgress = index === 0 ? progress : innerCircleIndicatorProgress[index - 1];
    let currentColor = index === 0 ? indicatorColor : innerCircleIndicatorColors[index - 1];

    const dashArray = 2 * Math.PI * r;
    const dashOffset = dashArray * ((100 - currentProgress) / 100);


    elements.push(
      <circle
        key={"c" + index}
        className="svg-pi-track"
        cx={center}
        cy={center}
        fill="transparent"
        r={r}
        stroke={trackColor}
        strokeWidth={trackWidth}
      />
    );
    elements.push(
      <circle
        key={"i" + index}
        className={`svg-pi-indicator ${
          spinnerMode ? "svg-pi-indicator--spinner" : ""
        }`}
        style={{ animationDuration: spinnerSpeed * 1000 }}
        cx={center}
        cy={center}
        fill="transparent"
        r={r}
        // stroke={indicatorColor}
         stroke={currentColor}

        strokeWidth={indicatorWidth}
        strokeDasharray={dashArray}
        strokeDashoffset={dashOffset}
        strokeLinecap={indicatorCap}
        onMouseEnter={() => setHoveredCircleIndex(index)}
        onMouseLeave={() => setHoveredCircleIndex(null)}
      />
    );
  }

  return (
    <>
      <div
        className="svg-pi-wrapper cursor-pointer"
        style={{ width: size, height: size }}
      >
        <svg className="svg-pi" style={{ width: size, height: size }}>
          {elements}
        </svg>

        {hoveredCircleIndex !== null ? (
          <div className="svg-pi-label" style={{ color: labelColor }}>
            <span className="svg-pi-label__loading"></span>
            <span className="svg-pi-label__progress cursor-pointer">
              {hoveredCircleIndex === 0
                ? `${progress}%`
                : `${innerCircleIndicatorProgress[hoveredCircleIndex - 1]}%`}
            </span>
          </div>
        ) : (
          <div className="svg-pi-label" style={{ color: labelColor }}>
            <span className="svg-pi-label__loading"></span>
            {!spinnerMode && (
              <span className="svg-pi-label__progress">
                {`${totalProgress > 100 ? 100 : totalProgress}%`}
              </span>
            )}
          </div>
        )}

        {/* {hoveredCircleIndex !== null && (
          <div
            className="svg-pi-hover-label cursor-pointer"
            style={{ color: labelColor }}
          >
            {hoveredCircleIndex === 0
              ? `${progress}%`
              : `${innerCircleIndicatorProgress[hoveredCircleIndex - 1]}%`}
          </div>
        )} */}
      </div>
    </>
  );
};

export default CircleProgressBar;
