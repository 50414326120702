import React from 'react'
import { Modal, Button } from "react-bootstrap";
const Limitation = ({isShow, show}) => {
  return (<>
      <ul>
          <li>
              <span>Step 1:</span> Use upload file button{" "}
              <span>"Choose a File"</span> to bring your file headers on the
              screen
          </li>
          <li>
              <span>Step 2:</span> Choose what utility is desired after
              anonymization for data in a given field in file
          </li>
          <li>
              <span>Step 3:</span> Click on execute button to get your file
              anonymized
          </li>
      </ul>
      <p>
          In case you are not getting desired results for your file, please
          look into the{" "}
          <span
              className="limitations_conditions"
              onClick={() => isShow(true)}
          >
              Pre-conditions and Limitations
          </span>
      </p>
      <Modal
          show={show}
          centered={true}
          size="lg"
          onHide={() => isShow(false)}
      >
          <Modal.Header>
              <Modal.Title>
                  <h3>
                      <b>Pre-conditions and Limitations</b>
                  </h3>
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div className="dialog_preconditionsWrapper">
                  <span className="subHeader">Pre-conditions:</span>
                  <ul className="modalUl">
                      <li>XLSX or CSV file must have the first row as the header</li>
                      <li>
                          File with no header is processed as first row being header
                      </li>
                      <li>
                          Only first worksheet in an xlsx sheet can be processed and a
                          multiple sheet spreadsheet may not process as expected
                      </li>
                      <li>
                          Values in fields must match the expected utility parameter. If
                          a name field is expected to be retaining utility parameter
                          Email - the output file may have all such values omitted.
                      </li>
                      <li>
                          Only if the existing data is having the property as expected
                          in the utility parameter, then the anonymized file would
                          retain utility
                      </li>
                  </ul>
                  <span className="subHeader">Limitations:</span>
                  <ul className="modalUl">
                      <li>
                          Product may not offer all the algorithms as displayed in the
                          screen
                      </li>
                      <li>
                          Files of length longer than 100 records would be trimmed down
                          to only 100 records in the free / trial version of the product
                      </li>
                  </ul>
              </div>
          </Modal.Body>
          <Modal.Footer>
              <Button
                  variant="primary"
                  className="mdp-btn-primary"
                  onClick={() => isShow(false)}
              >
                  Close
              </Button>
          </Modal.Footer>
      </Modal>
  </>
  )
}

export default Limitation