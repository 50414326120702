import "./Toast.scss";
import CN from "classnames";
import { useState, useEffect } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { FiAlertTriangle, FiCheckCircle } from "react-icons/fi";
import { GrStatusWarning } from "react-icons/gr";

const Toast = ({ message, show = false, hideToast, delay = 30000, type = "info" }) => {
  const [toastClassList, setToastClassList] = useState("toast");

  useEffect(() => {
    if(type==='success'){delay=8000}
    if (show) {
      setToastClassList(
        CN({
          toast: true,
          show: true,
          success: type === "success",
          fail: type === "fail",
          warning: type === "warning",
        })
      );
      const timeoutId = setTimeout(() => {
        hide();
      }, delay);

      return () => clearTimeout(timeoutId);
    }
  }, [show, type, delay]);

  const hide = () => {
    setToastClassList(
      CN({
        toast: true,
        show: false,
        success: type === "success",
        fail: type === "fail",
        warning: type === "warning",
      })
    );
    if (typeof hideToast === "function") hideToast();
  };

  const getIcon = () => {
    switch (type) {
      case "success":
        return <FiCheckCircle />;
      case "fail":
        return <FiAlertTriangle />;
      case "warning":
        return <GrStatusWarning />;
      default:
        return <BsInfoCircle />;
    }
  };

  return (
    <>
      <div className={toastClassList}>
        <div className="toast-content d-flex  justify-content-between">
          <div className='toast-icon d-flex align-items-center' >{getIcon()}
            <p className="font-5 mb-0 ms-2">{message}</p>
          </div>
          <button className="close-btn" onClick={hide}>×</button>
        </div>
        <div className={`progress-bar `}>
          <div className={` progress ${type} `}></div>
        </div>
      </div>

    </>
  );
};

export default Toast;



