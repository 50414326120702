import React, { useEffect, useState } from 'react';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../../hooks/UserContext';
import TablePro from '../../../components/newTable/TablePro';
import { getUtilityPiiHistoryById } from '../../../hooks/apis/useAppSafeApi';
import { newTabForFailure } from './newTabFailure';

const DetailTablesData = ({ data, onCancel }) => {
    const navigate = useNavigate();
    const [detailsData, setDetailsData] = useState([] || '');
    const [detailsDataSDD, setDetailsDataSDD] = useState([] || '');
    const { setLoading } = useUserContext();

    useEffect(() => {
        setDetailsData("Loading...")

        getUtilityPiiHistoryById(data?.id, data?.type, data?.utilitySetId)
            .then((res) => {
                const SDD = res?.tables
                console.log("by id response:: ", res)
                if (data?.type === 'SDD') {
                    setDetailsDataSDD(res.master)
                }
                setDetailsData(data.type == 'SDD' ? SDD : res);
            })
            .catch((err) => {
                console.error('Error fetching data: ', err);
                setDetailsData('Error fetching data')
            })
            .finally(() => {
                setLoading(false);
            });
    }, [data]);

    

    const columns = [
        {
            name: 'Table Name',
            field: 'tableName',
            type: 'customControl',
            fnRenderCustomControl: (item) => (
                <span className={`font-5 ${item?.status?.toLowerCase() === 'failed' && 'cursor-pointer'}`}>
                    {item?.tableName}
                </span>
            ),
        },
        data.type === 'SDD' && {
            name: 'Field Name',
            field: 'fieldName',
        },
        data.type === 'SDD' && {
            name: 'Distint Count',
            field: 'distintCount',
        }, data.type === 'SDD' && {
            name: 'Percentage Match',
            field: 'percentageMatch',
        },
        data.type === 'PII' && {
            name: 'Status',
            field: 'status',
            type: 'customControl',
            fnRenderCustomControl: (item) => {
                return (
                    newTabForFailure(item)
                );
            }
        },
    ];
    const displayTable = () => {
        if (!detailsData) {
            return <div className='font-5'>No data available</div>;
        }

        if (typeof detailsData !== 'object') {
            return <div className='font-5'>{detailsData}</div>;
        }

        if (detailsData.length === 0) {
            return <div className='fonr-5'>No records found</div>;
        }
        console.log(detailsData)
        return <TablePro
            columns={columns}
            data={detailsData.sort((a, b) => new Date(b.startTime) - new Date(a.startTime))}
            minRows={4}
        />
    }
    return (
        <>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '1050' }} className="modal-dialog modal-dialog-centered" role="document">
                <div>
                    <div className="modal-content" style={{ border: '1px solid var(--gray-300, #D0D5DD)' }}>
                        <div className="modal-header">
                            <h5
                                className="modal-title font-5 cursor-pointer"
                                onClick={() => navigate('/utilitySetHomePage')}
                            >
                                <BsFillArrowRightCircleFill /> {data?.name}
                            </h5>
                            <button
                                onClick={() => onCancel({ id: '', name: '' })}
                                type="button"
                                className="close btn btn-transparent border-0 font-6"
                                aria-label="Close"
                            >
                                <span aria-hidden="true" className="font-6">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body font-4">
                            {data.type === 'SDD' && <> <div className="d-flex">

                                <div className="font-4 list-unstyled">
                                    <li className='font-4'>Lead FieldName : {detailsDataSDD?.leadFieldName}</li>
                                    <li className='font-4'>Lead TableName : {detailsDataSDD?.leadTableName}</li>
                                    <li className='font-4'>Threshold Value : {detailsDataSDD?.thresholdValue}</li>
                                    <li className='font-4'>status : {newTabForFailure(detailsDataSDD)}</li>
                                </div>
                            </div></>}
                            {displayTable()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DetailTablesData;
