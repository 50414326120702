import React from 'react'
import Uper from './Uper'
import './Style.css'
import Datatable from './Datatable'

function Dashboard() {
  return (
    <div>
 

      {/* Uper_Section  */}
      <div className='mainsection'><Uper /></div>


      {/* Lower_section */}
      <div className='mainsectiontwo'><Datatable /></div>

      

    </div>
  )
}

export default Dashboard
