import decodeJwt from "./decodeJwt";

const getUserData = async (setFirstName) => {
  const sInfo = localStorage.getItem("info");
  if (sInfo && sInfo.length > 15) {
    const oUser = JSON.parse(sInfo);
    const decodedInfo = decodeJwt(oUser.jwtToken);
    setFirstName(decodedInfo.fname);
  }
};

export default getUserData;
