import React, { useState, useContext, useEffect } from "react";
import { RxCross1 } from "react-icons/rx";
import { UserContext, useUserContext } from "../../hooks/UserContext";
import { serverAxios } from "../../utils/AxiosUtils";
import { useTranslation } from "react-i18next";

const AddTableUtility = ({ refresh, isPanel, utilityId, utilityTableId }) => {
  const { t } = useTranslation("utility");

  const { showToast } = useUserContext();
  const { isLoading, setLoading } = useContext(UserContext);
  const [tableData, setTableData] = useState({});
  const [formFieldsFirstTable, setFormFieldsFirstTable] = useState({
    tableName: "",
    tableId: "",
    fields: "",
  });
  const getUtilityTableData = () => {
    serverAxios.appSafeAxios
      .get(`utility-set/${utilityId}/tables/${utilityTableId}`)
      .then((res) => {
        setFormFieldsFirstTable(res.data.data);
      });
  };
  useEffect(() => {
    if (utilityTableId) {
      getUtilityTableData();
    }
  }, []);
  const onSaveAddTableData = () => {
    // e.preventDefault();
    if (formFieldsFirstTable.tableName === "") {
      showToast("Table name is empty...");
    } else {
      const url = utilityTableId
        ? `utility-set/${utilityId}/tables/${utilityTableId}`
        : `utility-set/${utilityId}/tables`;
      setLoading(true);
      serverAxios.appSafeAxios
        .post(url, formFieldsFirstTable, {
          "Content-Type": "application/json",
        })
        .then((res) => {
          if (res.data.success) {
            showToast(res.data.message, "success");
            setLoading(false);
            isPanel(false);
            refresh();
            let tableData = {};
          } else {
            showToast(res.data.message);
            setLoading(false);
          }
        })
        .catch((oError) => {
          setLoading(false);
          showToast(
            "Failed to submit data. Please try again later or contact support if the issue persists.",
            "fail"
          );
          showToast(oError.message, "fail");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const handleInputChangeFisrtAddTable = (event) => {
    const { name, value } = event.target;
    setFormFieldsFirstTable((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <>
      <div className="panel-utility">
        <div className="panel-screen">
          <div className="header-panel">
            <div className="left font-5">
              {utilityTableId ? t("Update a Table") : t("Add a Table")}
            </div>
            <div className="right">
              <RxCross1
                className="cross cursor-pointer"
                onClick={() => isPanel(false)}
              />
            </div>
          </div>
          <div className="field-group-section-panel">
            <div className="field-section-panel">
              <label className="level-text font-5">{t("Table Name")} *</label>
              <input
                type="text"
                name="tableName"
                value={formFieldsFirstTable.tableName}
                onChange={handleInputChangeFisrtAddTable}
                className=" width-of-panel-input input-field-shadow font-4 "
                placeholder={t("Name...")}
              />
            </div>
            <div className=" field-section-panel">
              <label className="level-text font-5">{t("Table ID")}</label>
              <input
                type="text"
                name="tableId"
                value={formFieldsFirstTable.tableId}
                onChange={handleInputChangeFisrtAddTable}
                className="width-of-panel-input input-field-shadow font-4 "
                placeholder={t("ID")}
              />
            </div>
            {!utilityTableId && (
              <div className="field-section-panel ">
                <label className=" level-text font font-5">
                  {t("FieldsSep")}
                </label>
                <input
                  type="text"
                  name="fields"
                  value={formFieldsFirstTable.fields}
                  onChange={handleInputChangeFisrtAddTable}
                  className="width-of-panel-input input-field-shadow font-4 "
                />
              </div>
            )}
          </div>
          <div className="footer-panel">
            <button className="btn-purple font-6" onClick={onSaveAddTableData}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M14.1667 17.5V10.8333H5.83333V17.5M5.83333 2.5V6.66667H12.5M15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H13.3333L17.5 6.66667V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5Z"
                  stroke="#FCFAFF"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {utilityTableId ? t("Save & Update") : t("Save")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTableUtility;
