import {useEffect, useState} from "react";
import {getRecaptcha} from "../api/AuthApi";

export default function useRecaptcha() {
    const [recaptchaImage, setRecaptchaImage] = useState(null);
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [recaptchaError, setRecaptchaError] = useState("Loading...");
    const [reload, setReload] = useState(false);
    useEffect(() => {
        getRecaptcha().then((res) => {
            setRecaptchaImage(res.image);
            setRecaptchaToken(res.token);
        }).catch((err) => {
            console.log("Recaptcha get error ", err
                .message);
                setRecaptchaError("Reload Captcha' error.");
        });
    }, [reload]);

    const reloadRecaptcha = () => {
        setRecaptchaError("Reload...")
        setReload(prev => !prev);
    }

    return {recaptchaToken, recaptchaImage, reloadRecaptcha, recaptchaError};
}