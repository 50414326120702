import React, { useEffect, useState } from "react";
import "./Dashboard.scss";

import Report from "./Report";
import Connections from "./Connections";
import Collaboration from "./Collaboration";
import Compliance from "./Compliance";

const Dashboard = () => {
  const [firstName, setFirstName] = useState("");

  useEffect(() => {
    getUserData();
  }, []);

  const decodeJwt = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("")
    );

    return JSON.parse(jsonPayload);
  };

  const getUserData = async () => {
    const sInfo = localStorage.getItem("info");
    if (sInfo && sInfo.length > 15) {
      const oUser = JSON.parse(sInfo);
      const decodedInfo = decodeJwt(oUser.jwtToken);
      setFirstName(decodedInfo.fname);
    }
  };

  return (
    <div className="dashboard-over">
      <div className="dashboard-header">
        <h1 className="dashboard-welcome-message">Welcome, {firstName}</h1>
        <h3 className="dashboard-subtitle font-4">this year</h3>
      </div>
      <div className="dashboard-boxed-container">
        <div className="dashboard-info-boxes">
          <Compliance />
          <Report />
        </div>
        <div className="dashboard-info-boxes">
          <Connections />
          <Collaboration />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
