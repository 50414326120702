import { type } from "@testing-library/user-event/dist/type";

export const SUPPORTED_FILE_TYPES = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "text/csv",
  //   "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  //   "application/vnd.ms-excel.sheet.macroEnabled.12",
  //   "application/vnd.ms-excel.template.macroEnabled.12",
  //   "application/vnd.ms-excel.addin.macroEnabled.12",
  //   "	application/vnd.ms-excel.sheet.binary.macroEnabled.12"
];
export const ConfigurationType = [
  { value: "HANA", label: "SAP 4/HANA" },
  { value: "SHAREPOINT", label: "SharePoint" },
  { value: "POSTGRES", label: "POSTGRES" },
  { value: "ORACLE", label: "ORACLE" }
];
export const backupHistoryColumns = [
  {
    name: "Config Set",
    field: "configSetName",
    type: "text",

  },
  {
    name: "Table Name",
    field: 'tableName',
    type: 'text'
  },
  {
    name: 'Backup Schema',
    field: 'backupSchema',
    type: 'text',
  },
  {
    name: 'Schema Name',
    field: 'schemaName',
    type: 'text',
  },
  {
    name: 'Entries',
    field: 'entries',
    type: 'text',
  },
  {
    name: 'Created At',
    field: 'createdDate',
    type: 'date'
  },
  {
    name: 'Status',
    field: 'status',
    type: 'text',
  }
]
