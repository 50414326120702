import "./Pagination.scss";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import CN from "classnames";
import { useTranslation } from "react-i18next";

const PaginationItem = ({ page, currentPage, onPageChange }) => {
  const liClasses = CN({
    "page-item": true,
    active: page === currentPage,
  });
  return (
    <li className={liClasses} onClick={() => onPageChange(page)}>
      <span className="page-link">{page}</span>
    </li>
  );
};

const Pagination = ({ currentPage, total, limit, onPageChange }) => {
  const { t } = useTranslation("utility");

  const range = (start, end) => {
    return [...Array(end).keys()].map((item) => item + start);
  };
  const pagesCount = Math.ceil(total / limit);
  const pages = range(1, pagesCount);
  const previousPageClasses = CN({
    disabled: currentPage === 1,
  });
  const nextPageClasses = CN({
    disabled: currentPage === pagesCount,
  });

  const renderPages = () => {
    let aPages = [];
    let slicedPages;
    if (!(currentPage > pagesCount - 3)) {
      slicedPages = pages.slice(currentPage - 1, currentPage + 2);
    } else {
      slicedPages = pages.slice(pagesCount - 3, pagesCount);
    }

    aPages = [
      slicedPages.map((page, index) => {
        return (
          <PaginationItem
            page={page}
            key={page}
            currentPage={currentPage}
            onPageChange={onPageChange}
          />
        );
      }),
    ];
    // }
    return aPages;
  };

  return (
    <div className="paginationWrapper">
      <ul className="page">
        <li
          className={previousPageClasses}
          onClick={() => onPageChange(currentPage - 1)}
        >
          <span>
            <GrFormPrevious className="navArrows" />
          </span>
        </li>
        <li className={previousPageClasses} onClick={() => onPageChange(1)}>
          <span className="page-link">{t("First")}</span>
        </li>
        {renderPages()}
        <li
          className={nextPageClasses}
          onClick={() => onPageChange(pagesCount)}
        >
          <span className="page-link">{t("Last")}</span>
        </li>
        <li
          className={nextPageClasses}
          onClick={() => onPageChange(currentPage + 1)}
        >
          <span>
            <GrFormNext className="navArrows" />
          </span>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
