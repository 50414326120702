import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchChatHistory, chat, anonimise } from "../../hooks/apis/aiSafe";
import SidebarAiSafe from "./SidebarAiSafe";
import "./Chat.scss";

import { LuSendHorizonal } from "react-icons/lu";

import img1 from "../../images/setting.svg";
import ConversationEntry from "./main/ConversationEntry";
import ChatHistory from "./main/ChatHistory";
import PrivacySettings from "./main/PrivacySettings";
import ThemePopup from "./ThemePopup";
import { FiMenu } from "react-icons/fi";
import { GoPaperclip } from "react-icons/go";
import { serverAxios } from "../../utils/AxiosUtils";
import PromptHighlighter from "./textHighliter";

const Chat = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [history, setHistory] = useState([]);
  const [error, setError] = useState(null);

  const [privacySettings, setPrivacySettings] = useState({
    Names: true,
    Emails: true,
    Phone: false,
  });
  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [piiDetection, setPiiDetection] = useState({
    Names: [],
    Emails: [],
    Phones: [],
  });
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [hasLanded, setHasLanded] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showFileUploadPopup, setShowFileUploadPopup] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [idchat, setIdChat] = useState(null);
  const [modelLanguage, setModelLanguage] = useState(() => {
    return localStorage.getItem("modelLanguage") || "eng";
  });
  const [isAnonymized, setIsAnonymized] = useState(false);

  const [isEditing, setIsEditing] = useState(1);

  const conversationHistoryRef = useRef(null);
  const textareaRef = useRef(null);
  const sidebarRef = useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCheckboxChange = (key) => {
    setPrivacySettings((prevSettings) => ({
      ...prevSettings,
      [key]: !prevSettings[key],
    }));
  };

  useEffect(() => {
    const getChatHistory = async () => {
      try {
        const historyData = await fetchChatHistory(id);
        setHistory(historyData);
      } catch (error) {
        setError(error.message);
      }
    };

    if (id) {
      getChatHistory();
    }
  }, [id]);

  const handleLanguageChange = (language) => {
    setModelLanguage(language);
    localStorage.setItem("modelLanguage", language);
  };
  useEffect(() => {
    const storedLanguage = localStorage.getItem("modelLanguage");
    if (storedLanguage) {
      setModelLanguage(storedLanguage);
    }
  }, []);
  let tp;
  const handleAnonymize = async () => {
    const trimmedPrompt = prompt.trim();
    if (!trimmedPrompt) return;

    setLoading(true);
    try {
      const choices = Object.keys(privacySettings).filter(
        (key) => privacySettings[key]
      );
      const response = await anonimise(trimmedPrompt, choices, modelLanguage);
      // setPrompt(response[0]);

      tp = trimmedPrompt;

      const tempEntry = {
        originalPrompt: trimmedPrompt,
        anonymizedPrompt: response[0],
        chatResponse: "Waiting for chat...",
        detectedPrompt: response[1],
      };
      const piiDetect = {
        Names: response[1]?.Names ?? [],
        Phones: response[1]?.Phone ?? [],
        Emails: response[1]?.Emails ?? [],
      };
      setPiiDetection(piiDetect);
      setConversationHistory((prevHistory) => [...prevHistory, tempEntry]);
      setIsAnonymized(true);
    } catch (error) {
      setError("An error occurred during anonymization: " + error.message);
    } finally {
      setLoading(false);
    }
  };
  const pt = prompt;
  let isTrue = "False";

  const handleChat = async () => {
    const trimmedPrompt = prompt.trim();
    if (!trimmedPrompt) return;
    setPiiDetection({});
    // Compare after prompt has been anonymized
    const isNewPrompt = tp !== trimmedPrompt ? "True" : "False";

    setLoading(true);
    setError("");

    try {
      const choices = Object.keys(privacySettings).filter(
        (key) => privacySettings[key]
      );

      const dock = idchat ? idchat : "False";
      // Send the chat with the correct flag
      const chatResponse = await chat(
        trimmedPrompt,
        choices,
        id,
        dock,
        modelLanguage,
        isNewPrompt // Use the flag based on whether prompt has changed
      );

      const { data, message, response_code, success } = chatResponse;

      if (response_code !== 200 || !success) {
        throw new Error(message);
      }

      const newEntry = {
        originalPrompt: prompt,
        anonymizedPrompt: data[2] || "No anonymized prompt available",
        chatResponse: data[4] || "No response available",
      };

      // Update conversation history
      setConversationHistory((prevHistory) =>
        prevHistory.slice(0, -1).concat(newEntry)
      );

      setPrompt("");
      setIsAnonymized(false);

      tp = trimmedPrompt;
    } catch (error) {
      setError(
        "An error occurred: " +
          (error.response?.status === 500
            ? "Internal Server Error"
            : error.message)
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [prompt]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
    // setPrompt("");
  }, [prompt]);

  const showSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const closeSidebar = () => {
    setSidebarVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        !event.target.closest(".button_ai_safe_main2")
      ) {
        closeSidebar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const changeTheme = (newTheme) => {
    setTheme(newTheme);
    setIsPopupOpen(false);
  };

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("File selected:", file);

      setIsUploading(true); // Start uploading spinner

      // Convert the file into binary (ArrayBuffer)
      const arrayBuffer = await file.arrayBuffer();

      try {
        // Send the file as binary data to the backend
        const response = await serverAxios.aiSafeAxios.post(
          "/upload",
          arrayBuffer,
          {
            headers: {
              "Content-Type": "application/octet-stream",
              "Content-Disposition": `attachment; filename="${file.name}"`,
            },
          }
        );

        if (response.data.success) {
          console.log("File uploaded successfully:", response.data);
          console.log("hello", response.data.data);
          setPrompt((prev) => `${prev}\n[File uploaded: ${file.name}]`);
          setIdChat(response.data.data);
          setShowFileUploadPopup(false);
        } else {
          throw new Error(response.data.message || "File upload failed");
        }
      } catch (error) {
        console.error("Error in file upload:", error.message);
        setError("File upload failed. Please try again.");
      } finally {
        setIsUploading(false); // Stop uploading spinner
      }
    }
  };

  const handleIsChatAnonymized = () => {
    if (isEditing === 1) {
      setIsEditing(0);
    } else {
      setIsEditing(1);
    }
    // setPrompt("");
  };

  const handleSubmitChat = async () => {
    const trimmedPrompt = prompt.trim();
    if (!trimmedPrompt) return;

    setIsSubmitting(true); // Disable interactions
    setPrompt(""); // Clear the prompt immediately

    try {
      if (isEditing === 0) {
        if (!isAnonymized) {
          await handleAnonymize();
        } else {
          await handleChat();
        }
      } else {
        await handleAnonymize();
        await handleChat();
      }
    } finally {
      setIsSubmitting(false); // Re-enable interactions after chat completes
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey && !isSubmitting) {
      e.preventDefault();
      handleSubmitChat();
    } else if (e.key === "Enter" && e.shiftKey) {
      // setPrompt((prev) => prev + "\n");
    }
  };

  const handleButtonClick = () => {
    if (!isSubmitting) {
      handleSubmitChat();
    }
  };

  return (
    <>
      <div
        className={`ai_safe_sidebar ${sidebarVisible ? "visible" : ""}`}
        ref={sidebarRef}
      >
        <div className="ai_safe_sidebar-content">
          <PrivacySettings
            privacySettings={privacySettings}
            handleCheckboxChange={handleCheckboxChange}
            closeSidebar={closeSidebar}
            modelLanguage={modelLanguage} // Pass the selected language
            handleLanguageChange={handleLanguageChange}
            handleIsChatAnonymized={handleIsChatAnonymized}
          />
        </div>
      </div>

      <div className="head_chat">
        <div className="hamburger_icon" onClick={toggleSidebar}>
          <FiMenu />
        </div>
        <SidebarAiSafe
          isOpen={isSidebarOpen}
          onSettingsClick={togglePopup}
          onClose={toggleSidebar}
        />
        <div className="main_chat">
          {error && (
            <div className="font-4" style={{ color: "#FECDCA" }}>
              {" "}
              {error}
            </div>
          )}
          <div className="chat_chat">
            <div>
              {
                history.length > 0 &&
                  history
                    .filter(
                      (entry, index) =>
                        !(index === 0 && entry.role === "assistant")
                    )
                    .map((entry, index) => (
                      <div key={index} className={`chat-entry ${entry.role}`}>
                        <ChatHistory key={index} children={entry} />
                      </div>
                    ))
                // :!error&& (
                //   <p>No chat history available.</p>
                // )
              }
            </div>
            <div className="conversation_chat" ref={conversationHistoryRef}>
              {conversationHistory.map((entry, index) => (
                <ConversationEntry key={index} entry={entry} />
              ))}
            </div>
          </div>
          {/* </div> */}
          <div className="bottom_top_ai_safe">
            <div className="bottom_ai_safe_main d-flex flex-column gap-2 ">
              {(piiDetection?.Names?.length > 0 ||
                piiDetection?.Phones?.length > 0 ||
                piiDetection?.Emails?.length > 0) &&
              isAnonymized &&
              isEditing === 0 ? (
                <PromptHighlighter
                  prompt={prompt}
                  piiDetectionList={piiDetection}
                  updatedPrompt={setPrompt}
                  setPii={setPiiDetection}
                />
              ) : (
                <div className="w-100 textarea_container">
                  <div
                    onClick={() => setShowFileUploadPopup(true)}
                    className="upload_button "
                  >
                    <GoPaperclip size={25} />
                  </div>

                  <textarea
                    ref={textareaRef}
                    className="input_ai_safe"
                    placeholder="Enter your prompt here..."
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    onKeyDown={handleKeyPress}
                    rows={1}
                  />

                  {showFileUploadPopup && (
                    <div className="file-upload-popup">
                      <div className="popup-content">
                        <h3>Upload your file</h3>
                        {isUploading ? (
                          <div className="circular-loader">
                            <div className="spinner"></div>
                            <p>Uploading...</p>
                          </div>
                        ) : (
                          <input
                            type="file"
                            onChange={(e) => handleFileUpload(e)}
                          />
                        )}
                        <button onClick={() => setShowFileUploadPopup(false)}>
                          Close
                        </button>
                      </div>
                    </div>
                  )}
                  <div>
                    <div
                      className="left_20 button_ai_safe_main2"
                      onClick={showSidebar}
                    >
                      <img
                        src={img1}
                        alt=""
                        height={35}
                        width={35}
                        className="img_ai_safe"
                      />
                    </div>
                    <button
                      className="button_ai_safe_main"
                      onClick={handleButtonClick}
                      disabled={loading}
                    >
                      <div className="logo_ai_safe_main">
                        <LuSendHorizonal />
                      </div>
                    </button>
                  </div>
                </div>
              )}
              {/* <div className="d-flex justify-content-between">
                <label
                  className=" font-4"
                  style={{ fontSize: "0.8rem", color: "#FECDCA" }}
                >
                  {" "}
                  {piiDetection?.Names?.length === 0 &&
                    piiDetection?.Phones?.length === 0 &&
                    piiDetection?.Emails?.length === 0 &&
                    isAnonymized &&
                    isEditing === 0 &&
                    "No PII detected"}
                </label>
                <label
                  className=" d-flex gap-2 justify-content-end align-items-center"
                  style={{ color: "#b4b4b4" }}
                >
                  <input
                    type="checkbox"
                    checked={privacySettings.isChatAnonymized}
                    onChange={handleIsChatAnonymized}
                    className="pii-checkbox"
                  />
                  <span
                    className=" font-4"
                    style={{ fontSize: "0.8rem", color: "#b4b4b4" }}
                  >
                    Edit PII before chat.
                  </span>
                </label>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <ThemePopup
        isOpen={isPopupOpen}
        onClose={togglePopup}
        onThemeChange={changeTheme}
        currentTheme={theme}
      />
    </>
  );
};

export default Chat;
