export const PiiDetectionData = [
    {
        title: "Names",
        id: "name",
        label: "NAME",
        subHead: "Detect First and Last Names"
    },
    {
        id: "email",
        title: "Emails",
        label: 'EMAIL',
        subHead: "Detect Emails in any format"

    },
    {
        id: "date",
        title: "Dates",
        label: 'DATE',
        subHead: "Detect Dates in any format"
    }
]
export const piiHead = {
    head: "PII Detection",
    subHead: "Set up PII Detection, Classification and Transformation rules. They will be used as default settings when you use Automated PII Detection while creating your utility sets. These settings can be applied on all types of utility sets, applicable to databases, tabular files, free text or images."
}
export const initialPiiFormData = {
    name: createInitialFieldData(),
    email: createInitialFieldData(),
    date: createInitialFieldData()
};
function createInitialFieldData() {
    return {
        utilityParam: {},
        utilityParamConditions: [],
        isThreShold: false,
        threShold: "50",
        longText: 'wholeValue',
        autoDetect: 'replaceIndividually'
    };
}
export const longTextSectionData = (label) => ({
    head: 'Long Text',
    firstRadio: 'If a cell contains long text',
    label1: `Only detect if the whole value is a ${label}`,
    label2: `Also detect ${label} being parts of a longer text`,
    secondRadio: `If ${label} is detected as a part of the field`,
    label3: `Apply utility settings to the whole field`,
    label4: `Keep checking for other types of PII in that field and replace them individually`,
});
export const selectDropdownStyle = {
    container: (provided) => ({
        ...provided,
        width: '100%',
        // marginBottom: '1rem',
    }),
    control: (provided, state) => ({
        ...provided,
        borderColor: '#D0D5DD',
        border: '1px solid #D0D5DD',
        boxShadow: '0px 1px 2px 0px #1018280D',
        // padding: '10px 14px',
        // gap: '8px',
        borderRadius: '8px',
        color: '#344054',
        // borderColor: state.isFocused ? '#2684FF' : '#D1D1D1',
        // boxShadow: state.isFocused ? '0 0 0 1px #2684FF' : 'none',
        '&:hover': {
            borderColor: '#2684FF',
        },
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#2684FF' : state.isFocused ? '#E9F5FF' : '#FFF',
        color: state.isSelected ? state.isFocused ? 'black' : '#FFF' : '#333',
        '&:hover': {
            backgroundColor: '#E9F5FF',
        },
        padding: '10px 20px',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#344054',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#344054',
    }),
};
export function updateFormDataWithFetchedData(fetchedData, currentFormData) {
    const updatedFormData = { ...currentFormData };
    const data = ['name', 'date', 'email']
    fetchedData.forEach(({ level, value, utilityParamConditions }) => {
        const lowerCaseLevel = data;

        if (updatedFormData[lowerCaseLevel]) {
            updatedFormData[lowerCaseLevel].utilityParam = { level, value, utilityParamConditions };
        }
    });

    return updatedFormData;
}