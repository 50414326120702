// CodeBlock.js
import React, { useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { okaidia } from "react-syntax-highlighter/dist/esm/styles/prism";
import Notification from "./Notification";

const CodeBlock = ({ language, value }) => {
  const [notification, setNotification] = useState({
    visible: false,
    message: "",
  });

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setNotification({ visible: true, message: "Code copied to clipboard!" });
    setTimeout(() => {
      setNotification({ visible: false, message: "" });
    }, 2000); // Show notification for 2 seconds
  };

  return (
    <div style={{ position: "relative", margin: "10px 0" }}>
      <button
        onClick={handleCopy}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          backgroundColor: "#007bff",
          color: "#fff",
          border: "none",
          borderRadius: "3px",
          padding: "5px 10px",
          cursor: "pointer",
        }}
      >
        Copy
      </button>
      <SyntaxHighlighter
        language={language}
        style={okaidia}
        customStyle={{
          borderRadius: "5px",
          padding: "10px",
          backgroundColor: "#2d2d2d",
        }}
      >
        {value}
      </SyntaxHighlighter>
      <Notification
        message={notification.message}
        visible={notification.visible}
      />
    </div>
  );
};

export default CodeBlock;
