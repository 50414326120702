export const initialFormDataForAI = {
    tablesName: "",
    configSetId: "",
}
export const handleValidationError = (linesSensitiveData, utilityId, aiCreation, formData, formDataForAI) => {
    if (formData.utilitySetName === '') return false;
    else if (aiCreation) {
        if (formDataForAI.configSetId === '') return false;
        else if (linesSensitiveData.length === 0) {
            if (formDataForAI.tablesName === '') return true;
            else return true;
        }
        else if (linesSensitiveData.length > 0) {
            for (let line of linesSensitiveData) {
                if (line.leadTable === '' || line.utilityParam.length === 0 || line.threshold === '') {
                    return false;
                }
            }
            return true;
        }
    } else if (!aiCreation) {
        return true;
    }


    return false;
}