import React, { useState, useEffect } from "react";
import "../Utility/UtilitySetHomePage";
import TablePro from "../../components/newTable/TablePro";

import StatisticCard from "../../components/StatisticCard/StatisticCard";
import GraphComponent from "../../components/GraphComponent/GraphComponent";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";

import { useUserContext } from "../../hooks/UserContext";
import Popup from "../../components/popupModel/Popup";
import { serverAxios } from "../../utils/AxiosUtils";
import { useTranslation } from "react-i18next";
import { copyConfigSet } from "../../hooks/apis/useAppSafeApi";
const { REACT_APP_SITE_TITLE } = process.env;

const ConfigurationHome = () => {
  const { t } = useTranslation("config");

  const { showToast } = useUserContext();
  const [configurationSets, setConfigurationSets] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  let navigate = useNavigate();

  useEffect(() => {
    getConfigSet();
  }, []);
  const getConfigSet = () => {
    serverAxios.appSafeAxios
      .get("config-set")
      .then((res) => {
        const receivedData = res.data;
        if (!receivedData.success) {
          throw new Error(receivedData);
        }
        setConfigurationSets(receivedData.data);
        setFilteredData(receivedData.data);
      })
      .catch((err) => {
        console.error("The execution set error ->", err);
      })
      .finally(() => {});
  };
  const handleDeleteConfigRow = (id) => {
    serverAxios.appSafeAxios
      .post(`config-set/${id}/delete`, {
        "content-type": "application/json",
      })
      .then((res) => {
        const receivedData = res.data;
        console.log("config Delete details ->", receivedData);
        showToast(receivedData.message,'success');
        getConfigSet();
      })
      .catch((err) => {
        console.error("config Delete error ->", err);
        showToast(err.message, "fail");
      })
      .finally(() => {});
  };

  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState("");
  const [isArchivable, setIsArchivable] = useState(false);
  const [idToBeDelete, setIdToBeDelete] = useState("");
  const handleOpenPopup = (text, data) => {
    setPopupText(text);
    setShowPopup(true);
    setIsArchivable(data.archivable);
    setIdToBeDelete(data.id);
  };
  const handleConfirm = (result) => {
    if (result) {
      handleDeleteConfigRow(idToBeDelete);
      setShowPopup(false);
    } else {
      setShowPopup(false);
    }
  };
  const calculateNoConfigType = () => {
    const databaseSet = new Set();
    const fileSystemSet = new Set();
    configurationSets.forEach(item => {
      if (item?.configSetType === "DATABASE") {
        if (item?.sourceConfig?.databaseType) {
          databaseSet.add(item?.sourceConfig?.databaseType);
        }
        if (item?.targetConfi?.databaseType) {
          databaseSet.add(item?.targetConfi?.databaseType);
        }

      }

      if (item?.configSetType === "FTP") {
        if (item?.sourceConfig?.fileSystemType) {
          fileSystemSet.add(item?.sourceConfig?.fileSystemType);
        }
        if (item?.targetConfig?.fileSystemType) {
          fileSystemSet.add(item?.targetConfig?.fileSystemType);
        }
      }
    });
    return databaseSet.size + fileSystemSet.size || 0
  };
  const calculateNoConfigHost = () => {
    const hostSet = new Set();
    const directoryPath = new Set();
    configurationSets.forEach(item => {
      if (item?.configSetType === "DATABASE") {
        if (item?.sourceConfig?.config?.databaseHost) {
          hostSet.add(item?.sourceConfig?.config?.databaseHost);
        }
        if (item?.targetConfi?.config?.databaseHost) {
          hostSet.add(item?.targetConfi?.config?.databaseHost);
        }
      }
      if (item?.configSetType === "FTP") {
        if (item?.sourceConfig?.config?.directoryPath) {
          directoryPath.add(item?.sourceConfig?.config?.directoryPath);
        }
        if (item?.targetConfig?.config?.directoryPath) {
          directoryPath.add(item?.targetConfig?.config?.directoryPath);
        }
      }

    });
    return hostSet.size + directoryPath.size || 0
  };

  const columns = [
    {
      name: t("name"),
      field: "configSetName",
      type: "customControl",
      color: "rgba(16, 24, 40, 1)",
      class: "font-5",
      cursor: "pointer",
      fnRenderCustomControl: (item) => {
        return (
          <>
            <div
              className="font-5 first-col-homepage"
              onClick={() => navigate(`/configuration/${item.id}`)}
            >
              {item.configSetName}
            </div>
          </>
        );
      },
    },
    {
      name: t("Description"),
      field: "configSetDescription",
      type: "text",
      color: "rgba(16, 24, 40, 1)",
      fontWeight: "400",
    },
    {
      name: t("Connection Type"),
      field: "configSetType",
      // type: "text",
      color: "rgba(16, 24, 40, 1)",
      class: "font-4",
      fontWeight: "400",
      type: "customControl",

      fnRenderCustomControl: (item) => {
        return (
          <>
            <div
              className="font-5 first-col-homepage"
              onClick={() => navigate(`/configuration/${item.id}`)}
            >
              {item?.configSetType === 'DATABASE' ? item?.sourceConfig?.databaseType : item?.sourceConfig?.fileSystemType}
            </div>
          </>
        );
      },
    },

    {
      name: t("LastEdit"),
      field: "updatedAt",
      type: "date",
      display: "date",
      color: "rgba(16, 24, 40, 1)",
    },

    {
      name: t("Actions"),
      type: "customControl",
      width: "18%",
      fnRenderCustomControl: (item) => {
        // Define your custom action buttons here
        const handleCopy = (id) => {
          copyConfigSet(id).then(res => {
            showToast(res.message, 'success')
            getConfigSet()
          }).catch(err => {
            showToast(err.message, 'fail')
          })
        };



        return (
          <div className='action-btn-h d-flex flex-wrap "'>
            <svg
              className="cursor-pointer item-1"
              onClick={() =>
                handleCopy(item?.id)
              }
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clipPath="url(#clip0_7119_14580)">
                <path
                  d="M4.16699 12.5013H3.33366C2.89163 12.5013 2.46771 12.3257 2.15515 12.0131C1.84259 11.7006 1.66699 11.2767 1.66699 10.8346V3.33464C1.66699 2.89261 1.84259 2.46868 2.15515 2.15612C2.46771 1.84356 2.89163 1.66797 3.33366 1.66797H10.8337C11.2757 1.66797 11.6996 1.84356 12.0122 2.15612C12.3247 2.46868 12.5003 2.89261 12.5003 3.33464V4.16797M9.16699 7.5013H16.667C17.5875 7.5013 18.3337 8.24749 18.3337 9.16797V16.668C18.3337 17.5884 17.5875 18.3346 16.667 18.3346H9.16699C8.24652 18.3346 7.50033 17.5884 7.50033 16.668V9.16797C7.50033 8.24749 8.24652 7.5013 9.16699 7.5013Z"
                  stroke="#667085"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_7119_14580">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg
              onClick={() => navigate(`/configuration/${item.id}`)}
              className="cursor-pointer item-1"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clipPath="url(#clip0_7119_14588)">
                <path
                  d="M14.167 2.49895C14.3859 2.28008 14.6457 2.10646 14.9317 1.98801C15.2176 1.86956 15.5241 1.80859 15.8337 1.80859C16.1432 1.80859 16.4497 1.86956 16.7357 1.98801C17.0216 2.10646 17.2815 2.28008 17.5003 2.49895C17.7192 2.71782 17.8928 2.97766 18.0113 3.26362C18.1297 3.54959 18.1907 3.85609 18.1907 4.16562C18.1907 4.47514 18.1297 4.78164 18.0113 5.06761C17.8928 5.35358 17.7192 5.61341 17.5003 5.83228L6.25033 17.0823L1.66699 18.3323L2.91699 13.7489L14.167 2.49895Z"
                  stroke="#667085"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_7119_14588">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg
              onClick={() =>
                handleOpenPopup("Do you wish to proceed with deleting this row?",item
                )
              }
              className="cursor-pointer item-1"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M2.5 5.0013H4.16667M4.16667 5.0013H17.5M4.16667 5.0013V16.668C4.16667 17.11 4.34226 17.5339 4.65482 17.8465C4.96738 18.159 5.39131 18.3346 5.83333 18.3346H14.1667C14.6087 18.3346 15.0326 18.159 15.3452 17.8465C15.6577 17.5339 15.8333 17.11 15.8333 16.668V5.0013H4.16667ZM6.66667 5.0013V3.33464C6.66667 2.89261 6.84226 2.46868 7.15482 2.15612C7.46738 1.84356 7.89131 1.66797 8.33333 1.66797H11.6667C12.1087 1.66797 12.5326 1.84356 12.8452 2.15612C13.1577 2.46868 13.3333 2.89261 13.3333 3.33464V5.0013"
                stroke="#667085"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        );
      },
    },
  ];

  const xData = [0, 1, 2, 3, 4, 5];
  const yData = [0, 1, 4, 9, 16, 25];
  const cardDataOne = {
    heading: t("System Type"),
    number: configurationSets.length,
    percentage: 25,
    discriptiontext: t("heading-desc"),
  };
  const cardDataTwo = {
    heading: t("Connection Type"),
    number: calculateNoConfigType(),
    percentage: 10,
    discriptiontext: t("heading-desc"),
  };
  const cardDataThree = {
    heading: t("Host"),
    number: calculateNoConfigHost(),
    percentage: 20,
    discriptiontext: t("heading-desc"),
  };
  const handelClickAdd = () => {
    // Navigation("./utility");
    navigate("/configuration");
  };
  const onSearch = (keyWord) => {
    if (!keyWord || keyWord === "") {
      setFilteredData(configurationSets);
      return;
    }
    setFilteredData(
      configurationSets.filter((rowItem) =>
        rowItem[columns[0].field].toLowerCase().includes(keyWord)
      )
    );
  };
  return (
    <>
      <Helmet>
        <title>Configuration Home - {REACT_APP_SITE_TITLE} </title>
      </Helmet>
      <div className="main-section">
        {showPopup && (
          <div className="backdropPopupStyle">
            <Popup
              popupTitle={"Deletion"}
              popupMessage={popupText}
              isConfirm={handleConfirm}
            />
          </div>
        )}
        <div className="header-section-maya h-section">
          <div className="header-text  ">
            <h1 className="heading1 font-5">{t("Configuration Sets")}</h1>
            <h4 className="sub-heading font-4">{t("desc")}</h4>
          </div>
          <div className=" header-btn">
            <button
              className="btn-purple"
              type="navigation"
              onClick={handelClickAdd}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M10.0003 4.16797V15.8346M4.16699 10.0013H15.8337"
                  stroke="white"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>{" "}
              {t("Create")}
            </button>
          </div>
        </div>
        <div className="body-section-maya contain-util">
          <div className="container-fluid">
            <div className="row g-2">
              <div className="col">
                <StatisticCard {...cardDataOne} />
              </div>
              <div className="col">
                <StatisticCard {...cardDataTwo} />
              </div>
              <div className="col">
                <StatisticCard {...cardDataThree} />
              </div>
            </div>
          </div>
          <div className="table-con">
            <TablePro
              columns={columns}
              data={filteredData}
              minRows={5}
              search={true}
              onSearch={onSearch}
              showRecords={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfigurationHome;
