import React from 'react'

const FileSafe_Header = () => {
  return (<>
      <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
              <li className="breadcrumb-item">
                  <a href="#">New Run</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                  File Anonymization
              </li>
          </ol>
      </nav>
      <div
          className="panelHeader">
          <span className="subHeader">
              Anonymize data in a file in 3 simple steps
          </span>
      </div></>
  )
}

export default FileSafe_Header
