import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import AppContextProvider from "./providers/AppContextProvider";
import "./hooks/i18n.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AppContextProvider>
      <Suspense fallback={<div>Loading...</div>}>
        <App />
      </Suspense>
    </AppContextProvider>
  </BrowserRouter>
);
reportWebVitals();
