import "./Dashboard.scss";
import icon2 from "../../images/Dashboard/icons4.svg";
import icon1 from "../../images/Dashboard/sahara.svg";

const Connections = () => {
  return (
    <div>
      <div className="dashboard-info-box">
        <div className="dashboard-info-header">
          <div className="dashboard-icon-title-container">
            <div className="dashboard-icon-background-1">
              <div className="dashboard-icon-background">
                <img src={icon2} alt="icon" className="dashboard-icon" />
              </div>
            </div>
            <div className="dashboard-info-title">Connections</div>
          </div>
        </div>
        <div className="dashboard-directions dashboard-report-levels">
          <div className="dashboard-outer-box">
            <div className="dashboard-levels-box">
              <div className="dashboard-active-btn">
                <div className="dashboard-btn-dot"></div>
                <div>Active</div>
              </div>
              <span className="dashboard-font-4">Data Provider KPIs </span>
            </div>
            <div className="">
              <img src={icon1} alt="" className="dashboard-icons" />
            </div>
          </div>
          <div className="dashboard-outer-box">
            <div className="dashboard-levels-box">
              <div className="dashboard-active-btn">
                <div className="dashboard-btn-dot"></div>
                <div>Active</div>
              </div>
              <span className="dashboard-font-4">
                Cardiovascular Clinical Trials Data{" "}
              </span>
            </div>
            <div className="">
              <img src={icon1} alt="" className="dashboard-icons" />
            </div>
          </div>
          <div className="dashboard-outer-box">
            <div className="dashboard-levels-box">
              <div className="dashboard-active-btn">
                <div className="dashboard-btn-dot"></div>
                <div>Active</div>
              </div>
              <span className="dashboard-font-4">
                Cardiovascular Health Records{" "}
              </span>
            </div>
            <div className="">
              <img src={icon1} alt="" className="dashboard-icons" />
            </div>
          </div>
          <div className="dashboard-outer-box">
            <div className="dashboard-levels-box">
              <div className="dashboard-active-btn">
                <div className="dashboard-btn-dot"></div>
                <div>Active</div>
              </div>
              <span className="dashboard-font-4">Heart Data Bank Pilot </span>
            </div>
            <div className="">
              <img src={icon1} alt="" className="dashboard-icons" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Connections;
