export const inputField_manage_Project = [
    {
        label: 'Project Name',
        name: 'firstName',
        type: 'text',
        placeholder: 'Enter project name',
        required: true,
    },
    {
        label: 'Bussines Area',
        name: 'lastName',
        type: 'text',
        placeholder: 'Enter bussines area',
        required: true,
    },
    {
        label: 'Insdustry Area',
        name: 'table',
        type: 'text',
        placeholder: 'Enter insdustry area',
        required: true,
    },
    {
        label: 'Description',
        name: 'selectSQL',
        type: 'text',
        required: true,
    }
];