import React from 'react'

const Steps = ({ isDisabled, stepName, discription }) => {
    const checkIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
    <g opacity="0.4">
      <rect x="0.5" width="14" height="14" rx="7" fill="url(#paint0_linear_7934_30092)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4738 4.30853L6.29715 8.33936L5.18881 7.15519C4.98465 6.96269 4.66381 6.95103 4.43048 7.11436C4.20298 7.28353 4.13881 7.58103 4.27881 7.82019L5.59131 9.95519C5.71965 10.1535 5.94131 10.276 6.19215 10.276C6.43131 10.276 6.65881 10.1535 6.78715 9.95519C6.99715 9.68103 11.0046 4.90353 11.0046 4.90353C11.5296 4.36686 10.8938 3.89436 10.4738 4.30269V4.30853Z" fill="#F9F5FF"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_7934_30092" x1="0.5" y1="0" x2="14.5" y2="-1.45937e-09" gradientUnits="userSpaceOnUse">
        <stop stop-color="#7F56D9"/>
        <stop offset="1" stop-color="#9E77ED"/>
      </linearGradient>
    </defs>
  </svg>

    return (
        <>
            <div className={`${isDisabled ? "" : "disabled"}`}>
                <div className="font-6"style={{color:'var(--gray-700, #344054)', fontSize:'14.4px', textAlign:'center'}}>{isDisabled &&<>{checkIcon}</>} {stepName}</div>
                <div className='font-4' style={{color:'var(--gray-500, #667085)', fontSize:'11px', textAlign:'center'}}>{discription}</div>
            </div>
        </>
    )
}

export default Steps