import React, { useEffect, useState } from "react";
import getUserData from "./getUserData";
import { LuBell } from "react-icons/lu";

import icon1 from "../../images/Dashboard/box.svg";
import icon2 from "../../images/Dashboard/one.svg";
import icon3 from "../../images/Dashboard/two.svg";
import icon4 from "../../images/Dashboard/three.svg";
import icon5 from "../../images/Dashboard/thunder.svg";
import { FiFile } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import "./Dashboard.scss";
import AiPowredRun from "../powered/AiPowered";
import AiRun from "./AiRun";

const Dashboard = () => {
  let navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const tabNames = ["Manual", "AI-Powered"];
  const [appSafeHistory, setAppSafeHistory] = useState("Manual");

  useEffect(() => {
    getUserData(setFirstName);
  }, []);
  const activness = (tab) => {
    // const tab = oEven.target.innerText;
    return tab === appSafeHistory ? "activeTab" : "non-activeTab";
  };
  const handleTabChange = (oEven) => {
    const tab = oEven.target.innerText;

    setAppSafeHistory(tab);
  };

  return (
    <div className="dash_main-section">
      <div className="dashboard_main">
        <div className="heading_dash">
          <h1 className="dashboard_title font-6">Welcome, {firstName}</h1>

          <div className="bell_dashboard">
            <LuBell size={20} color="#7F56D9" />
          </div>
        </div>
        <div className="">
          <div className=" box_dashboard ">
            <div className="box1_dashboard">
              <div className="box_head font-4">Application</div>
              <div className="box_2_head">
                <div className="dashboard-icon-background-1">
                  <div className="dashboard-icon-background">
                    <img src={icon1} alt="icon" className="dashboard-icon" />
                  </div>
                </div>
                <div
                  className="cursor-pointer font-5"
                  onClick={() => navigate("/newrun")}
                >
                  App Safe
                </div>
              </div>
              <div className="box_2_head2 font-4">
                Data anonymisation across diverse platforms and applications.
                Building on trusted features, AppSafeTM offers enhanced and
                expanded capabilities for greater utility and security.
              </div>
              <div className=" box_2_head31 ">
                <div
                  className="top_layer cursor-pointer"
                  onClick={() => navigate("/Configuration-Home")}
                >
                  <div className="layer">
                    <div className="dashboard-icon-background-1">
                      <div className="dashboard-icon-background">
                        <img
                          src={icon2}
                          alt="icon"
                          height={15}
                          className="dashboard-icon "
                        />
                      </div>
                    </div>
                    <div className="font-4">
                      <span className="bold">Set Up {"\n"}</span>
                      connection with the data source(s)
                    </div>
                  </div>
                </div>
                <div
                  className="top_layer cursor-pointer"
                  onClick={() => navigate("/utilitySetHomePage")}
                >
                  <div className="layer">
                    <div className="dashboard-icon-background-1">
                      <div className="dashboard-icon-background">
                        <img
                          src={icon3}
                          alt="icon"
                          height={15}
                          className="dashboard-icon "
                        />
                      </div>
                    </div>
                    <div className="font-4">
                      <span className="bold">
                        Detect Personal & Sensitive Information {"\n"}
                      </span>
                      Use Auto-Detection, Categorisation and Transformation or
                      configure conversion manually
                    </div>
                  </div>
                </div>
                <div
                  className="top_layer cursor-pointer"
                  onClick={() => navigate("/Execution-Home")}
                >
                  <div className="layer">
                    <div className="dashboard-icon-background-1">
                      <div className="dashboard-icon-background">
                        <img
                          src={icon4}
                          alt="icon"
                          height={15}
                          className="dashboard-icon "
                        />
                      </div>
                    </div>
                    <div className="font-4">
                      <span className="bold">Transform {"\n"}</span> data
                      consistently
                    </div>
                  </div>
                </div>
                <div className="button_dashboard">
                  <div onClick={() => navigate("/newrun")}>
                    <button className="btn-dshb font-4">Set Up App Safe</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="box1_dashboard">
              <div className="box_head pink">Files</div>
              <div className="box_2_head">
                <div className="dashboard-icon-background-1">
                  <div className="dashboard-icon-background">
                    <FiFile size={25} color="#7F56D9" />
                  </div>
                </div>
                <div
                  className="cursor-pointer font-5"
                  onClick={() => navigate("/dashboard/fa")}
                >
                  File Safe
                </div>
              </div>
              <div className="settingsNavbar tab_dashboard pt-4 ">
                <ul className="row font-4">
                  {tabNames.map((tabName) => (
                    <li
                      key={tabName}
                      onClick={handleTabChange}
                      className={`font-6 col cursor-pointer  ${activness(
                        tabName
                      )}`}
                    >
                      {tabName}
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                {appSafeHistory === "Manual" ? (
                  <div
                    className="manual_dashboard font-4 cursor-pointer"
                    onClick={() => navigate("/dashboard/fa")}
                  >
                    <div className="font-6">Manual Configuration.</div>
                    <div className="font-4 center_dashboard">
                      Seamlessly removes sensitive data from your files,
                      ensuring a secure and confidential experience.
                    </div>
                    <div className="box_2_head3">
                      <div className="top_layer">
                        <div className="layer">
                          <div className="dashboard-icon-background-1">
                            <div className="dashboard-icon-background">
                              <img
                                src={icon2}
                                alt="icon"
                                height={15}
                                className="dashboard-icon "
                              />
                            </div>
                          </div>
                          <div className="font-4">
                            <span className="bold">Upload file(s) {"\n"}</span>
                            from your computer or use our demo file
                          </div>
                        </div>
                      </div>
                      <div className="top_layer">
                        <div className="layer">
                          <div className="dashboard-icon-background-1">
                            <div className="dashboard-icon-background">
                              <img
                                src={icon3}
                                alt="icon"
                                height={15}
                                className="dashboard-icon "
                              />
                            </div>
                          </div>
                          <div className="font-4">
                            <span className="bold">
                              Detect Personal & Sensitive Information {"\n"}
                            </span>
                            Use Auto-Detection, Categorisation and
                            Transformation or configure conversion manually
                          </div>
                        </div>
                      </div>
                      <div className="top_layer">
                        <div className="layer">
                          <div className="dashboard-icon-background-1">
                            <div className="dashboard-icon-background">
                              <img
                                src={icon4}
                                alt="icon"
                                height={15}
                                className="dashboard-icon "
                              />
                            </div>
                          </div>
                          <div className="font-4">
                            <span className="bold">Transform {"\n"}</span> data
                            consistently
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : appSafeHistory === "AI-Powered" ? (
                  <div
                    className="box_2_head2 font-4 cursor-pointer"
                    onClick={() => navigate("/ai-powered")}
                  >
                    <AiRun />
                  </div>
                ) : null}
              </div>
              <div className="button_dashboard ">
                <div
                  onClick={() =>
                    navigate(
                      appSafeHistory === "Manual"
                        ? "/dashboard/fa"
                        : "/ai-powered"
                    )
                  }
                >
                  <button className="btn-dshb fs-db font-4">
                    Try File Safe
                  </button>
                </div>
              </div>
            </div>
            <div className="box1_dashboard">
              <div className="box_head blue">Ai-Chat Prompts</div>
              <div className="box_2_head">
                <div className="dashboard-icon-background-1">
                  <div className="dashboard-icon-background">
                    <FiFile size={25} color="#7F56D9" />
                  </div>
                </div>
                <div
                  className="cursor-pointer font-5"
                  onClick={() => navigate("/ai-safe")}
                >
                  Ai Safe
                </div>
              </div>
              <div className="box_2_head2 font-4">
                Effortlessly remove sensitive data from your inputs before
                sharing them with ChatGPT, ensuring your privacy and security
                are never compromised.
              </div>
              <div
                className="bottom_dash cursor-pointer"
                onClick={() => navigate("/ai-safe")}
              >
                <div className="font-6 bold">Prompt</div>
                <div className="font-4">
                  Use demo text below with your prompt
                </div>
                <div className="font-4 box_dash_ai">
                  <div className="font-4">
                    I want the order redirected to 456 Maple Avenue, Riverside,
                    CA 92501.
                  </div>{" "}
                  <div className="font-4">
                    {" "}
                    “Dear Josh, we are pleased to inform you that your order
                    #AB123456 for the Blue Mountain Bike has been successfully
                    processed. Your item will be shipped to 123 Elm Street,
                    Springfield, IL 62704, and is expected to arrive by July 25,
                    2024.{" "}
                  </div>
                  <div className="font-4">
                    If you have any questions, feel free to contact our support
                    team at <span className="font-4">support@bikesrus.com</span>{" "}
                    .”
                  </div>
                </div>
              </div>
              <div className="button_dashboard pt-2">
                <div onClick={() => navigate("/ai-safe")}>
                  <button className="btn-dshb font-4">
                    <img
                      src={icon5}
                      alt="icon"
                      height={15}
                      className="dashboard-icon "
                    />
                    Anonymise & Get Reply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
