import React, { useState } from "react";
import { FaHome, FaServicestack, FaEnvelope } from "react-icons/fa";
import { Drawer, List, ListItem, ListItemText, Divider } from "@mui/material";
import "./BottomBar.scss";

const BottomBar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button>
          <ListItemText primary="Service 1" />
        </ListItem>
        <ListItem button>
          <ListItemText primary="Service 2" />
        </ListItem>
        <ListItem button>
          <ListItemText primary="Service 3" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button>
          <ListItemText primary="Service 4" />
        </ListItem>
        <ListItem button>
          <ListItemText primary="Service 5" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className="bottom_bar">
      <ul>
        <li>
          <a href="#home">
            <FaHome className="icon" />
            <span>Home</span>
          </a>
        </li>
        <li>
          <a href="#services" onClick={toggleDrawer(true)}>
            <FaServicestack className="icon" />
            <span>Services</span>
          </a>
        </li>
        <li>
          <a href="#contact">
            <FaEnvelope className="icon" />
            <span>Contact</span>
          </a>
        </li>
      </ul>
      <Drawer anchor="bottom" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </div>
  );
};

export default BottomBar;
