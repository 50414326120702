export const dummyDataFor_copysql =[
    {
        id:"1",
        projectName:"Tranning Data Set Q1 Test",
        configSet:"Oracle Accounts",
        table:"Accounts",
        firstNameField:"Accounts_first",
        lastNameField:"Accounts_last",
        group:["DROP_TABLE"]

    }
]
export const inputField_copy_name = [
    {
        label: 'First name column',
        name: 'firstName',
        type: 'text',
        placeholder: 'Enter first name column',
        required: true,
    },
    {
        label: 'Last name column',
        name: 'lastName',
        type: 'text',
        placeholder: 'Enter last name column',
        required: true,
    },
    {
        label: 'Table',
        name: 'table',
        type: 'text',
        placeholder: 'Enter Table ',
        // required: true,
    },
    {
        label: 'Select SQL',
        name: 'selectSQL',
        type: 'text',
        placeholder: 'Enter select SQL',
        required: true,
    }
];