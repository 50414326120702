import React, { useContext, useEffect, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import Select from "react-select";
import { getConfigSetData } from '../../../hooks/apis/useAppSafeApi';
import { InputSection } from '../../../components/FieldSection.jsx/field';
import { UserContext, useUserContext } from "../../../hooks/UserContext"
import { serverAxios } from '../../../utils/AxiosUtils';
import { FaSave } from 'react-icons/fa';
const Copy_name = ({ setCopyName, copyName, inputField }) => {
    const { setLoading } = useContext(UserContext);
    const { showToast } = useUserContext();
    const [configSets, setConfigSets] = useState([]);
    const [formData, setFormData] = useState({
        configSet: copyName.id ? copyName.id.configSet : '',
        firstName: copyName.id ? copyName.id.firstNameField : '',
        lastName: copyName.id ? copyName.id.lastNameField : '',
        selectSQL: copyName.id ? copyName.id.selectSQL : ''
    });
    useEffect(() => {
        getConfigSetData()
            .then((res) => {
                setConfigSets(res);
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    const optionsConfigSet = configSets.map((val) => ({
        value: val.id,
        label: val.configSetName,
    }));

    const handleSelectChange = (selectedOption) => {
        setFormData({ ...formData, configSet: selectedOption.value });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSaveCopyUtil = async (e) => {
        e.preventDefault();
        const data={
            configSetId:formData.configSet,
            firstNameColumnName:formData.firstName,
            lastNameColumnName:formData.lastName,
            selectQuery:formData.selectSQL,
            // genderColumnName:formData.table
        }
        setLoading(true)
        await serverAxios.appSafeAxios.post(`utils/copy-names-sql`, data).then(res => {
            if (typeof res.data.data === 'string') {
                showToast(res.data.data, "success")
            }
        }).catch(err => {
            showToast(err.message, "fail");
            console.error("error on Retrieve Tables:: ", err);
        }).finally(() => { setLoading(false) })

    };

    

    return (
        <div className="panel-utility pl-2 h-100" style={{ padding: "16px 15px 12px 12px" }}>
            <div className="h-100 panel-screen gap-4 d-flex flex-column">
                <div className="d-flex justify-content-between w-100">
                    <h4 className='font-5'> {copyName.id !== ''? <>Edit {copyName.id.projectName}</>:"Add Name Source"}</h4>
                    <RxCross2 className='level-text cursor-pointer' onClick={() => setCopyName({ show: false, id: '' })}  size={25}/>
                </div>
                <form className="d-flex flex-column h-100 justify-content-between" onSubmit={handleSaveCopyUtil}>
                    <div className="gap-4 d-flex flex-column">
                        <div className="w-75 input-section">
                            <label className="level-text font-5">Configuration Set *</label>
                            <Select
                                options={optionsConfigSet}
                                onChange={handleSelectChange}
                                className="select-dropdown"
                                placeholder="Select an action"
                            />
                        </div>
                        <div className="gap-4 w-75 d-flex flex-column">
                            {inputField.map((i) => (
                                <InputSection
                                    key={i.name}
                                    {...i}
                                    value={formData[i.name]}
                                    onChange={handleChange}
                                />
                            ))}
                        </div>
                    </div>
                    <button type="submit" className="btn-purple mt-3 align-self-start"><FaSave/> Save</button>
                </form>
            </div>
        </div>
    );
};

export default Copy_name;
