import { serverAxios } from "../../utils/AxiosUtils";

export async function get_sql_byPass() {
    const res = await serverAxios.appSafeAxios.get(`sql-by-pass`);
    if (!res.data.success) {
        throw new Error(res.data);
    }
    return res.data;
}
export async function get_sql_queries() {
    const res = await serverAxios.appSafeAxios.get(`sql-by-pass/cautionary-queries`);
    if (!res.data.success) {
        throw new Error(res.data);
    }
    return res.data;
}
export async function save_Sql_bypass(data) {
    const res = await serverAxios.appSafeAxios.post(`sql-by-pass`, data);
    if (!res.data.success) {
        throw new Error(res.data);
    }
    return res.data;
}
export async function reset_Sql_queries(data) {
    const res = await serverAxios.appSafeAxios.post(`sql-by-pass/reset`, data);
    if (!res.data.success) {
        throw new Error(res.data);
    }
    return res.data;
}
export async function revoke_User(data) {
    const formData = new FormData();
    formData.append("email", data);
    const res = await serverAxios.appSafeAxios.post(`sql-by-pass/revoke`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    },);
    if (!res.data.success) {
        throw new Error(res.data);
    }
    return res.data;
}
export async function get_User_details() {
    const res = await serverAxios.idmWithToken.get(`uam-connector/get-user-profile`);
    if (!res.data.success) {
        throw new Error(res.data);
    }
    return res.data;
}
// export async function get_User_details() {
//    const users=['anjalirajwar@gmail.com']
//     const res = await serverAxios.authAxios.get(`uam-connector/get-user-details`,users, {

//         headers: {
//             Authorization: `Bearer eyJhbGciOiJIUzUxMiJ9.eyJyZWZyZXNoX3Rva2VuX2NpbSI6NTM0MCwic3ViIjoibmlwdW4ua3VtYXJAbWF5YWRhdGFwcml2YWN5LmV1IiwiZm5hbWUiOiJOaXB1biIsIm1vYiI6Iis5MTk1MDgwNTQyMjMiLCJqd3RfdG9rZW5fY2ltIjozNTQwMDAwLCJPcmdhbml6YXRpb25EZXRhaWxzIjoibWF5YWRhdGFwcml2YWN5LmV1IiwiUm9sZXMiOlsiYWRtaW4iXSwiT3JnYW5pemF0aW9uSWQiOiIxIiwicHJlZmVycmVkX3VzZXJuYW1lIjoibmlwdW4ua3VtYXJAbWF5YWRhdGFwcml2YWN5LmV1IiwiYXV0aG9yaXRpZXMiOlt7ImF1dGhvcml0eSI6IlJPTEVfYWRtaW4ifV0sInJlZnJlc2hfdG9rZW5fZXhwIjoxNzMwNTM2ODE2OTYxLCJyZWZyZXNoX3Rva2VuX2lhdCI6MTczMDUzMTQxNjk2NiwibG5hbWUiOiJLdW1hciIsImV4cCI6MTczMDUzNTAxNiwiaWF0IjoxNzMwNTMxNDE2fQ.bmDhC0y_Mm1_-0O-AL26ojNT09n9APwj1COrRUJP843-NuxaK_HQrbixzXNe9geW2Xf1yDfx8b70xuSdz1DNzg`
//         }
//     });
//     if (!res.data.success) {
//         throw new Error(res.data);
//     }
//     return res.data;
// }