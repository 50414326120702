import axios from "axios";
const { REACT_APP_BASE_URL } = process.env;

// Constants for HTTP status codes
const HTTP_STATUS = {
  INTERNAL_SERVER_ERROR: "500",
  CLIENT_ERROR: "400",
};
let authToken='';

const handleError = (err) => {
  if (axios.isAxiosError(err)) {
    let message = "Something went wrong";
    let status = 500;

    const { response } = err;
    if (response) {
      status = response.status;
      const { data } = response;

      if (data) {
        message =
          data.message ||
          data.errorMessage ||
          (status.toString().startsWith(HTTP_STATUS.CLIENT_ERROR)
            ? "There is some error in your request."
          : "Internal server error, please contact the admin or send an email to support@mayadataprivacy.eu.") || (status === 401 && "You do not have authorization to access this functionality. Please contact your account manager, or write to support@mayadataprivacy.eu to purchase additional licenses.") || (status === 403 &&"You are not authorized to access this resource. Please write to info@mayadataprivacy.eu  to purchase additional licences to access this feature.");
      }
      if (typeof data.data === "string") {
        message = data.data;
      }
      
     
    }

    throw new Error(message);
  }

  throw err;
};

const createAxiosInstance = (baseURL) => {
  const instance = axios.create({ baseURL });
  // instance.interceptors.request.use(setAuthorizationHeader);
  instance.interceptors.response.use(
    (res) => {
      const data = res.data;
      if (data.success) {
        return { ...res, data: data };
      }
      return {
        ...res,
        data: {
          success: true,
          message: "fetched",
          data: data,
        },
      };
    },
    (err) => handleError(err)
  );

  return instance;
};

const serverDemoAxios = createAxiosInstance("");
const serverFileAxios = createAxiosInstance(REACT_APP_BASE_URL + "file-safe/");
const authAxios = createAxiosInstance(REACT_APP_BASE_URL + "app-safe-idm/");
const authAxiosWithToken = createAxiosInstance(REACT_APP_BASE_URL + "app-safe-idm/");
const serverAppSafeAxios = createAxiosInstance(
  REACT_APP_BASE_URL + "app-safe/"
);
const serverCollaborationAxios = createAxiosInstance(
  REACT_APP_BASE_URL + "maya-utils/"
);
const serverAiSafeAxios = createAxiosInstance(REACT_APP_BASE_URL + "ai-safe/");
let serverFileInterceptor;
let serverAppSafeInterceptor;
let serverCollaborationInterceptor;
let serverDemoInterceptor;
let serverAiSafeInterceptor;
let serverAuthAxiosWithToken

const setTokenForServer = async (token) => {
  authToken=token;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  
const setAuthorizationHeader = (config) => {
  if (authToken && config.headers) {
    config.headers["Authorization"] = `Bearer ${authToken}`;
  }
  return config;
};
  if (serverFileInterceptor)
    serverFileAxios.interceptors.request.eject(serverFileInterceptor);
  if (serverAppSafeInterceptor)
    serverAppSafeAxios.interceptors.request.eject(serverAppSafeInterceptor);
  if (serverCollaborationInterceptor)
    serverCollaborationAxios.interceptors.request.eject(
      serverCollaborationInterceptor
    );
  if (serverDemoInterceptor)
    serverDemoAxios.interceptors.request.eject(serverDemoInterceptor);
  if (serverAiSafeInterceptor)
    serverAiSafeAxios.interceptors.request.eject(serverAiSafeInterceptor);

  if (serverAuthAxiosWithToken)
    authAxiosWithToken.interceptors.request.eject(serverAuthAxiosWithToken);

  // Set new interceptors
  serverFileInterceptor = serverFileAxios.interceptors.request.use(
    setAuthorizationHeader
  );
  serverAuthAxiosWithToken = authAxiosWithToken.interceptors.request.use(
    setAuthorizationHeader
  );
  serverAiSafeInterceptor = serverAiSafeAxios.interceptors.request.use(
    setAuthorizationHeader
  );
  serverAppSafeInterceptor = serverAppSafeAxios.interceptors.request.use(
    setAuthorizationHeader
  );
  serverCollaborationInterceptor =
    serverCollaborationAxios.interceptors.request.use(setAuthorizationHeader);
  serverDemoInterceptor = serverDemoAxios.interceptors.request.use(
    setAuthorizationHeader
  );
};

export const serverAxios = {
  authAxios,
  fileSafeAxios: serverFileAxios,
  appSafeAxios: serverAppSafeAxios,
  collaborationAxios: serverCollaborationAxios,
  updateToken: setTokenForServer,
  defaultAxios: serverDemoAxios,
  aiSafeAxios: serverAiSafeAxios,
  idmWithToken: authAxiosWithToken
};
