import { TbPremiumRights } from "react-icons/tb";
import { StoragePref } from "../utils/LocalStorageUtils";
import { decodeJwt } from "./Methods";


const badgeStyle = () => {
    return <b className="w-45 btn-purple" style={{ fontSize: "8px", width: "30%", height: '50%', padding: '8px 5px' }}>Premium</b>
}

const userRoleDetails = {
    'super_admin': {
        code: 1,
        message: "Super Admin access granted",
    },
    'admin': {
        code: 2,
        message: "Admin access granted",
    },
    'normal_user': {
        code: 3,
        message: "Normal user access granted",
        error: "User is not authenticated for Config Set",
        style: {
            badge: <b className="w-45 btn-purple" style={{ fontSize: "8.5px", width: "40%", height: '50%', padding: '8px 5px' }}>Premium</b>,
            icon: <TbPremiumRights />
        }
    },
    'public_user': {
        code: 4,
        error: "User is not authenticated for App Safe and AI Safe",
        style: {
            badge: badgeStyle(),
            icon: <TbPremiumRights />
        }
    }
};

export const getUserRole = () => {
    const oUser = StoragePref.user.load();
    const decodedValue = decodeJwt(oUser.jwtToken);
    const role = decodedValue?.Roles || [];

    for (let key in userRoleDetails) {
        if (role.includes(key)) {
            return userRoleDetails[key];
        }
    }

    return { error: "Role not recognized", code: -1 };
};

export const displayIconInByName = (tabName) => {
    const userRole = getUserRole();
    const role = userRole?.code;
    if (role === 4 && (tabName === 'App Safe' || tabName === 'AI Jobs')) {
        return badgeStyle()
    } else return ''
}
