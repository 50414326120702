export const decodeJwt = (token) => {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
                .join('')
        );

        return JSON.parse(jsonPayload);
    } catch (error) {
        console.log("error on decoded jwt token ", error)
    }
};

export function areAnonymizeArrayEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
        return false;
    }

    // Iterate through the arrays and compare each element
    for (let i = 0; i < arr1.length; i++) {
        const obj1 = arr1[i];
        const obj2 = arr2[i];

        // Compare the properties of each AnonymizeField object
        if (
            obj1.name !== obj2.name ||
            !areUtilityParamsEqual(obj1.utilityParam, obj2.utilityParam) ||
            !areUtilityParamConditionsEqual(obj1.utilityParamConditions, obj2.utilityParamConditions)
        ) {
            return false;
        }
    }

    // If all elements are equal, the arrays are equal
    return true;
}

// Function to check if two UtilityParam objects are equal
function areUtilityParamsEqual(param1, param2) {
    if (param1) {
        if (param2) {
            return param1.value === param2.value;
        }
        return false;
    }
    return !param2;
}

// Function to check if two arrays of UtilityParamCondition objects are equal
function areUtilityParamConditionsEqual(conditions1, conditions2) {
    if (conditions1) {
        if (conditions2) {
            // console.log(conditions1, conditions2)
            if (conditions1.length !== conditions2.length) return false;
            for (let i = 0; i < conditions1.length; i++) {
                if (conditions1[i].value !== conditions2[i].value) return false;
            }
            return true;
        }
        return false;
    }
    return !conditions2;
}