import React from 'react';
import './MultiColourProgressbarBar.scss';

const MultiColourProgressBar = ({ redP, blueP, PurpleP, gryP }) => {
    const red = {
        width: `${redP}%`

    };
    const blue = {
        width: `${blueP}%`
    };
    const purple = {
        width: `${PurpleP}%`
    };
    const gry = {
        width: `${gryP}%`
    };

    return (
        <>
            <div className="pro-progress">
                <div className="progress-bar-pro" >
                    <div className="error" style={red}></div>
                    <div className="runing-p" style={blue}></div>
                    <div className="success-p" style={purple}></div>
                    <div className="blank" style={gry}></div>
                </div>
            </div>
        </>
    );

};


export default MultiColourProgressBar;

// import React from 'react';
// import './MultiColourProgressbarBar.scss';

// const MultiColourProgressBar = ({ redP, blueP, PurpleP, gryP }) => {
//   const colors = ['#E31B54', '#6941C6', '#9E77ED', '#EAECF0']; // Fixed colors
//   const percentages = [redP, blueP, PurpleP,gryP];
//   const colorStops = [];

//   // Calculate the color stops based on the provided percentages
//   for (let i = 0; i < percentages.length; i++) {
//     const colorStop = `${colors[i]} ${percentages[i]}%`;
//     colorStops.push(colorStop);
//   }

//   const progressBarStyle = {
//     background: `linear-gradient(to right, ${colorStops.join(', ')})`
//   };

//   return <div className="progress-bar-pro" style={progressBarStyle}></div>;
// };

// export default MultiColourProgressBar;

