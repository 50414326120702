import "./Dashboard.scss";
import icon2 from "../../images/Dashboard/command.svg";

const Collaboration = () => {
  return (
    <div>
      <div className="dashboard-info-box">
        <div className="dashboard-info-header">
          <div className="dashboard-icon-title-container">
            <div className="dashboard-icon-background-1">
              <div className="dashboard-icon-background">
                <img src={icon2} alt="icon" className="dashboard-icon" />
              </div>
            </div>
            <div className="dashboard-info-title">Collaboration</div>
          </div>
        </div>
        <div className="dashboard-front">
          <div className="dashboard-front dashboard-levels-box">
            <div className="dashboard-active-btn">
              <div className="dashboard-btn-dot"></div>
              <div className="active-text font-5">Active</div>
            </div>
            <span className=" font-6">Cardiovascular Health Records </span>
          </div>
          <div className="">
            University Hospital Vienna, Oxford Cardiovascular Health Clinic,{" "}
            Aarhus University Hospital
          </div>
        </div>
        <div className="dashboard-front">
          <div className="dashboard-front dashboard-levels-box">
            <div className="blue dashboard-active-btn">
              <div className="dashboard-btn-dot"></div>
              <div className="progress-text font-5 ">Set Up In Progress</div>
            </div>
            <span className=" font-6">Heart Data Bank Pilot </span>
          </div>
          <div className="">
            European Health Union, National Institute of Health, US Food and
            Drug Administration
          </div>
        </div>
        <div className="dashboard-front">
          <div className="dashboard-front dashboard-levels-box">
            <div className="complete-bg dashboard-active-btn">
              <div className="complete-dot dashboard-btn-dot"></div>
              <div className="complete-text font-5">Complete</div>
            </div>
            <span className=" font-6">
              Cardiovascular Clinical Trials Data{" "}
            </span>
          </div>
          <div className="">Novartis, Pfizer, Bayer</div>
        </div>
      </div>
    </div>
  );
};

export default Collaboration;
