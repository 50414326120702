import React, { useEffect, useState } from "react";
import "./Duplicate.scss";
import { useNavigate } from "react-router-dom";
import { useNewRunContext } from "../../hooks/NewRunContext";
import Helmet from "react-helmet";
import { BiArrowBack, BiSave } from "react-icons/bi";
import { BsCaretRight } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { FiEdit2 } from "react-icons/fi";
import dUtilitySetSingleEntry from "../../model/UtilitySetSingleEntry.json";
import { HiRefresh } from "react-icons/hi";
import ExecutedTable from "../../components/ExecutedTable/ExecutedTable";
import ExecutionDefinition from "../../components/ExecutionDefinition/ExecutionDefinition";
import { useUserContext } from "../../hooks/UserContext";
import axios from "axios";
import { AppSafeUrl } from "../../model/AppSafeUrl";
import { serverAxios } from "../../utils/AxiosUtils";
import Popup from "../../components/popupModel/Popup";

const { REACT_APP_SITE_KEY, REACT_APP_SITE_TITLE } = process.env;

const Duplicate = () => {
  // Hooks
  const [editing, setEditing] = useState(false);
  const [editedData, setEditedData] = useState([]);
  const [stepTwoActive, setStepTwoActive] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [showScheduleDialog, setShowScheduleDialog] = useState(false);
  const [showNewDivDefinition, setShowNewDivDefinition] = useState(false);
  const [utilitySetTables, setUtilitySetTables] = useState(
    dUtilitySetSingleEntry
  );
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");

  const { data, updateData } = useNewRunContext();
  const { showToast } = useUserContext();
  const { executionSet, runName, scheduleTime, seed } = data;
  const navigate = useNavigate();

  const executionSetDetails = executionSet
    ? executionSet.executionSetDetailsList
    : [];
  const executionSetType = executionSet ? executionSet.executionSetType : "";
  const findExecutionDetailByStep = (step) => {
    const currentExecutionDetail = executionSetDetails.find((d) => {
      return d.step === step;
    });
    return currentExecutionDetail;
  };
  const [currentExecutionSetDetail, setCurrentExecutionSetDetail] = useState(
    findExecutionDetailByStep(currentStep)
  );

  useEffect(() => {
    if (!executionSet) {
      showToast("No execution set provided," + "configure it first");
      navigate("/newrun");
    }
  }, []);

  const cancelEditClick = () => {
    setEditing(false);
  };
  const handleEditClick = () => {
    setEditing(true);
    setEditedData([...utilitySetTables]);
  };
  const handleScheduledJobClick = () => {
    saveCurrentRun(true);
  };
  const [showPopup, setShowPopup] = useState(false);
  const handleConfirm = (result) => {
    if (result) {
      saveCurrentRun(false);
      setShowPopup(false);
    } else {
      saveCurrentRun(true);
      setShowPopup(false);
    }
  };
  const handleClickRun = (e) => {
    if (data.scheduleTime) {
      //  if user want to run the job immediately => saveCurrentRun(false);
      // if user wants to shedule the run => saveCurrentRun(true);
      setShowPopup(true);
      return;
    }
    saveCurrentRun(false);
  };

  const handleRefresh = () => {
    window.location.reload(true);
  };

  const saveCurrentRun = (isSchedule) => {
    const scheduleTimeStamp = isSchedule
      ? scheduleTime
        ? new Date(scheduleTime).getTime()
        : null
      : null;
    const currentTimeStamp = new Date().getTime();
    if (isSchedule && currentTimeStamp >= scheduleTimeStamp) {
      showToast("Schedule time should be greater than current time.");
      return;
    }
    const postData = {
      runName: runName,
      scheduleTime: scheduleTimeStamp,
      executionSetId: executionSet.executionSetId,
      projectId: seed,
    };
    serverAxios.appSafeAxios
      .post(AppSafeUrl.jobSet, postData)
      .then((resp) => {
        showToast(resp.data.message);
        navigate("/history");
      })
      .catch((err) => {
        showToast(err.message, "fail");
        // if (err.data) {
        //     showToast(err.data,"fail")
        //     console.log(err.data);
        // }else{
        //     showToast(err.message, "fail");
        // }
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  const handleScheduleClick = () => {
    setShowScheduleDialog(!showScheduleDialog);
  };

  const handleOnStepClick = (step) => {
    setCurrentStep(step);
    setCurrentExecutionSetDetail(findExecutionDetailByStep(step));
  };

  const handleClickDefinition = () => {
    setShowNewDivDefinition(!showNewDivDefinition);
  };

  const hideShowScheduleDialogue = (e) => {
    setShowScheduleDialog(false);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };
  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
  };
  return (
    <>
      <Helmet>
        <title>New Run Edit screen - {REACT_APP_SITE_TITLE} </title>
      </Helmet>
      <div className="newrunscreen">
        {showPopup && (
          <div className="backdropPopupStyle">
            <Popup
              popupTitle={"Confirmation"}
              popupMessage={
                "You have selected the schedule time,  If you want to save the run and proceed immediately then click 'Run'.  If you prefer to schedule the run then click 'Schedule'."
              }
              isConfirm={handleConfirm}
              confirmButtonLabel={"Run"}
              cancelButtonLabel={"Schedule"}
              removePopup={() => setShowPopup(false)}
            />
          </div>
        )}
        <div className="main-sec-newrun">
          <div className="header-edit">
            <div className="d-flex align-items-center page-header ">
              <button
                type=""
                className="res-back-btn font-5 me-2"
                onClick={() => navigate(-1)}
              >
                <BiArrowBack /> NewRun 1/2
              </button>
              <div className="font-5 heading1 head-form ">
                New Run (2/2): {runName}
              </div>
            </div>
            <div className="buttons">
              {" "}
              {editing ? (
                <div className="footer-edit ">
                  <div className="cancel-btn">
                    <button
                      className="cancel s font-6"
                      onClick={cancelEditClick}
                    >
                      <RxCross2 /> Cancel
                    </button>
                  </div>
                  <div className="save">
                    <button
                      className="save-changes s font-6"
                      onClick={cancelEditClick}
                    >
                      <BiSave /> Save changes
                    </button>
                  </div>
                </div>
              ) : (
                <div className="head-btn">
                  <button
                    type=" button"
                    className={`btn-low-purple font-6 ${
                      !scheduleTime && "disabled"
                    }`}
                    onClick={handleScheduledJobClick}
                  >
                    <FiEdit2 />
                    Save & Schedule Job
                  </button>
                  <button
                    type=" button"
                    className=" btn-low-purple font-6"
                    onClick={handleRefresh}
                  >
                    <HiRefresh /> Refresh
                  </button>

                  <button
                    type=" button"
                    className="btn-purple font-6"
                    onClick={handleClickRun}
                  >
                    <BsCaretRight />
                    {scheduleTime ? "Run Now" : "Run"}
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="section-run body-section-maya">
            <div className="sec-1">
              <div className="sec-1-content">
                <div className="con-1">
                  <div className="title-text font-4">Run Name</div>
                  <div className=" head-info-sec">
                    <div className="dis-text name-def  font-4">
                      {runName} {"  "}{" "}
                      <span
                        className="text-def font-6 cursor-pointer"
                        style={{ color: "#6941C6" }}
                        onClick={handleClickDefinition}
                      >
                        {" "}
                        Show Definitions
                      </span>
                    </div>
                    <div
                      className="dis-text name-def font-4"
                      style={{ color: "#101828" }}
                      onClick={handleScheduleClick}
                    >
                      {scheduleTime
                        ? new Date(scheduleTime).toLocaleString()
                        : "Not scheduled"}
                      <span
                        className="text-def font-6 cursor-pointer"
                        style={{ color: "#6941C6" }}
                      >
                        {" "}
                        Edit
                      </span>
                    </div>
                  </div>
                </div>
                <div className="con-2">
                  <div className="title-text font-4">Run Type</div>
                  <div className="dis-text font-4">{executionSetType}</div>
                </div>
                <div className="con-2">
                  <div className="title-text font-4">Step</div>
                  {executionSetDetails.map((key, index) => {
                    const executionDetail = executionSetDetails[index];
                    return (
                      <div
                        className={
                          "dis-text  font-5 " +
                          (currentStep === executionDetail.step
                            ? "highlight "
                            : "pointer ")
                        }
                        onClick={() => {
                          handleOnStepClick(executionDetail.step);
                        }}
                      >
                        step {executionDetail.step}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="sec-2">
              {currentExecutionSetDetail?.type === "SCRIPT" ? (
                <>
                  <div className="sec-02 mt-5 ">
                    <div className="heading font-6">Scripting Information</div>
                    <div className="information pt-3">
                      <div className="config-info">
                        <div className="text-muted font-5 ">
                          Configuration set
                        </div>
                        <div className="dis-text-2 s font-4">
                          {currentExecutionSetDetail.configSet.configSetName}
                        </div>
                      </div>
                      <div className="config-info pt-4">
                        <div className="text-muted font-5">Script</div>
                        <div className="dis-text-2 s font-4">
                          {currentExecutionSetDetail.fileName}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <ExecutedTable
                    encodedUtilityId={
                      currentExecutionSetDetail?.utilitySet?.utilitySetId
                    }
                    configSetId={currentExecutionSetDetail?.configSet?.id}
                  />
                </>
              )}
            </div>
          </div>
          <div className="def">
            {showNewDivDefinition && (
              <div className="panel-utility">
                <div className="h-100 panel-screen">
                  <ExecutionDefinition
                    executionSet={executionSet}
                    isPreview={true}
                    onCrossClick={handleClickDefinition}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="schedule">
            {showScheduleDialog && (
              <div>
                <div className="panel panel-utility body-section-maya">
                  <div className=" panel-screen uper-panel-sec">
                    <div className="header-sh-panel ">
                      <div className="font-5">Add Schedule</div>
                      <div
                        className=" cancel cursor-pointer"
                        onClick={hideShowScheduleDialogue}
                      >
                        <RxCross2 />
                      </div>
                    </div>
                    <div className="section-sh-panel">
                      <div className="in-1 schedule-input">
                        <label className="font-5 label s">Date:</label>
                        <input
                          className="input-field-shadow"
                          type="date"
                          placeholder=" "
                          value={selectedDate}
                          onChange={handleDateChange}
                        />
                      </div>
                      <div className="in-2 schedule-input">
                        <label className="font-5 label s">Time:</label>
                        <input
                          className="input-field-shadow"
                          type="time"
                          value={selectedTime}
                          onChange={handleTimeChange}
                        />
                      </div>
                    </div>
                  </div>
                  <footer className="footer-sh">
                    <button
                      className="btn-purple font-5"
                      onClick={(e) => {
                        const scheduleDate = new Date(
                          selectedDate + " " + selectedTime
                        ).getTime();
                        const currentTimeDate = new Date().getTime();
                        if (currentTimeDate >= scheduleDate) {
                          showToast(
                            "Schedule time should be greater than current"
                          );
                          return;
                        }
                        updateData({
                          ...data,
                          scheduleTime: selectedDate + " " + selectedTime,
                        });
                        hideShowScheduleDialogue(e);
                      }}
                    >
                      <BiSave /> Save & Use
                    </button>
                    <button
                      className="btn-low-purple font-5"
                      onClick={hideShowScheduleDialogue}
                    >
                      <RxCross2 /> Cancel
                    </button>
                  </footer>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Duplicate;
