import React, { useState, useEffect, useContext } from 'react';
import "./Configuration.scss";
import FTP from "./FTP";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FooterActions, InputSection, SelectSection } from '../../components/FieldSection.jsx/field';
import { UserContext, useUserContext } from "../../hooks/UserContext";
import { serverAxios } from '../../utils/AxiosUtils';
import useAppContext from '../../hooks/useAppContext';
import { useNavigate } from "react-router-dom";
import DataBase from './DataBase';
import { BiArrowBack } from 'react-icons/bi';
import { AppSafeUrl } from "../../model/AppSafeUrl";
import { availableConfigType, initialDataBaseData, initialFormData, initialSharePointData } from './ConfigData';
import { databaseValidation, handleDto, validateFormData } from './Vlidation';
import axios from 'axios';

const Configuration = () => {

  const navigate = useNavigate()
  const { t } = useTranslation("config");
  useEffect(() => {
    if (id) {
      getConfigDataOfSpecificId();
    }

  }, []);
  
  const { popupModel } = useAppContext();
  const { showToast } = useUserContext();
  const { setLoading } = useContext(UserContext);
  const { id } = useParams();
  const [formData, setFormData] = useState(initialFormData);
  const [formDataForSharePoint, setFormDataForSharePoint] = useState(initialSharePointData);
  const [formDataForDataBase, setFormDataForDataBase] = useState(initialDataBaseData);
  const [error, setError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTest, setIsTest] = useState(false);
  const getConfigDataOfSpecificId = async () => {
    setLoading(true);
    await serverAxios.appSafeAxios
      .get(`config-set/${id}`)
      .then((res) => {
        const receivedData = res.data;
        setFormData(receivedData.data);
        setFormDataForDataBase(receivedData.data)
        setFormDataForSharePoint(receivedData.data)
      })
      .catch((err) => {
        console.error("The execution set error ->", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleChange = (e) => {
    const { name, value, id } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

  };
  const onCancle = async () => {
    await setFormDataForDataBase(initialDataBaseData);
    await setFormDataForSharePoint(initialSharePointData);
    await displayConfigType(true)
    // setFormData(initialFormData)

  }
  const handleInputChange = (event, configType, field, subField = null) => {
    const { name, value, checked } = event.target;
    if (!configType && !subField && field) {
      setFormDataForSharePoint(prevState => ({
        ...prevState,
        [field]: checked
      }));
    } else {
      if (configType) {
        setFormDataForSharePoint(prevState => {
          const updatedConfig = { ...prevState[configType] };

          if (subField) {
            updatedConfig.config[subField] = value;
          } else {
            updatedConfig[field] = value;
          }

          return {
            ...prevState,
            [configType]: updatedConfig
          };
        });
      } else {
        setFormDataForSharePoint(prevState => ({
          ...prevState,
          [field]: value
        }));
      }
    }
  };
  const handleInputChangeForDataBase = (event, configType, field, subField = null) => {
    const { name, value, checked } = event.target;
    if (!configType && !subField && field) {
      setFormDataForDataBase(prevState => ({
        ...prevState,
        [field]: checked,
        targetConfig:checked?null:initialDataBaseData.targetConfig
      }));
    } else {
      if (configType) {
        setFormDataForDataBase(prevState => {
          const updatedConfig = { ...prevState[configType] };

          if (subField) {
            updatedConfig.config[subField] = value;
          } else {
            updatedConfig[field] = value;
          }

          return {
            ...prevState,
            [configType]: updatedConfig
          };
        });
      } else {
        setFormDataForSharePoint(prevState => ({
          ...prevState,
          [field]: value
        }));
      }
    }
  };
  
  const postConfigurationSetData = async (dto,isTesting = true) => {
    
    const apiUrl = isTesting
      ? "config-set/test"
      : id
        ? `config-set/${id}`
        : AppSafeUrl.configSet;
    saveConfigData(apiUrl, dto, isTesting);
  }


  const saveConfigData = (apiUrl, dto, isTesting) => {
    setLoading(true);
    serverAxios.appSafeAxios
      .post(apiUrl, dto)
      .then((res) => {
        const receivedData = res.data;
        showToast(receivedData.message, "success");
        !isTesting && navigate("/Configuration-Home");
      })
      .catch((err) => {
        showToast(err.message, "fail");
        console.log("The execution set error ->", err);
      })
      .finally(() => {
        setLoading(false);
        // formDataForDataBase.sameConfig===true && setFormDataForDataBase(initialDataBaseData)
      });
  }

  const displayConfigType = () => {
    if (formData.configSetType === 'DATABASE') {
      return (<DataBase id={id}  setFormData={setFormDataForDataBase} formDataForSource={formDataForDataBase} handleChange={handleInputChangeForDataBase} error={error} />);
    }
    if (formData.configSetType === 'FTP') {
      return (<FTP formDataForSource={formDataForSharePoint}
        handleChange={handleInputChange} id={id} error={error} />);
    }
    return null;
  };
  const handleSubmit = async (e, isTest = false) => {
    e.preventDefault();

    try {
      const dto = await handleDto(formDataForDataBase, formDataForSharePoint, formData);
      console.log("dto", dto); // This will now log the resolved dto object

      if (validateFormData(dto)) {
        await postConfigurationSetData(dto, isTest); // Wait for the post action to complete
      } else {
        setError(true);
        console.log("fill the mendatory things")
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      setError(true); // Set error state if there's any error in the process
    }
  };



 

  return (
    <div className='h-100 d-flex flex-column gap-3'>
      <div className="header-section-maya pt-3">
        <div className="back-config">
          <button
            className="res-back-btn font-5"
            onClick={() => navigate(-1)}
          >
            <BiArrowBack /> {t("Configuration Sets")}
          </button>
        </div>
        <div className="heading1 head-form font-5">{t("new")}</div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="ml-4 d-flex w-100 h-100 flex-column justify-content-between" style={{ justifyContent: 'space-between', height: '100vh' }}>
          <div className="w-100 body-section-maya ">
            <div className="w-50 d-flex flex-column gap-3">
              <InputSection label={"ConfigSet Name"} name="configSetName" value={formData.configSetName} onChange={handleChange} required={true} type='text' error={error} />
              <InputSection type="text" label="ConfigSet Description" name="configSetDescription" value={formData.configSetDescription} onChange={handleChange} required={false} placeholder="" />
              <SelectSection label='Config Type' name="configSetType" onChange={handleChange} value={formData.configSetType} options={availableConfigType} id={id} required={true} placeholder={"Select type"} error={error} />
            </div>
            <div className=" pt-5 w-100 pl-4 d-flex flex-column ">
              {displayConfigType()}
            </div>
          </div>

          <div className="pt-3">
            <FooterActions isCancel={true}
              isInitialSave={true}
              isSave={true}
              id={id}
              cancelWithId={getConfigDataOfSpecificId}
              handleSaveData={handleSubmit}
              handleOpenPopup={popupModel}
              error={setError}
              onCancle={onCancle} />
          </div>
        </div>
      </form>
    </div>
  );
};
export default Configuration;