// import React, { Component } from 'react'

import './Uperpart.css';
import { Col, Row } from 'react-bootstrap';
import React from 'react'
import ReviewsBar from './ReviewsBar';
import { BsCoin } from "react-icons/bs"


function Colone(props) {




  return (

    <div>
      <div className='plaininfo'>
        <Row className='row responsiverow'>
          <Col className='col rowbardiv'>
            <div className='bar'>
              <ReviewsBar score={40}  ></ReviewsBar>
            </div>




          </Col>
          <Col className='col second_col'>
            <div className='col secondcolicon'>
              <div className='Coinicon'>
              <div className='icon1'><BsCoin /></div>
              </div>
              
              <div className='data'>
                <h5>Total March Coins</h5>
                <p>40 Subscription<br></br>
                  10 Rolled Over from February</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>


    </div>
      
      
    
  )
}

export default Colone

