import React from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <div className="container vh-100 d-flex justify-content-center align-items-center">
            <div className="text-center">
                <h3 className="display-1">404</h3>
                <p className="font-5">Page Not Found</p>
                <button
                    type="button"
                    className=" res-back-btn font-5 mt-3"
                    onClick={() => navigate(-1)}
                >
                    <BiArrowBack /> Go Back
                </button>
            </div>
        </div>
    );
};

export default NotFound;
