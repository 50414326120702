import { serverAxios } from "../utils/AxiosUtils";
import { StoragePref } from "../utils/LocalStorageUtils";

export const AUTH_API_STATE = {
  loading: "Loading",
  Authenticated: "Authenticated",
  otp: "otp",
};

const AUTH_IDM_URL = {
  login: "login",
  captcha: `captcha`,
  register: `uam-connector/user-registration`,
  reset_pass_verify_info: `uam-connector/users/reset-password/verify-info`,
  reset_pass_verify_otp: `uam-connector/users/reset-password/verify-otp`,
  add_roles: `uam-connector/add-roles`,
};

export async function getRecaptcha() {
  const response = await serverAxios.authAxios.get(AUTH_IDM_URL.captcha, {
    headers: {
      "Content-Type": "*/*",
    },
    responseType: "arraybuffer",
  });
  // console.log("response:: ", response);
  const contentType = response.headers["content-type"];
  const blob = new Blob([response.data.data], { type: contentType });
  return {
    token: response.headers.token,
    image: URL.createObjectURL(blob),
  };
}

export function loginUserWithoutOtp(
  { email, password, captchaToken, captchaValue },
  onResponse,
  onError
) {
  const loginData = {
    userName: email,
    password: password,
    captchaValue: captchaValue,
    captchaToken: captchaToken,
  };
  serverAxios.authAxios
    .post(AUTH_IDM_URL.login, loginData)
    .then((res) => {
      // console.log("login response:: ", res.data);
      const responseData = res.data;
      if (!responseData.success) {
        onError(responseData.message);
        return;
      }
      const oUser = responseData.data;
      const otpEnable = oUser.isOtpEnable;
      if (otpEnable) {
        onResponse(AUTH_API_STATE.otp, res.data);
        return;
      }
      StoragePref.user.save(oUser);
      onResponse(AUTH_API_STATE.Authenticated);
    })
    .catch((oError) => {
      onError(oError.message);
    });
}

export async function loginUserWithOtp({ email, password, otp, otpToken }) {
  const loginData = {
    userName: email,
    password: password,
    tokenValue: otp,
    token: otpToken,
  };
  const response = await serverAxios.authAxios.post(
    AUTH_IDM_URL.login,
    loginData
  );
  StoragePref.user.save(response.data);
}
