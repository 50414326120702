import "./forgotPassword.scss";
import React, { useEffect, useState } from "react";
import logo from "../../logo_maya.png";
import useAuth from "../../hooks/useAuth";
import { useForm } from "react-hook-form";
import Helmet from "react-helmet";
import { BiRefresh } from "react-icons/bi";
import axios from "axios";
import { ThreeDots } from 'react-loading-icons'
import useAppContext from "../../hooks/useAppContext";
import AuthURL from "../../model/auth_url.json";
import useRecaptcha from "../../hooks/useRecaptcha";
import { useNavigate } from "react-router-dom";
const { REACT_APP_SITE_TITLE } = process.env;

export default function PasswordRest() {
    let navigate = useNavigate();
    const { resetPasswordVerifyInfo, resetPasswordOTPVerify, resetNewPassword } = useAuth();
    const { showToast, isLoading } = useAppContext();
    const [toastMessage, setToastMessage] = useState("");
    const { recaptchaImage, recaptchaToken, reloadRecaptcha, recaptchaError } = useRecaptcha();
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [newPassword, setNewPassword] = useState(false);
    const [otpKey, setOtpKey] = useState("");
    const [smsToken, setSmsToken] = useState("");
    const [otpForResetPass, setOtpForResetPass] = useState(false);
    const [timer, setTimer] = useState(1);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const onSubmitEmail = (data) => {
        resetPasswordVerifyInfo({ ...data, recaptchaToken }, fnSuccess, fnError, setTimer);
    };
    const onSubmitOtp = (data) => {
        resetPasswordOTPVerify({ ...data, otpKey, smsToken }, fnSuccessOtp);
    };
    const onSubmitNewPassword = (data) => {
        resetNewPassword({ ...data, otpKey, smsToken }, fnSuccessNewPassword);
    }
    const fnSuccessOtp = (key, smsToken) => {
        reset({ newPassword: '' });
        setShowSuccessMessage(true);
        setToastMessage("Please enter your new password and attempt to log in once it has been successfully set. Thank you!");
        setNewPassword(true);
        setTimer(0);
        setOtpKey(key);
        setSmsToken(smsToken);
    }
    const fnSuccessNewPassword = () => {
        showToast("Your new password has been successfully updated. Please try to logIn with the new password.", 'success')
        navigate('/login')
        setShowSuccessMessage(true);
        setToastMessage("Your new password has been successfully updated. Please try to logIn with the new password.");
    }
    const fnSuccess = (key, smsToken) => {
        setOtpKey(key);
        setSmsToken(smsToken);
        setOtpForResetPass(true);
        setToastMessage(
            "An OTP has been sent to your email for verification. Please check your inbox (also check spam and junk folders)."
        );
        setShowSuccessMessage(true);
        let interval;

        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }
    };

    const fnError = (errorMsg) => {
        showToast(errorMsg,'fail');
        setShowSuccessMessage(false);
    };
    const verifyInfo = () => {
        return (
            <>
                <form onSubmit={handleSubmit(onSubmitEmail)}>
                    <a href="https://mayadataprivacy.eu/">
                        <img className="mb-4" src={logo} alt="" width="72" height="72" />
                    </a>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Enter Email address"
                            {...register("email", {
                                required: "Email Address is required",
                                pattern: {
                                    value:
                                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: "Invalid email address",
                                },
                            })}
                        />
                        <span className="errorMessage">{errors.email?.message}</span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="font-5 ">Recaptcha text *</label>
                        <div className="flex">
                            <div className="cursor-pointer pass-icon" onClick={reloadRecaptcha}><BiRefresh
                                size={25} /></div>
                            <div className="input-field-shadow form-control font-5">
                                {recaptchaImage ? <img src={recaptchaImage} alt="Captcha Image" /> : <div className={`font-4 ${(recaptchaError === "Reload Captcha' error.") ? 'text-danger' : ''}`}>{recaptchaError}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <input
                            id="captchaValue"
                            name="captchaValue"
                            className="font-4 form-control"
                            placeholder="Enter captcha text"
                            {...register("captchaValue", {
                                required: "Captcha text is required",
                                pattern: {
                                    message: "Invalid captcha text ",
                                },
                            })}
                        />
                        <span className="font-6 errorMessage">{errors.captchaValue?.message}</span>
                    </div>

                    <button
                        className="font-4 btn btn-dark btn-lg btn-block"
                        disabled={isLoading}
                    >
                        Reset
                    </button>

                    <div>
                        <a
                            className="font-4 btn btn-dark btn-lg btn-block linkAsButton"
                            href="/login"
                        >
                            Sign in
                        </a>
                    </div>
                </form>

            </>
        )
    }
    const verifyOTP = () => {
        return (
            <>
                <form onSubmit={handleSubmit(onSubmitOtp)}>
                    <a href="https://mayadataprivacy.eu/">
                        <img className="mb-4" src={logo} alt="" width="72" height="72" />
                    </a>
                    <p className="font-5 justifyied text-red">{showSuccessMessage && toastMessage}</p>
                    <div className="form-group">
                        <label htmlFor="font-5 otp">OTP*</label>
                        <input
                            id="otp"
                            name="otp"
                            className="font-4 form-control"
                            placeholder="Enter OTP from email"
                            {...register("otp", {
                                required: true,

                            })}
                        />
                        <span
                            className="font-6 errorMessage">{errors.lastName?.type === "required" && "last Name is required"}</span>
                    </div>
                    {timer > 0 ? (
                        <p className="font-5 text-muted mt-2">{timer} seconds remaining</p>
                    ) : (
                        <><p className="font-5 text-primary mt-2 cursor-pointer"
                            onClick={() => setOtpForResetPass(false)}>Resend OTP </p>

                        </>

                    )}
                    <button
                        onClick={handleSubmit}
                        className="font-4 btn btn-dark btn-lg btn-block"
                        disabled={isLoading}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        {isLoading ? <ThreeDots height="20"
                            width="20"
                            radius="3" stroke="#98ff98" strokeOpacity={.1}
                            speed={1} /> : <>Submit OTP</>}
                    </button>
                    <div>
                        <a
                            className="font-4 btn btn-dark btn-lg btn-block linkAsButton"
                            href="/login"
                        >
                            Sign in
                        </a>
                    </div>
                    <div className="form-login-register">
                        <label className="font-4">
                            Don`t have Account? <a href="/register" className="font-5">Sign up here</a>
                        </label>
                    </div>

                </form>
            </>
        )
    }
    const resetNewPasswordUI = () => {
        return (
            <>
                <form onSubmit={handleSubmit(onSubmitNewPassword)}>
                    <a href="https://mayadataprivacy.eu/">
                        <img className="mb-4" src={logo} alt="" width="72" height="72" />
                    </a>
                    <p className="font-5 justifyied text-red">{showSuccessMessage && toastMessage}</p>
                    <div className="form-group">
                        <label htmlFor="font-5 newPassword">New Password</label>
                        <input
                            id="newPassword"
                            name="newPassword"
                            className="font-4 form-control"
                            placeholder="Enter New Password "
                            {...register("newPassword", {
                                required: "Password is required",
                                pattern: {
                                    value:
                                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\-$*.{}?"!@#%&\/\\,><':;|_~`^\]\[\)\(])\S{8,}$/,
                                },
                            })}
                        />
                        {errors.newPassword && (
                            <span className="errorMessage">
                                <span>
                                    <span>Your password needs to:</span>
                                    <p className="errorMsgListItem">
                                        * Include both lower and upper case character
                                    </p>
                                    <p className="errorMsgListItem">
                                        * Include at least one number and symbol
                                    </p>
                                    <p className="errorMsgListItem">
                                        * Be at least 8 characters long
                                    </p>
                                </span>
                            </span>
                        )}
                    </div>
                    <button
                        onClick={handleSubmit}
                        className="font-4 btn btn-dark btn-lg btn-block"
                        disabled={isLoading}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        {isLoading ? <ThreeDots height="20"
                            width="20"
                            radius="3" stroke="#98ff98" strokeOpacity={.1}
                            speed={1} /> : <>Set New password </>}
                    </button>
                    <div>
                        <a
                            className="font-4 btn btn-dark btn-lg btn-block linkAsButton"
                            href="/login"
                        >
                            login
                        </a>
                    </div>

                </form>
            </>
        )
    }

    return (
        <>
            <Helmet>
                <title>Forgot Password - {REACT_APP_SITE_TITLE} </title>
            </Helmet>
            <div className="ForgotPwd text-center ">

                {!otpForResetPass ? (verifyInfo())
                    : (!newPassword
                        ? verifyOTP() : resetNewPasswordUI())
                }
            </div>
        </>
    );
}
