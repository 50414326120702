import React from 'react';
import PropTypes from 'prop-types';
import "./StatisticCard.scss";
import { BsThreeDots } from 'react-icons/bs';
import { useUserContext } from '../../hooks/UserContext';
const StatisticCard = ({ heading, number, percentage, graph, discriptiontext }) => {
    
  const { showToast } = useUserContext();
    // Determine the color and icon based on the percentage change
    const isPositiveChange = percentage > 0;
    const textColorClass = isPositiveChange ? 'green-text' : 'red-text';
    const icon = isPositiveChange ? <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M9.99984 15.8346V4.16797M9.99984 4.16797L4.1665 10.0013M9.99984 4.16797L15.8332 10.0013" stroke="#12B76A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M9.99935 4.1665V15.8332M9.99935 15.8332L15.8327 9.99984M9.99935 15.8332L4.16602 9.99984" stroke="red" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
    </svg>;

    return (
        <div className="statistic-card">
            <div className=" d-flex justify-content-between">
                <div><div className='font-5 heading-card '>{heading}</div></div>
                <div className='' style={{cursor:"not-allowed"}} >
                    <svg className='icon-dot' xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                        <path d="M10.6663 10.832C11.1266 10.832 11.4997 10.4589 11.4997 9.9987C11.4997 9.53846 11.1266 9.16536 10.6663 9.16536C10.2061 9.16536 9.83301 9.53846 9.83301 9.9987C9.83301 10.4589 10.2061 10.832 10.6663 10.832Z" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M10.6663 4.9987C11.1266 4.9987 11.4997 4.6256 11.4997 4.16536C11.4997 3.70513 11.1266 3.33203 10.6663 3.33203C10.2061 3.33203 9.83301 3.70513 9.83301 4.16536C9.83301 4.6256 10.2061 4.9987 10.6663 4.9987Z" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M10.6663 16.6654C11.1266 16.6654 11.4997 16.2923 11.4997 15.832C11.4997 15.3718 11.1266 14.9987 10.6663 14.9987C10.2061 14.9987 9.83301 15.3718 9.83301 15.832C9.83301 16.2923 10.2061 16.6654 10.6663 16.6654Z" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>



            </div>
            <div className="content-card  ">
                <div className="text-card">

                    <div className="number font-6">
                        {number}
                    </div>
                    <div className={`percentage-card font-5 ${textColorClass}`}>
                        <div className="percent-card">
                            <div className="icon-card">{icon} </div>
                            {/* <div className="per-card font-5">{percentage}% </div> */}
                        </div>
                        <p className='dis-text font-4'>{discriptiontext}</p>
                    </div>
                </div>

                {/* Render your graph here using the 'graph' prop */}
                <div className="container graph">
                    {graph}
                </div>
            </div>
        </div>
    );
};

StatisticCard.propTypes = {
    heading: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
    graph: PropTypes.node.isRequired, // You can use any graph library or component here
};

export default StatisticCard;
