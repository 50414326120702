import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SidebarHeader from "./SidebarHeader";
import SidebarNav from "./SidebarNav";
import "./Sidebar.scss";

import { MdOutlinePeopleAlt } from "react-icons/md";
import profile from "../../images/profile.png";

import { StoragePref } from "../../utils/LocalStorageUtils";
import useAppContext, { AuthState } from "../../hooks/useAppContext";
import useSidebarStore from "../../store/useSidebarStore";
import { AiOutlineLogout } from "react-icons/ai";

const Sidebar = () => {
  const {
    isCollapsed,
    selectedItem,
    firstName,
    email,
    toggleSidebar,
    setIsCollapsed,
    setSelectedItem,
    setFirstName,
    setEmail,
  } = useSidebarStore();

  const { updateAuthState } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    const pathsToCollapse = [
      "/ai-safe",
      "/definitions/settings",
      "/someOtherPath",
    ];
    if (pathsToCollapse.includes(location.pathname)) {
      setIsCollapsed(true);
    }
  }, [location.pathname, setIsCollapsed]);

  const decodeJwt = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("")
    );

    return JSON.parse(jsonPayload);
  };

  const getUserData = async () => {
    const sInfo = localStorage.getItem("info");
    if (sInfo && sInfo.length > 15) {
      const oUser = JSON.parse(sInfo);
      const decodedInfo = decodeJwt(oUser.jwtToken);
      setFirstName(decodedInfo.fname);
      setEmail(decodedInfo.sub);
    }
  };

  const handleItemClick = (item) => {
    if (isCollapsed) {
      toggleSidebar();
    }
    setSelectedItem(item);
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };

  const logoutSec = () => {
    StoragePref.clear();
    updateAuthState(AuthState.Unauthenticated);
    navigate("/login");
  };

  return (
    <div className={`main_sidebar ${isCollapsed ? "collapsed" : ""}`}>
      <div>
        <SidebarHeader
          isCollapsed={isCollapsed}
          toggleSidebar={toggleSidebar}
        />
        <SidebarNav
          isCollapsed={isCollapsed}
          selectedItem={selectedItem}
          handleItemClick={handleItemClick}
          toggleSidebar={toggleSidebar}
        />
      </div>
      <div className="sidebar_foot">
        <div className="sidebar-footer">
          <div className="w-100 d-flex justify-content-between px-4">
            <div
              onClick={() => navigate("/profile")}
              className="sidebar_logged cursor-pointer"
            >
              <MdOutlinePeopleAlt size={20} className="icon_sidebar" />
              <div className="font-4">{isCollapsed ? "" : ` Profile`}</div>
            </div>
            <div className="cursor-pointer">
              <AiOutlineLogout size={17} onClick={logoutSec} />
            </div>
          </div>

          <div className="profile_sidebar">
            <img
              src={profile}
              alt=""
              height={30}
              width={30}
              onClick={() => navigate("/profile")}
            />
            <div>
              <div className="font-4">{isCollapsed ? "" : ` ${firstName}`}</div>
              <div className="font-4">
                {isCollapsed ? "" : truncateText(email, 16)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
