import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SidebarAiSafe from "./SidebarAiSafe";
import MainAiSafe from "./MainAiSafe";
import ThemePopup from "./ThemePopup";
import { FiMenu } from "react-icons/fi";
import "./AiSafe.scss";

const AiSafe = () => {
  const { id } = useParams();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");

  const [refreshKey, setRefreshKey] = useState(0); // Key for triggering refresh

  useEffect(() => {
    localStorage.setItem("theme", theme);
    document.body.className = theme;
  }, [theme]);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const changeTheme = (newTheme) => {
    setTheme(newTheme);
    setIsPopupOpen(false);
  };

  const handleChatComplete = () => {
    // Trigger a re-fetch of the sidebar by changing the refresh key
    setRefreshKey((prevKey) => prevKey + 1);
  };

  return (
    <div className={`ai_safe_container ${theme}`}>
      <div className="hamburger_icon" onClick={toggleSidebar}>
        <FiMenu />
      </div>
      <SidebarAiSafe
        key={refreshKey} // This ensures the component re-renders on key change
        id={id}
        isOpen={isSidebarOpen}
        onSettingsClick={togglePopup}
        onClose={toggleSidebar}
      />
      <div className="background_main_ai_safe">
        <MainAiSafe onChatComplete={handleChatComplete} />
      </div>
      <ThemePopup
        isOpen={isPopupOpen}
        onClose={togglePopup}
        onThemeChange={changeTheme}
        currentTheme={theme}
      />
    </div>
  );
};

export default AiSafe;
