const { REACT_APP_BASE_URL } = process.env;
export const AppSafeUrl = {
	executionSet: `${REACT_APP_BASE_URL}app-safe/execution-set-details`,
	configSet: `/config-set`,
	configSetTestConnection: `${REACT_APP_BASE_URL}app-safe/config-set/test`,
	utilitySet: `${REACT_APP_BASE_URL}app-safe/utility-set`,
	historySet: `${REACT_APP_BASE_URL}app-safe/history`,
	anonymizationRun: `${REACT_APP_BASE_URL}app-safe/anonymization/execute`,
	jobSet: `/jobs`,
};

//  BASE URL  -> http://host:port/mdp/app-safe/
