import React, { useState, useEffect, useContext } from "react";
import "./PriceCard.scss";
import { HiXMark } from "react-icons/hi";
import { FcCheckmark } from "react-icons/fc";

import { UserContext } from "../../hooks/UserContext";
import Toast from "../toast/Toast";
import axios from "axios";

import cardIcon from "../../cardIcon.png";
import coinsIcon from "../../coins-icon.png";

import { GiTwoCoins } from "react-icons/gi";

const PriceCard = ({ name, features, range }) => {
  const [rangeValue, setRangeValue] = useState("0");
  const [price, setPrice] = useState();
  const [recordCount, setRecordCount] = useState();
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const { setLoading } = useContext(UserContext);

  useEffect(() => {
    updateRangeLabels();
  }, []);

  useEffect(() => {
    updateRangeLabels();
  }, [rangeValue, price, range]);

  //   useEffect(() => {}, [range]);

  const updateRangeLabels = () => {
    // debugger;
    if (range && range.length > 0) {
      setPrice(range[rangeValue].price);
      setRecordCount(range[rangeValue].recordCount);
    }
  };

  const handleRangeChange = (e) => {
    let current = e.target.value;
    setRangeValue(current);
    updateRangeLabels();
  };

  const onPayPress = async (oEvent) => {
    oEvent.preventDefault();

    if (name === "Business") {
      const link = document.createElement("a");
      link.href = "mailto:info@mayadataprivacy.eu";

      // Append to html link element page
      document.body.appendChild(link);

      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } else {
      let priceId = range[rangeValue].priceId;
      let quantity = 1; //range[rangeValue].recordCount;

      if (priceId) {
        setLoading(true);
        let oResponse = await axios
          .post(`/mdp/api/checkout`, {
            quantity: quantity,
            priceId: priceId,
            // customerId: "",
          })
          .then((res) => {
            if (res.data && res.data.redirectUrl.length > 0) {
              window.open(res.data.redirectUrl, "_self");
            } else {
              console.warn(`Creating Checkout Session ::::: No check out URL`);
            }
            // window.location.replace = res.data.redirectUrl;
          })
          .catch((oError) => {
            // window.open("https://www.google.com", "_self");
            // if (oError.request && oError.request.status === 303) {
            //   let response = JSON.parse(oError.request.response);
            //   window.open(response.redirectUrl, "_self");
            // } else {
            console.error(`Creating Checkout Session ::::: ${oError}`);
            setToastMessage("Failed to Create Checkout Session.");
            setShowToast(true);
            // }
          })
          .finally(() => {
            //busy dialog of
            setLoading(false);
          });
      }
    }
  };

  return (
    <div>
      <div className="Card">
        <div className="CardIconContainer">
          <div className="CardIcon">
            {" "}
            <img src={cardIcon} width="16.8" height="24" />
          </div>
        </div>
        <div className="Content">
          {/* <div className="Icon"></div> */}
          <div className="PlanTitle">{name}</div>
          <div className="PlanCost">
            {price || price == 0 ? (
              <span>
                €<span className="Amount">{price}</span>
              </span>
            ) : (
              <span className="CustomPlan">Corporate Plan</span>
            )}
          </div>
          <div className="RangeContainer">
            {range && range.length > 1 ? (
              <>
                <div className="RangeLabels">
                  {/* <span className="MinLabel">{range[0].recordCount}</span>
                  <span className="MaxLabel">
                    {range[range.length - 1].recordCount}
                  </span> */}

                  <span className="MinLabel">{range[0].recordCount}</span>
                  <input
                    type="range"
                    min="0"
                    className="RangeInput"
                    max={range.length - 1}
                    step="1"
                    value={rangeValue}
                    onChange={handleRangeChange}
                  />
                  <span className="MaxLabel">
                    {range[range.length - 1].recordCount}
                  </span>
                </div>
                <span className="RecordCount">
                  {recordCount}{" "}
                  <GiTwoCoins color="orange" className="CoinIcon" />
                  <span className="Spacer">/</span>
                  month
                </span>
              </>
            ) : name === "Free" ? (
              <>
                <div className="RangeLabels">
                  <span className="MinLabel"></span>
                  <span className="MaxLabel"></span>
                </div>
                <span className="RecordCount">
                  {recordCount}{" "}
                  <GiTwoCoins color="orange" className="CoinIcon" />
                </span>
              </>
            ) : name === "Business" ? (
              <>
                <div className="RangeLabels">
                  <span className="MinLabel"></span>
                  <span className="MaxLabel"></span>
                </div>
                <span className="RecordCount">
                  Unlimited <GiTwoCoins color="orange" className="CoinIcon" />
                  <span className="Spacer">/</span>
                  month
                </span>
              </>
            ) : (
              <></>
            )}
          </div>

          <div className="FeatureListWrapper">
            {/* <span className="Title">Quick Overview:</span> */}
            {features.map((item, index) => {
              let oFeatureItem;
              let oDefaultItem = (
                <div className="FeatureListItem">
                  <span className="ItemIcon">
                    <FcCheckmark />
                  </span>
                  <span className="ItemContent">{item}</span>
                </div>
              );
              if (name !== "Free" && index === 0) {
                oFeatureItem = <span className="Title">{oDefaultItem}</span>;
              } else {
                oFeatureItem = oDefaultItem;
              }
              return <div key={index}>{oFeatureItem}</div>;
            })}
          </div>
        </div>
        <div className="ButtonWrapper">
          <button className="ActionButton" onClick={onPayPress}>
            Start Now
          </button>
        </div>
      </div>
      <Toast
        message={toastMessage}
        show={showToast}
        hideToast={() => setShowToast(false)}
      />
    </div>
  );
};

export default PriceCard;
