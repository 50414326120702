import React, { Component } from 'react'
import './Uperpart.css';
import { Dropdown, Nav } from 'react-bootstrap';
import { BsCreditCard } from "react-icons/bs";
export default class Col2 extends Component {
  render() {
    return (
      <div className='planpolicy1'>
        <div className='planpolicy'>
          <h5>Plan</h5>
          <p>Subscription: Premium 40 coins, billed monthly</p>
        </div>
        <div  className='disytributon'>
          <h5>Distrubution Policy</h5>
          <p>Maximum overflow: 1 month (40 coins)</p>
        </div>
        <div className='dropdown'>
        <Dropdown>
        <Dropdown.Toggle className='drop' id="dropdown-button-dark-example1" variant="trasparent">
          <BsCreditCard />  Billing
        </Dropdown.Toggle>

        <Dropdown.Menu className='drop_menu' variant="gray">
          <Dropdown.Item className='drop_menu' href="#/action-1" >
            Action
          </Dropdown.Item>
          <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
          <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item href="#/action-4">Separated link</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

        </div>
        
        
      </div>
    )
  }
}

