import React, { useState, useEffect, useContext } from "react";
import "./ExecutionSet.scss";
import { BiArrowBack, BiSave } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { UserContext, useUserContext } from "../../hooks/UserContext";
import Popup from "../../components/popupModel/Popup";
import { serverAxios } from "../../utils/AxiosUtils";
import Select from "react-select";
import {
  getUtilitySetData,
  getConfigSetData,
  getExecutionSetDataById,
} from "../../hooks/apis/useAppSafeApi";
import { encryptFile, readFileAsText } from "../../hooks/encryption";
import { ConfigurationType } from "../../model/Constants";
import { useTranslation } from "react-i18next";
import { selectDropdownStyle } from "../defiSettings/PII/PiiData";
import NotFound from "../404/NotFound";
const { REACT_APP_SITE_TITLE, REACT_APP_ENCRYPTION_KEY } = process.env;

const ExecutionSet = () => {
  const { t } = useTranslation("execution");
  const { setLoading } = useContext(UserContext);
  const { id } = useParams();
  const { showToast } = useUserContext();
  const [executionSets, setExecutionSets] = useState([]);
  const [utilitySets, setUtilitySets] = useState([]);
  const [configSets, setConfigSets] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [executionSetDetailsList, setExecutionSetDetailsList] = useState([]);
  const [executionSetDetailsListById, setExecutionSetDetailsListById] =
    useState([]);
  const [formData, setFormData] = useState({
    executionSetName: "",
    executionSetType: "HANA",
    executionSetType: "HANA",
    executionSetId: "",
    executionSetDescription: "",
  });
  const executionSetDataById = () => {
    getExecutionSetDataById(id).then((res) => {
      setExecutionSets(res);
      setFormData(res.data);
      setExecutionSetDetailsListById(map(res.data.executionSetDetailsList));
    }).catch(err => {
      if (err.message === "There is some error in your request.") {
        setNotFound(true)
      }
    });
  };
  useEffect(() => {
    getUtilitySetData().then((res) => {
      setUtilitySets(res);
    });
    getConfigSetData().then((res) => {
      setConfigSets(res);
    });
    if (id) {
      executionSetDataById();
    }
  }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  useEffect(() => {
    let timer;

    if (showAlert) {
      timer = setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    }

    return () => clearTimeout(timer);
  }, [showAlert]);
  const [filteredExecutionSetDetailsList, setFilteredExecutionSetDetailsList] =
    useState([]);
  const handleSaveButtonClick = async () => {
    const apiUrl = id ? `execution-set-details/${id}` : "execution-set-details";
    if (!formData.executionSetName) {
      showToast("Execution name is empty.", "warning");
      return;
    }

    // const encryptedData = await encryptFile();

    const dataToSend = {
      executionSetDetailsList: [
        ...executionSetDetailsListById,
        ...executionSetDetailsList,
      ],
      // encryptedkey: encryptedkey,
      executionSetName: formData.executionSetName,
      executionSetType: formData.executionSetType,
      executionSetDescription: formData.executionSetDescription,
    };
    if (dataToSend.executionSetDetailsList.length === 0) {
      showToast("Please add atleast one step", "warning");
      return;
    }
    setLoading(true)
    serverAxios.appSafeAxios
      .post(apiUrl, dataToSend)
      .then((res) => {
        const receivedData = res.data;
        showToast(receivedData.message, "success");
        navigate("/Execution-Home");
      })
      .catch((err) => {
        showToast(err.message, "fail");
      })
      .finally(() => {
        setLoading(false)
      });
  };
  const handleFileUpload = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        return e.target.result;
      };
      reader.readAsText(file);
    }
  };
  const handleChange = async (index, key, value) => {
    if (key === "script" && value) {
      try {
        const result = await readFileAsText(value);
        const updatedList = [...filteredExecutionSetDetailsList];
        updatedList[index].script = result;
        updatedList[index].fileName = value.name;
        // updatedList[index].encryptedKey = result.encryptedKey;
        setFilteredExecutionSetDetailsList(updatedList);
      } catch (error) {
        console.error("Encryption failed:", error);
      }
    } else {
      const updatedList = [...filteredExecutionSetDetailsList];
      updatedList[index][key] = value;
      setFilteredExecutionSetDetailsList(updatedList);
    }
    const updatedFilteredList = filteredExecutionSetDetailsList.filter(
      (item) =>
        item.configSetId !== "" &&
        (item.utilitySetId !== "" || item.script !== "")
    );
    setExecutionSetDetailsList(updatedFilteredList);
  };
  const [
    executionSetDetailsListByIdToPost,
    setExecutionSetDetailsListByIdToPost,
  ] = useState([]);
  const handleChangeById = async (index, key, value) => {
    if (key === "script" && value) {
      const result = await encryptFile(value);
      const updatedList = [...executionSetDetailsListById];
      updatedList[index][key].script = result;
      updatedList[index].fileName = value.name;
      // updatedList[index].encryptedKey = result.encryptedKey;
      // setFileName(value.name)
      setExecutionSetDetailsListById(updatedList);
    } else {
      const updatedList = [...executionSetDetailsListById];
      updatedList[index][key] = value;
      setExecutionSetDetailsListById(updatedList);
    }
    const updatedFilteredList = executionSetDetailsListById.filter(
      (item) =>
        item.configSetId !== "" &&
        (item.utilitySetId !== "" || item.script !== "")
    );
    setExecutionSetDetailsListById(updatedFilteredList);
  };

  const removeStep = (index) => {
    const updatedList = [...filteredExecutionSetDetailsList];
    updatedList.splice(index, 1);
    setFilteredExecutionSetDetailsList(updatedList);
  };
  const removeStepById = (index) => {
    const updatedList = [...executionSetDetailsListById];
    updatedList.splice(index, 1);
    setExecutionSetDetailsListById(updatedList);
  };
  const stepValue = id
    ? executionSetDetailsListById.length + executionSetDetailsList.length + 1
    : executionSetDetailsList.length + 1;
  const addStep = (type) => {
    const newStep = {
      type,
      configSetId: "",
      step: stepValue,
      utilitySetId: type === "UTILITY" ? "" : null,
      script: type === "SCRIPT" ? "" : null,
      encryptedKey: type === "SCRIPT" ? "" : null,
      fileName: type === "SCRIPT" ? "" : null,
    };
    setFilteredExecutionSetDetailsList([
      ...filteredExecutionSetDetailsList,
      newStep,
    ]);
  };
  const [fileName, setFileName] = useState("");
  const map = (details) => {
    return details.map((detail) => {
      const type = detail.type;
      const data = {
        configSetId: detail.configSet.id,
        step: detail.step,
        type: type,
      };

      if (type === "UTILITY") {
        return { ...data, utilitySetId: detail.utilitySet.utilitySetId };
      }
      // setFileName(detail.script);
      return {
        ...data,
        fileName: detail.fileName,
        script: detail.script,
        encryptedKey: detail.encryptedKey,
      };
    });
  };
  let navigate = useNavigate();
  const stepsOfExecutionSet = () => {
    const optionsConfigSet = configSets.map((val) => ({
      value: val.id,
      label: val.configSetName,
    }));
    const optionsUtilitySet = utilitySets.map((value) => ({
      value: value.utilitySetId,
      label: value.utilitySetName,
    }));

    return (
      <div className="step-section">
        <div className="input-group-section">
          <p className="basic-info font font-5">{t("steps")}</p>
          {id &&
            executionSetDetailsListById.map((step, index) =>
              step.type === "UTILITY" ? (
                <>
                  <div className="utility-line">
                    <div className="input-section">
                      <label className="level-text font-5">
                        {t("Configuration Set")}
                      </label>
                      {/* <select
                        className="dropDown-field-maya"
                        value={step.configSetId}
                        onChange={(e) =>
                          handleChangeById(index, "configSetId", e.target.value)
                        }
                      >
                        <option></option>
                        {configSets.map((val, e) => (
                          <option key={e} value={val.id}>
                            {val.configSetName}
                          </option>
                        ))}
                      </select> */}
                      <Select
                        styles={selectDropdownStyle}
                        menuPlacement="auto"
                        value={optionsConfigSet.find((option) => option.value === step.configSetId)}
                        onChange={(selectedOption) =>
                          handleChangeById(index, "configSetId", selectedOption.value)
                        }
                        options={optionsConfigSet}
                        isClearable
                        placeholder="Select config set"
                      />
                    </div>
                    <div className="input-section">
                      <label className="level-text font-5">
                        {t("Utility Set")}
                      </label>
                      {/* <select
                        className="dropDown-field-maya"
                        value={step.utilitySetId}
                        onChange={(e) =>
                          handleChangeById(
                            index,
                            "utilitySetId",
                            e.target.value
                          )
                        }
                      >
                        <option></option>
                        {utilitySets.map((value, e) => (
                          <option key={e} value={value.utilitySetId}>
                            {value.utilitySetName}
                          </option>
                        ))}
                      </select> */}
                      <Select
                        styles={selectDropdownStyle}
                        menuPlacement="auto"
                        value={optionsUtilitySet.find((option) => option.value === step.utilitySetId)}
                        onChange={(selectedOption) =>
                          handleChangeById(index, "utilitySetId", selectedOption?.value || "")
                        }
                        options={optionsUtilitySet}
                        isClearable
                        placeholder="Select utility set"
                      />
                    </div>
                    <div
                      className="for-remove-line cursor-pointer"
                      onClick={() => removeStepById(index)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.29303 4.29357C4.48056 4.1061 4.73487 4.00078 5.00003 4.00078C5.26519 4.00078 5.5195 4.1061 5.70703 4.29357L10 8.58657L14.293 4.29357C14.3853 4.19806 14.4956 4.12188 14.6176 4.06947C14.7396 4.01706 14.8709 3.98947 15.0036 3.98832C15.1364 3.98717 15.2681 4.01247 15.391 4.06275C15.5139 4.11303 15.6255 4.18728 15.7194 4.28117C15.8133 4.37507 15.8876 4.48672 15.9379 4.60962C15.9881 4.73251 16.0134 4.86419 16.0123 4.99697C16.0111 5.12975 15.9835 5.26097 15.9311 5.38297C15.8787 5.50498 15.8025 5.61532 15.707 5.70757L11.414 10.0006L15.707 14.2936C15.8892 14.4822 15.99 14.7348 15.9877 14.997C15.9854 15.2592 15.8803 15.51 15.6948 15.6954C15.5094 15.8808 15.2586 15.986 14.9964 15.9882C14.7342 15.9905 14.4816 15.8897 14.293 15.7076L10 11.4146L5.70703 15.7076C5.51843 15.8897 5.26583 15.9905 5.00363 15.9882C4.74143 15.986 4.49062 15.8808 4.30521 15.6954C4.1198 15.51 4.01463 15.2592 4.01236 14.997C4.01008 14.7348 4.11087 14.4822 4.29303 14.2936L8.58603 10.0006L4.29303 5.70757C4.10556 5.52004 4.00024 5.26573 4.00024 5.00057C4.00024 4.73541 4.10556 4.4811 4.29303 4.29357Z"
                          fill="#D0D5DD"
                        />
                      </svg>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="utility-line">
                    <div className="input-section">
                      <label className="level-text font-5">
                        {t("Configuration Set")}
                      </label>
                      {/* <select
                        styles={selectDropdownStyle}
                                menuPlacement="auto"
                        value={step.configSetId}
                        onChange={(e) => {
                          handleChangeById(
                            index,
                            "configSetId",
                            e.target.value
                          );
                        }}
                      >
                        <option>Select an Option</option>
                        {configSets.map((val, e) => (
                          <option key={e} value={val.id}>
                            {val.configSetName}
                          </option>
                        ))}
                      </select> */}
                      <Select
                        styles={selectDropdownStyle}
                        menuPlacement="auto"
                        value={optionsConfigSet.find((option) => option.value === step.configSetId)}
                        onChange={(selectedOption) =>
                          handleChangeById(index, "configSetId", selectedOption?.value || "")
                        }
                        options={optionsConfigSet}
                        isClearable
                        placeholder="Select an Option"
                      />
                    </div>
                    <div className=" input-section ">
                      <label className="level-text  font-5">
                        {t("Script")}
                      </label>

                      <div className="custom-file-input pt-0 pb-0 input-field-shadow cursor-pointer font-6">
                        <label htmlFor="fileInput" className="input-label">
                          {fileName || step.fileName}
                        </label>
                        <input
                          type="file"
                          id="fileInput"
                          className="hidden-file-input"
                          onChange={(e) => {
                            const selectedFile = e.target.files[0];
                            if (selectedFile) {
                              setFileName(selectedFile.name);
                              handleChangeById(index, "script", selectedFile);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="for-remove-line cursor-pointer"
                      onClick={() => removeStepById(index)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.29303 4.29357C4.48056 4.1061 4.73487 4.00078 5.00003 4.00078C5.26519 4.00078 5.5195 4.1061 5.70703 4.29357L10 8.58657L14.293 4.29357C14.3853 4.19806 14.4956 4.12188 14.6176 4.06947C14.7396 4.01706 14.8709 3.98947 15.0036 3.98832C15.1364 3.98717 15.2681 4.01247 15.391 4.06275C15.5139 4.11303 15.6255 4.18728 15.7194 4.28117C15.8133 4.37507 15.8876 4.48672 15.9379 4.60962C15.9881 4.73251 16.0134 4.86419 16.0123 4.99697C16.0111 5.12975 15.9835 5.26097 15.9311 5.38297C15.8787 5.50498 15.8025 5.61532 15.707 5.70757L11.414 10.0006L15.707 14.2936C15.8892 14.4822 15.99 14.7348 15.9877 14.997C15.9854 15.2592 15.8803 15.51 15.6948 15.6954C15.5094 15.8808 15.2586 15.986 14.9964 15.9882C14.7342 15.9905 14.4816 15.8897 14.293 15.7076L10 11.4146L5.70703 15.7076C5.51843 15.8897 5.26583 15.9905 5.00363 15.9882C4.74143 15.986 4.49062 15.8808 4.30521 15.6954C4.1198 15.51 4.01463 15.2592 4.01236 14.997C4.01008 14.7348 4.11087 14.4822 4.29303 14.2936L8.58603 10.0006L4.29303 5.70757C4.10556 5.52004 4.00024 5.26573 4.00024 5.00057C4.00024 4.73541 4.10556 4.4811 4.29303 4.29357Z"
                          fill="#D0D5DD"
                        />
                      </svg>
                    </div>
                  </div>
                </>
              )
            )}

          {filteredExecutionSetDetailsList.map((step, index) => (
            <div key={index} className="line-and-cross">
              <div className="util-sc-line">
                {step.type === "UTILITY" && (
                  <div className="utility-line">
                    <div className="input-section">
                      <label className="level-text font-5">
                        {t("Configuration Set")}
                      </label>
                      <Select
                        styles={selectDropdownStyle}
                        menuPlacement="auto"
                        value={optionsConfigSet.find((option) => option.value === step.configSetId)}
                        onChange={(selectedOption) =>
                          handleChange(index, "configSetId", selectedOption?.value || "")
                        }
                        options={optionsConfigSet}
                        isClearable
                        placeholder="Select a Configuration Set"
                      />
                    </div>
                    <div className="input-section">
                      <label className="level-text font-5">
                        {t("Utility Set")}
                      </label>
                      <Select
                        styles={selectDropdownStyle}
                        menuPlacement="auto"
                        value={optionsUtilitySet.find((option) => option.value === step.utilitySetId)}
                        onChange={(selectedOption) =>
                          handleChange(index, "utilitySetId", selectedOption?.value || "")
                        }
                        options={optionsUtilitySet}
                        isClearable
                        placeholder="Select a Utility Set"
                      />
                    </div>
                    <div
                      className="for-remove-line cursor-pointer"
                      onClick={() => removeStep(index)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.29303 4.29357C4.48056 4.1061 4.73487 4.00078 5.00003 4.00078C5.26519 4.00078 5.5195 4.1061 5.70703 4.29357L10 8.58657L14.293 4.29357C14.3853 4.19806 14.4956 4.12188 14.6176 4.06947C14.7396 4.01706 14.8709 3.98947 15.0036 3.98832C15.1364 3.98717 15.2681 4.01247 15.391 4.06275C15.5139 4.11303 15.6255 4.18728 15.7194 4.28117C15.8133 4.37507 15.8876 4.48672 15.9379 4.60962C15.9881 4.73251 16.0134 4.86419 16.0123 4.99697C16.0111 5.12975 15.9835 5.26097 15.9311 5.38297C15.8787 5.50498 15.8025 5.61532 15.707 5.70757L11.414 10.0006L15.707 14.2936C15.8892 14.4822 15.99 14.7348 15.9877 14.997C15.9854 15.2592 15.8803 15.51 15.6948 15.6954C15.5094 15.8808 15.2586 15.986 14.9964 15.9882C14.7342 15.9905 14.4816 15.8897 14.293 15.7076L10 11.4146L5.70703 15.7076C5.51843 15.8897 5.26583 15.9905 5.00363 15.9882C4.74143 15.986 4.49062 15.8808 4.30521 15.6954C4.1198 15.51 4.01463 15.2592 4.01236 14.997C4.01008 14.7348 4.11087 14.4822 4.29303 14.2936L8.58603 10.0006L4.29303 5.70757C4.10556 5.52004 4.00024 5.26573 4.00024 5.00057C4.00024 4.73541 4.10556 4.4811 4.29303 4.29357Z"
                          fill="#D0D5DD"
                        />
                      </svg>
                    </div>
                  </div>
                )}
                {step.type === "SCRIPT" && (
                  <div className="utility-line">
                    <div className="input-section">
                      <label className="level-text font-5">
                        {t("Configuration Set")}
                      </label>
                      {/* <select
                        className="dropDown-field-maya"
                        value={step.configSetId}
                        onChange={(e) => {
                          handleChange(index, "configSetId", e.target.value);
                        }}
                      >
                        <option>Select an Option</option>
                        {configSets.map((val, e) => (
                          <option key={e} value={val.id}>
                            {val.configSetName}
                          </option>
                        ))}
                      </select> */}
                      <Select
                        styles={selectDropdownStyle}
                        menuPlacement="auto"
                        value={optionsConfigSet.find((option) => option.value === step.configSetId)}
                        onChange={(selectedOption) =>
                          handleChange(index, "configSetId", selectedOption?.value || "")
                        }
                        options={optionsConfigSet}
                        isClearable
                        placeholder="Select a Configuration Set"
                      />
                    </div>
                    <div className=" input-section ">
                      <label className="level-text  font-5">
                        {t("Script")}
                      </label>
                      <input
                        type="file"
                        className="input-field-shadow cursor-pointer font-6"
                        accept=".sql"
                        onChange={(e) =>
                          handleChange(index, "script", e.target.files[0])
                        }
                      />
                    </div>
                    <div
                      className="for-remove-line cursor-pointer"
                      onClick={() => removeStep(index)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.29303 4.29357C4.48056 4.1061 4.73487 4.00078 5.00003 4.00078C5.26519 4.00078 5.5195 4.1061 5.70703 4.29357L10 8.58657L14.293 4.29357C14.3853 4.19806 14.4956 4.12188 14.6176 4.06947C14.7396 4.01706 14.8709 3.98947 15.0036 3.98832C15.1364 3.98717 15.2681 4.01247 15.391 4.06275C15.5139 4.11303 15.6255 4.18728 15.7194 4.28117C15.8133 4.37507 15.8876 4.48672 15.9379 4.60962C15.9881 4.73251 16.0134 4.86419 16.0123 4.99697C16.0111 5.12975 15.9835 5.26097 15.9311 5.38297C15.8787 5.50498 15.8025 5.61532 15.707 5.70757L11.414 10.0006L15.707 14.2936C15.8892 14.4822 15.99 14.7348 15.9877 14.997C15.9854 15.2592 15.8803 15.51 15.6948 15.6954C15.5094 15.8808 15.2586 15.986 14.9964 15.9882C14.7342 15.9905 14.4816 15.8897 14.293 15.7076L10 11.4146L5.70703 15.7076C5.51843 15.8897 5.26583 15.9905 5.00363 15.9882C4.74143 15.986 4.49062 15.8808 4.30521 15.6954C4.1198 15.51 4.01463 15.2592 4.01236 14.997C4.01008 14.7348 4.11087 14.4822 4.29303 14.2936L8.58603 10.0006L4.29303 5.70757C4.10556 5.52004 4.00024 5.26573 4.00024 5.00057C4.00024 4.73541 4.10556 4.4811 4.29303 4.29357Z"
                          fill="#D0D5DD"
                        />
                      </svg>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="add-line ">
          <div
            className="one-0 btn-low-purple cursor-pointer  font-6"
            onClick={() => addStep("UTILITY")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10 4.16602V15.8327M4.16667 9.99935H15.8333"
                stroke="#6941C6"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {t("addUtilitySet")}
          </div>
          <div
            className="btn-low-purple cursor-pointer one-01 font-6"
            onClick={() => addStep("SCRIPT")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5M14.1667 6.66667L10 2.5M10 2.5L5.83333 6.66667M10 2.5V12.5"
                stroke="#6941C6"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {t("addScriptFile")}
          </div>
        </div>
      </div>
    );
  };
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState("");
  const handleOpenPopup = (text) => {
    setPopupText(text);
    setShowPopup(true);
  };
  const handleConfirm = (result) => {
    if (result) {
      handleSaveButtonClick();
      setShowPopup(false);
    } else {
      setShowPopup(false);
    }
  };
  const onClickSave = (e) => {
  e.preventDefault()
    handleOpenPopup(
      "Do you wish to proceed with Saving the data?"
    )
  }
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleOpenPopup("Do you wish to proceed with Saving the data?");
    }
  };
  if (notFound) {
    return <NotFound />;
  }
  return (
    <>
      <Helmet>
        <title>Execution Set - {REACT_APP_SITE_TITLE}</title>
      </Helmet>
      <div>
        {" "}
        {showPopup && (
          <div className="backdropPopupStyle">
            <Popup
              popupTitle={"Save"}
              popupMessage={popupText}
              isConfirm={handleConfirm}
            />
          </div>
        )}
        <form className="main-section" onSubmit={onClickSave} onKeyPress={handleKeyPress}>
         
          <div className="header-section-maya">
            <div className="page-header">
              <button
                type=""
                className="res-back-btn font-5"
                onClick={() => navigate(-1)}
              >
                <BiArrowBack /> {t("ExectionSet")}
              </button>
              <div className="heading1 head-form font-5">
                {t("newExecutionSet")}
              </div>
            </div>
          </div>
          <div className="form-content body-section-maya">
            <div className="form">
              <div>
                <div className="input-group-section">
                  <div className="basic-info font-5">{t("info")}</div>
                  <div className="input-section ">
                    <label className="level-text font font-5">
                      {t("ename")}
                    </label>
                    <input
                      className="input-field-shadow font-4"
                      value={formData.executionSetName}
                      name="executionSetName"
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* <div className="input-section ">
                    <label className="level-text font font-5">
                      {t("type")}
                    </label>
                    <select
                      className="dropDown-field-maya font-4"
                      value={formData.executionSetType}
                      name="executionSetType"
                      onChange={handleInputChange}
                    >
                      executionSetType
                      
                      
                      {ConfigurationType.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="drop-select"
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div> */}
                  <div className="input-section ">
                    <label className="level-text font font-5">
                      {t("Description")}
                    </label>
                    <textarea
                      className="input-field-shadow font-4"
                      value={formData.executionSetDescription}
                      name="executionSetDescription"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                {stepsOfExecutionSet()}
              </div>
            </div>
          </div>
          <div className="util-footer">
            <div className="cancel-div">
              <button
                type="reset"
                className="res-back-btn font-5"
                onClick={() => {
                  if (id) {
                    executionSetDataById();
                  } else {
                    setFormData({
                      executionSetName: "",
                      executionSetType: "",
                      executionSetDescription: "",
                      stepsOfExecutionSet: [],
                      executionSetId: "",
                    });
                    setExecutionSetDetailsList([])
                  }
                }}
              >
                <RxCross2 /> {t("Cancel")}
              </button>
            </div>
            <div className="save-div">
              <button
                type="submit"
                className="btn-purple font-5"
              >
                <BiSave color="#FFFFFF" /> {id ? t("save") : t("close")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ExecutionSet;
