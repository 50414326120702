import "./BusySpinner.scss";

const BusySpinner = ({ message }) => {
  return (
    <div className="spinner-container">
      <div className="loading-spinner"></div>
      <div className="message">
        <p>{message}</p>
      </div>
    </div>
  );
};

export default BusySpinner;
