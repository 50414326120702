import { React, useState, useEffect } from "react";
import { Navbar, Container, Form, Button, Table, Modal } from "react-bootstrap";
import Select from "react-select";
import logo from "../logo_maya.png";

export default function DBAnonymization() {
  const [show, setShow] = useState(false);
  const [selectedSchema, setSelectedSchema] = useState();
  const [aSelectedTables, setSelectedTables] = useState([]);
  const [tableEntries, setTableEntries] = useState([]);
  useEffect(() => {}, [selectedSchema, aSelectedTables, tableEntries]);

  const aDataTypes = [
    {
      id: 1,
      type: "Text",
      description: "",
    },
    {
      id: 2,
      type: "Date",
      description: "",
    },
    {
      id: 3,
      type: "Number",
      description: "",
    },
  ];

  const aSchemas = [
    {
      id: 1,
      schemaName: "E2P",
    },
    {
      id: 2,
      schemaName: "DM1",
    },
    {
      id: 3,
      schemaName: "SFDC",
    },
    {
      id: 4,
      schemaName: "ORAP",
    },
    {
      id: 5,
      schemaName: "SQLANA",
    },
  ];

  const aTables = [
    {
      id: 1,
      tableName: "Master_Data_Business_Part",
      aFields: [],
    },
    {
      id: 2,
      tableName: "Master_data_Product",
      aFields: [],
    },
    {
      id: 3,
      tableName: "Transaction_data_Sales",
      aFields: [],
    },
    {
      id: 4,
      tableName: "Transaction_data_Loans",
      aFields: [],
    },
    {
      id: 5,
      tableName: "Master_data_BP_Identity",
      aFields: [],
    },
    {
      id: 6,
      tableName: "Master_data_BP_Address",
      aFields: [],
    },
    {
      id: 7,
      tableName: "Transaction_data_bank_records",
      aFields: [],
    },
    {
      id: 8,
      tableName: "Transaction_data_Salary_records",
      aFields: [],
    },
    {
      id: 9,
      tableName: "TX_Supplier_invoice",
      aFields: [],
    },
    {
      id: 10,
      tableName: "TX_Purchase_invoices",
      aFields: [],
    },
    {
      id: 11,
      tableName: "PERSONAL_MASTER",
      aFields: [
        {
          fieldName: "CustomerId",
          dataType: 3,
          description: "",
          maxLength: null,
          anonMethod: 0,
          sensitivity: 0,
        },
        {
          fieldName: "PERSON_NAME",
          dataType: 1,
          description: "",
          maxLength: 40,
          anonMethod: 1,
          sensitivity: 2,
        },
        {
          fieldName: "Email_ID",
          dataType: 1,
          description: "",
          maxLength: 35,
          anonMethod: 2,
          sensitivity: 2,
        },
        {
          fieldName: "Phone_Number",
          dataType: 3,
          description: "",
          maxLength: null,
          anonMethod: 3,
          sensitivity: 2,
        },
        {
          fieldName: "Address_Eircode",
          dataType: 1,
          description: "",
          maxLength: 7,
          anonMethod: 4,
          sensitivity: 2,
        },
        {
          fieldName: "Date_of_Birth",
          dataType: 2,
          description: "",
          maxLength: null,
          anonMethod: 8,
          sensitivity: 2,
        },
        {
          fieldName: "Customer_Since",
          dataType: 2,
          description: "",
          maxLength: null,
          anonMethod: 7,
          sensitivity: 1,
        },
      ],
    },
    {
      id: 12,
      tableName: "BANK_DATA",
      aFields: [
        {
          fieldName: "CustomerId",
          dataType: 3,
          description: "",
          maxLength: null,
          anonMethod: 0,
          sensitivity: 0,
        },
        {
          fieldName: "Age",
          dataType: 3,
          description: "",
          maxLength: null,
          anonMethod: 9,
          sensitivity: 2,
        },
        {
          fieldName: "PERSON_NAME",
          dataType: 1,
          description: "",
          maxLength: 40,
          anonMethod: 1,
          sensitivity: 2,
        },
        {
          fieldName: "Job",
          dataType: 1,
          description: "",
          maxLength: 25,
          anonMethod: 0,
          sensitivity: 0,
        },
        {
          fieldName: "Marital_status",
          dataType: 1,
          description: "",
          maxLength: 10,
          anonMethod: 0,
          sensitivity: 0,
        },
        {
          fieldName: "CURRENT_AC_BAL",
          dataType: 3,
          description: "",
          maxLength: null,
          anonMethod: 0,
          sensitivity: 0,
        },
        {
          fieldName: "Education_level",
          dataType: 1,
          description: "",
          maxLength: 25,
          anonMethod: 0,
          sensitivity: 0,
        },
        {
          fieldName: "Credit_default",
          dataType: 1,
          description: "",
          maxLength: 10,
          anonMethod: 0,
          sensitivity: 0,
        },
        {
          fieldName: "Housing_loan",
          dataType: 1,
          description: "",
          maxLength: 10,
          anonMethod: 0,
          sensitivity: 0,
        },
        {
          fieldName: "Personal_loan",
          dataType: 1,
          description: "",
          maxLength: 10,
          anonMethod: 0,
          sensitivity: 0,
        },
        {
          fieldName: "Last_contact_channel",
          dataType: 1,
          description: "",
          maxLength: 10,
          anonMethod: 0,
          sensitivity: 0,
        },
        {
          fieldName: "Last_contact_month",
          dataType: 1,
          description: "",
          maxLength: 10,
          anonMethod: 0,
          sensitivity: 0,
        },
        {
          fieldName: "last_contact_day",
          dataType: 1,
          description: "",
          maxLength: 10,
          anonMethod: 0,
          sensitivity: 0,
        },
        {
          fieldName: "Last_contact_delay",
          dataType: 3,
          description: "",
          maxLength: null,
          anonMethod: 0,
          sensitivity: 0,
        },
        {
          fieldName: "Number_of_contacts_in_the_last_12_months",
          dataType: 3,
          description: "",
          maxLength: null,
          anonMethod: 0,
          sensitivity: 0,
        },
        {
          fieldName: "Commercial_pressure",
          dataType: 3,
          description: "",
          maxLength: null,
          anonMethod: 0,
          sensitivity: 0,
        },
        {
          fieldName: "Previous_campaign_result",
          dataType: 1,
          description: "",
          maxLength: 20,
          anonMethod: 0,
          sensitivity: 0,
        },
        {
          fieldName: "Eco_employment_var_rate",
          dataType: 3,
          description: "",
          maxLength: null,
          anonMethod: 0,
          sensitivity: 0,
        },
        {
          fieldName: "Eco_consumer_price_index",
          dataType: 3,
          description: "",
          maxLength: null,
          anonMethod: 0,
          sensitivity: 0,
        },
        {
          fieldName: "Eco_consumer_confidence_index",
          dataType: 3,
          description: "",
          maxLength: null,
          anonMethod: 0,
          sensitivity: 0,
        },
        {
          fieldName: "Eco_euribor3m",
          dataType: 3,
          description: "",
          maxLength: null,
          anonMethod: 0,
          sensitivity: 0,
        },
        {
          fieldName: "TARGET",
          dataType: 1,
          description: "",
          maxLength: 10,
          anonMethod: 0,
          sensitivity: 0,
        },
        {
          fieldName: "Channel",
          dataType: 1,
          description: "",
          maxLength: 10,
          anonMethod: 0,
          sensitivity: 0,
        },
      ],
    },
  ];

  const aMethods = [
    {
      id: 0,
      dataTypeId: 0,
      methodName: "No Change",
      description: "",
    },
    {
      id: 1,
      dataTypeId: 1,
      methodName: "Names",
      description: "",
    },
    {
      id: 2,
      dataTypeId: 1,
      methodName: "Emails",
      description: "",
    },
    {
      id: 3,
      dataTypeId: 1,
      methodName: "Phone No",
      description: "",
    },
    {
      id: 4,
      dataTypeId: 1,
      methodName: "Consistent Values",
      description: "",
    },
    {
      id: 5,
      dataTypeId: 1,
      methodName: "Clear Values",
      description: "",
    },
    {
      id: 6,
      dataTypeId: 2,
      methodName: "Random",
      description: "",
    },
    {
      id: 7,
      dataTypeId: 2,
      methodName: "Date Same Year",
      description: "",
    },
    {
      id: 8,
      dataTypeId: 2,
      methodName: "Date Adult",
      description: "",
    },
    {
      id: 9,
      dataTypeId: 3,
      methodName: "Differential Privacy",
      description: "",
    },
    {
      id: 10,
      dataTypeId: 3,
      methodName: "Randomization",
      description: "",
    },
  ];

  const aTableOptions = aTables.map((oTable) => {
    return { value: oTable.id, label: oTable.tableName };
  });

  const [dataType, setDataTypes] = useState(aDataTypes);
  const [anonMethodData, setAnonMethods] = useState(aMethods);

  const aSchemaOptions = aSchemas.map((oSchema) => {
    return { value: oSchema.id, label: oSchema.schemaName };
  });

  const onExecuteClick = (e) => {
    //if (tableEntries.length === 0) return;
    setShow(true);
  };

  const onViewSelectedClick = (e) => {
    let aTableData = [];
    aSelectedTables.forEach((oSelectedTable) => {
      aTableData = [
        ...aTableData,
        ...aTables[oSelectedTable.value - 1].aFields.map((oField) => {
          return {
            fieldName: `${oSelectedTable.label}.${oField.fieldName}`,
            dataType: oField.dataType,
            description: oField.description,
            maxLength: oField.maxLength,
            anonMethod: 0,
            sensitivity: 0,
          };
        }),
      ];
    });
    setTableEntries([...aTableData]);
  };

  const onLoadFuncSetClick = (e) => {
    let aTableData = [];
    let aTablesToSelect = [aTableOptions[10], aTableOptions[11]];

    setSelectedTables(aTablesToSelect);
    aTablesToSelect.forEach((oSelectedTable) => {
      aTableData = [
        ...aTableData,
        ...aTables[oSelectedTable.value - 1].aFields.map((oField) => {
          return {
            fieldName: `${oSelectedTable.label}.${oField.fieldName}`,
            dataType: oField.dataType,
            description: oField.description,
            maxLength: oField.maxLength,
            anonMethod: oField.anonMethod,
            sensitivity: oField.sensitivity,
          };
        }),
      ];
    });
    setTableEntries([...aTableData]);
  };

  const onDataTypeChange = (index, event) => {
    // event.preventDefault();
    let aData = tableEntries;
    aData[index].dataType = parseInt(event.target.value, 10);
    let aUpdatedMethodList = aMethods.filter((item) => {
      return aData[index].dataType === item.dataTypeId;
    });
    setAnonMethods(aUpdatedMethodList);
    setTableEntries([...aData]);
  };

  const onAnonMethodChange = (index, event) => {
    // event.preventDefault();
    let aData = tableEntries;
    aData[index].anonMethod = parseInt(event.target.value, 10);
    setTableEntries([...aData]);
  };

  const onSensitivityChange = (index, event) => {
    // event.preventDefault();
    let aData = tableEntries;
    aData[index].sensitivity = parseInt(event.target.value, 10);
    setTableEntries([...aData]);
  };

  const DisplayRows = tableEntries.map((row, index) => {
    let anMethod = aMethods.filter((item) => {
      return row.dataType === item.dataTypeId;
    });
    return (
      <tr key={row.fieldName}>
        <td>{row.fieldName}</td>
        <td>{row.maxLength}</td>
        <td>
          <select
            className="form-select"
            defaultValue={row.dataType}
            onChange={(e) => onDataTypeChange(index, e)}
          >
            {dataType.map((dType) => {
              return (
                <option key={dType.id} value={dType.id}>
                  {dType.type}
                </option>
              );
            })}
          </select>
        </td>
        <td>
          <select
            className="form-select"
            value={row.anonMethod}
            onChange={(e) => onAnonMethodChange(index, e)}
          >
            <option key="0" value="0">
              No Change
            </option>
            {anMethod.map((method) => {
              return (
                <option key={method.id} value={method.id}>
                  {method.methodName}
                </option>
              );
            })}
          </select>
        </td>
        <td>
          <select
            className="form-select"
            value={row.sensitivity}
            onChange={(e) => onSensitivityChange(index, e)}
          >
            <option key="" value="0">
              Not Relevant
            </option>
            <option key="1" value="1">
              Confidential
            </option>
            <option key="2" value="2">
              Personal
            </option>
          </select>
        </td>
      </tr>
    );
  });

  const style = {
    backgroundColor: "#F8F8F8",
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "50px",
    width: "100%",
  };

  return (
    <>
      <Navbar bg="white" className="py-3" variant="ligth">
        <Container>
          <Navbar.Brand className="fw-bolder">
            <h2>
              <img
                alt=""
                src={logo}
                width="48"
                height="48"
                className="d-inline-block align-top"
              />{" "}
              MAYA Data Privacy
            </h2>
          </Navbar.Brand>
        </Container>
      </Navbar>

      <div className="container d-flex flex-wrap justify-content-center">
        <h5 className="col-12 col-lg-auto mb-2 mb-lg-0 me-lg-auto">
          <b>
            <i>DB Anonymization</i>
          </b>
        </h5>
      </div>
      <div className="p-3">
        <Form>
          <Form.Group className="mb-3 col-4" controlId="formInputSchemaName">
            <Form.Label>Database Schema</Form.Label>
            <Select
              onChange={setSelectedSchema}
              value={selectedSchema}
              options={aSchemaOptions}
            ></Select>
          </Form.Group>

          <Form.Group className="mb-3 col-4" controlId="formInputTableName">
            <Form.Label>Table Name</Form.Label>
            <Select
              value={aSelectedTables}
              onChange={setSelectedTables}
              options={aTableOptions}
              isMulti="true"
            ></Select>
          </Form.Group>
          <div className="justify-content-md-end">
            <Button
              className="btn btn-primary me-md-2"
              type="button"
              onClick={() => {
                onViewSelectedClick();
              }}
              disabled={!selectedSchema}
            >
              View Selected
            </Button>
            <Button
              className="btn btn-primary me-md-2"
              type="button"
              onClick={() => {
                onLoadFuncSetClick();
              }}
              disabled={!selectedSchema}
            >
              Load Functional Sets
            </Button>
          </div>
        </Form>

        {tableEntries.length > 0 && (
          <Table
            className="table"
            striped
            hover
            //   ref={(r) => (tableRef.current = r)}
          >
            <thead className=" p-3 mb-2 bg-white text-dark">
              <tr>
                <th>Field Name</th>
                <th>Max Length</th>
                <th>Data Type</th>
                <th>Utility Parameter</th>
                <th>Privacy Relevance</th>
              </tr>
            </thead>
            <tbody>{DisplayRows}</tbody>
          </Table>
        )}

        <Modal
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w"
          aria-labelledby="info-modal-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="info-modal-title">Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              This is a demo version to showcase the capability. To get your
              Database table data anonymized, please engage with{" "}
              <a href="mailto:info@mayadataprivacy.eu">
                info@mayadataprivacy.eu
              </a>{" "}
              to start the process. Meanwhile, you may download the file in{" "}
              <a
                href="https://mayaforbusiness.eu/wp-content/uploads/2022/06/MAYA_demo_sample.csv"
                download
              >
                link
              </a>{" "}
              and see one sample of how the data may look like after being
              privacy preserved using MAYA software.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-primary" onClick={() => setShow(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div style={style}>
        {tableEntries.length > 0 && (
          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
            <Button
              className="btn btn-primary me-md-2 my-1"
              type="button"
              onClick={onExecuteClick}
            >
              Execute
            </Button>{" "}
          </div>
        )}
      </div>
    </>
  );
}
