import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    debug: process.env.NODE_ENV === "development", // Disable debug in production
    fallbackLng: "eng",
    returnObjects: true,
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json", // Path to load translations
    },
    detection: {
      // Options for language detection
      order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag"],
      caches: ["localStorage", "cookie"],
    },
  });

export default i18n;
