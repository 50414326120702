import { FaChevronDown } from "react-icons/fa";
import "./Dashboard.scss";
import icon1 from "../../images/Dashboard/icons8.png";

const Compliance = () => {
  return (
    <div>
      <div className="dashboard-info-box">
        <div className="dashboard-info-header">
          <div className="dashboard-icon-title-container">
            <div className="dashboard-icon-background-1">
              <div className="dashboard-icon-background">
                <img src={icon1} alt="icon" className="dashboard-icon" />
              </div>
            </div>
            <div className="dashboard-info-title">
              Compliance Readiness Levels
            </div>
          </div>
          <div className="dashboard-date-dropdown dashboard-font-4">
            June 2024 <FaChevronDown />
          </div>
        </div>
        <div className="dashboard-compliance-levels">
          <div className="dashboard-up-level">
            <div className="dashboard-level"></div>
            <div className="dashboard-font-4">Target</div>
          </div>
          <div className="dashboard-up-level">
            <div className="dashboard-missed"></div>
            <div className="dashboard-font-4">Target Missed</div>
          </div>
          <div className="dashboard-up-level">
            <div className="dashboard-exceeded dashboard-missed"></div>
            <div className="dashboard-font-4">Target Met or Exceeded</div>
          </div>
          <div className="dashboard-up-level">
            <div className="dashboard-exceeded dashboard-missed"></div>
            <div className="dashboard-font-4">Target Met or </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Compliance;
