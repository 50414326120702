import React from "react";
import "./ExecutionDefinition.scss";
import { RxCross2 } from "react-icons/rx";

const ExecutionDefinition = ({
  executionSet,
  isPreview = false,
  onCrossClick,
}) => {
  console.log("executionSet", executionSet);
  return (
    <div className={"main_exe_def"}>
      <div className="header-def ">
        <div className="def-text font-5">Definitions Preview</div>
        {isPreview && (
          <div className="def-cross s" onClick={onCrossClick}>
            <RxCross2 />
          </div>
        )}
      </div>
      <div className="dis">
        <div className="div-pro">
          <div className="text-pro">
            <p className="config font-4">Execution Set</p>
          </div>
        </div>
        <div className="second-div">
          <div className="div-1">
            <div className="subhead font-4">Name</div>
            <div className="subdis font-4">{executionSet.executionSetName}</div>
          </div>
        </div>
        {executionSet.executionSetDetailsList.map((key, index) => {
          return renderExecutionSetDetails(
            executionSet.executionSetDetailsList[index]
          );
        })}
      </div>
    </div>
  );
};

const renderExecutionSetDetails = (executionSetDetail) => {
  const configSet = executionSetDetail.configSet;
  const utilitySet = executionSetDetail.utilitySet;
  return (
    <>
      <p className="step font-4"> Step {executionSetDetail.step}</p>
      {renderConfigSet(configSet)}
      {executionSetDetail.type.toLowerCase() === "UTILITY".toLowerCase() &&
      utilitySet ? (
        renderUtilitySet(utilitySet)
      ) : (
        <div className="div-1">
          <div className="subhead font-4">File</div>
          <div className="subdis font-4">{executionSetDetail.fileName}</div>
        </div>
      )}
    </>
  );
};

const renderConfigSet = (configSet) => {
  return configSet ? (
    <>
      <div className="div-1">
        <div className="subhead font-4">Configuration Set Name</div>
        <div className="subdis font-4">{configSet.configSetName}</div>
      </div>
      <div className="div-1">
        <div className="subhead font-4">Host Name</div>
        <div className="subdis font-4">{configSet.sourceConfig.config.databaseHost}</div>
      </div>
      <div className="div-1">
        <div className="subhead font-4">Username</div>
        <div className="subdis font-4">{configSet.createdBy}</div>
      </div>
    </>
  ) : (
    <div>No Config set selected</div>
  );
};

const renderUtilitySet = (utilitySet) => {
  if (!utilitySet) {
    return <div>No Utility set selected</div>;
  }

  const { tables, utilitySetName, noOfTables, noOfFields } = utilitySet;
  const tableCount = tables ? tables.length : noOfTables;
  const fieldCount = tables
    ? tables.reduce(
        (total, table) =>
          total + (table.anonymizeFields ? table.anonymizeFields.length : 0),
        0
      )
    : noOfFields;

  return (
    <>
      <div className="div-1">
        <div className="subhead font-4">Utility Set Name</div>
        <div className="subdis font-4">{utilitySetName}</div>
      </div>
      <div className="div-1">
        <div className="subhead font-4">Selected for Anonymization</div>
        <div className="subdis font-4">
          {tableCount} Tables (
          {tables ? tables.map((item) => item.tableName).join(", ") : ""}){" "}
          {fieldCount} Fields
        </div>
      </div>
    </>
  );
};

export default ExecutionDefinition;
