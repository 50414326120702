// import { useState } from 'react';
import Select from 'react-select';
import { selectDropdownStyle } from '../PII/PiiData';
import { get_sql_byPass, get_sql_queries, get_User_details } from '../../../hooks/apis/settings';
// const [sqlOptions, setSqlOptions] = useState([]);

import { components } from 'react-select';

const SQL_TYPE_OPTIONS = [
    { value: 'DENY', label: 'RESTRICT' },
    { value: 'ALLOW', label: 'BY_PASS' },
];
export const dropdownConfigs = (SQL_OPTIONS, USER_OPTIONS) => [
    {
        label: "Bypass restrictions on script in execution set *",
        options: USER_OPTIONS,
        placeholder: "Select users",
        isMulti: true,
        name: "users",
        required: true
    },
    {
        label: "Bypass restrictions for SQL type *",
        options: SQL_OPTIONS,
        placeholder: "Select SQL ",
        isMulti: true,
        name: "byPassSqls",
        required: true

    },
    {
        label: "Bypass type *",
        options: SQL_TYPE_OPTIONS,
        placeholder: "Select bypass type",
        isMulti: false,
        name: "action",
        required: true


    },
];
export const Dropdown = ({ label, options, placeholder, isMulti = false, onChange, name, required, value}) => (
    <div className="input-section w-75">
        <label className="level-text font-5">{label}</label>
        <Select
            name={name}
            options={options}
            onChange={onChange}
            className="select-dropdown"
            placeholder={placeholder}
            isMulti={isMulti}
            styles={selectDropdownStyle}
            components={{ Option: CustomOption }}
            required={required}
            value={value}
        />
    </div>
)
export const get_sql_By_Pass = async () => {
    try {
        const res = await get_sql_byPass();
        return res.data;
    } catch (err) {
        console.error("Error:", err.message);
        return err.message;
    }
}

export const get_user_name = async () => {
    try {
        const res = await get_User_details();
        return res.data;
    } catch (err) {
        console.error("Error:", err.message);
        return err.message;
    }
}

export const get_sql_queriess = async () => {
    try {
        const res = await get_sql_queries();
        const formattedQueries = res?.data.map(query => ({
            value: query,
            label: query,
        }));
        return formattedQueries

    } catch (err) {
        console.error("Error:", err.message);
        return err.message;
    }
}
const CustomOption = (props) => {
    const { data } = props; // `data` contains the option's `value` and `label`

    return (
        <components.Option {...props}>
            <div className="d-flex justify-content-between"><span >{data.label}</span>
                <span className='level-text cursor-pointer'
                    data-toggle="tooltip" data-placement="top"
                    title={data.value}
                >
                    ℹ️
                </span></div>

        </components.Option>
    );
};
