import "./Dashboard.scss";
import { FaChevronDown } from "react-icons/fa";
import icon2 from "../../images/Dashboard/icons2.svg";
import icon3 from "../../images/Dashboard/icons3.svg";

const Report = () => {
  return (
    <div>
      <div className="dashboard-info-box">
        <div className="dashboard-info-header">
          <div className="dashboard-icon-title-container">
            <div className="dashboard-icon-background-1">
              <div className="dashboard-icon-background">
                <img src={icon2} alt="icon" className="dashboard-icon" />
              </div>
            </div>
            <div className="dashboard-info-title">Reporting & Analytics</div>
          </div>
          <div className="dashboard-date-dropdown dashboard-font-4">
            June 2024 <FaChevronDown />
          </div>
        </div>
        <div className="dashboard-report-levels">
          <div className="dashboard-font-4 dashboard-report-box">
            <div className="dashboard-font-4 dashboard-up-level">
              <img src={icon3} alt="" />
              28
            </div>
            Jobs
          </div>
          <div className="dashboard-font-4 dashboard-report-box">
            <div className="dashboard-font-4 dashboard-up-level">
              <img src={icon3} alt="" />
              28
            </div>
            Errors
          </div>
          <div className="dashboard-font-4 dashboard-report-box">
            <div className="dashboard-font-4 dashboard-up-level">
              <img src={icon3} alt="" />
              28
            </div>
            Data Sets
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
