import {createContext, useContext} from "react";

export const AuthState = {
    loading : "Loading",
    Authenticated : "Authenticated",
    Unauthenticated : "Unauthenticated"
}

export const AppContext = createContext({
    authState: AuthState.loading,
    updateAuthState: (state) => {},
    isLoading: false,
    setLoading: (value) => {},
    showToast: (message) => {},
});


export default function useAppContext() {
    return useContext(AppContext);
}
