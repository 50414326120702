import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useAppContext, { AuthState } from "./useAppContext";
import AuthURL from "../model/auth_url.json"
import { serverAxios } from "../utils/AxiosUtils";
import { UserContext, useUserContext } from "../hooks/UserContext";
import { decodeJwt } from "../utils/Methods";
const { REACT_APP_FILE_SAFE_API_URL } = process.env;
axios.defaults.baseURL = REACT_APP_FILE_SAFE_API_URL;

export default function useAuth() {
    
    const { showToast, updateAuthState, setLoading } = useAppContext();
    let navigate = useNavigate();
    const [error, setError] = useState("");
    // const {setUser} = useContext(UserContext);
    // const setUserContext = (jwtToken) => {
    //     serverAxios.updateToken(jwtToken)
    //     updateAuthState(AuthState.Authenticated)
    //     navigate("/dashboard/fa");
    // };
    const setUserContext = async (jwtToken) => {
        try {
            await serverAxios.updateToken(jwtToken);
            updateAuthState(AuthState.Authenticated);
            // navigate("/dashboard/fa");
        } catch (error) {
            console.error("Error setting user context:", error);
        }
    };

    // const cookies = () => {
    //     return (
    //         <>
    //             <div className='backdropPopupStyle'>
    //                 <Popu popupTitle={"Deletion"}
    //                     popupMessage={popupText}
    //                     isConfirm={handleConfirm} />
    //             </div>
    //         </>
    //     )
    // }
    const updateProfile = async (data) => {
        const { email, userMobileNo, } = data;
        return serverAxios.authAxios.post(AuthURL.profile_update, data).then((res) => {
        }).catch((err) => {
            console.log("profile error:: ", err);
        }).finally(() => {

        })
    }


    const loginUser = async (data, fnSuccess) => {

        const {
            email,
            password,
            recaptchaToken,
            rememberMe,
            captchaValue,
            captchaToken,
            otp,
            encryptedUserName,
            encryptedPassword,
            otpToken,
            otpLogin
        } = data;
        let userLoginData = {}
        if (otpLogin) {
            userLoginData = {
                userName: encryptedUserName,
                tokenValue: otp,
                token: otpToken,
                password: encryptedPassword,
            }
        } else {
            if (!otpLogin) {
                userLoginData = {
                    userName: email,
                    password: password,
                    captchaValue: captchaValue,
                    captchaToken: captchaToken,
                }
            }
        }
        // console.log("this is for login test :: ", userLoginData)
        return serverAxios.authAxios
            // .post("/mdp/api/authenticate", {
            .post(AuthURL.login, userLoginData)
            .then((res) => {
                // showToast(res.data.message);
                const responseData = res.data.data;
                const withOTP = responseData.isOtpEnable;
                if (res.status === 200) {

                    let oUser = {
                        status: responseData.status,
                        isOtpEnable: responseData.data.isOtpEnable,
                        jwtToken: responseData.data.jwtToken,
                        password: responseData.data.password,
                        refreshToken: responseData.data.refreshToken,
                        tokenObj: responseData.data.tokenObj,
                        userName: responseData.data.userName,
                        rememberMe: rememberMe,
                        showCookies: true,
                    };
                    sessionStorage.setItem("info", JSON.stringify(oUser));
                    localStorage.setItem("info", JSON.stringify(oUser));
                    if (oUser.jwtToken !== null) {
                        setUserContext(oUser);
                        axios.defaults.headers.common["Authorization"] = oUser.jwtToken;


                    } else {
                    }
                    // // setError(null);
                } else {
                    fnSuccess();

                }
            })
            .catch((oError) => {
             showToast(oError.errorMessage, "fail")
                // if (oError.message === 'Invalid UserName Or Password!') {
                //     throw oError.response.data.errorMessage;
                // }
            })
            .finally(() => {
            });
    };


    const errorInLogin = () => {
        console.log("error==", error);
    }

    const registerUser = async (data) => {
        const { firstName, lastName, userEmailID, userMobileNo, password, organizationDetails, recaptchaToken } = data;
        // const mailNickname = emailProcessor(userEmailId);
        const fornData = {
            firstName: firstName,
            lastName: lastName,
            userEmailId: userEmailID,
            passCode: password,
            userMobileNo: userMobileNo,
            organizationDetails: organizationDetails,
            status: "ACTIVE",
        }
        // setLoading(true);
        return serverAxios.authAxios
            .post(AuthURL.register, fornData, {
                headers: {
                    'content-type': 'application/json'
                }
            }
            )
            .then((res) => {
                showToast("CONGRATULATIONS, YOU HAVE SUCCESSFULLY REGISTERED! Now You can able to login");
                navigate("/login");
                // setError(null);
            })
            .catch((oError) => {
                console.log("error in resgister:: ", oError)
                showToast(oError.message);
            })
            .finally(() => {

            });
    };

    const resetPasswordVerifyInfo = async (data, fnSuccess, fnError, setTimer) => {
        const { email, recaptchaToken, captchaValue } = data;

        setLoading(true);
        const formData = {
            captchaValue: captchaValue,
            email: email,
            token: recaptchaToken
        }
        return serverAxios.authAxios
            .post(AuthURL.reset_pass_verify_info, formData, {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then((res) => {
                setError(null);
                setTimer(res.data.data.expiredTimeInSecond)
                fnSuccess(res.data.data.key, res.data.data.smsToken);

            })
            .catch((oError) => {
                showToast(oError.message);
                console.log("Error response:: ", oError.response)
                setError(oError.response.data);
                fnError(oError.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const resetPasswordOTPVerify = async (data, fnSuccessOtp) => {
        const { otp, otpKey, smsToken } = data;

        setLoading(true);
        const formData = {
            key: otpKey,
            smsToken: smsToken,
            value: data.otp
        }
        return serverAxios.authAxios
            .post(AuthURL.reset_pass_verify_otp, formData, {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then((res) => {
                fnSuccessOtp(res.data.data.key, res.data.data.smsToken);

            })
            .catch((oError) => {
                console.log("Error response:: ", oError)
                showToast(oError.message);
            })
            .finally(() => {
                setLoading(false);
                if (error) console.error(error);
            });
    };
    const resetNewPassword = async (data, fnSuccessNewPassword) => {
        const { newPassword, otpKey, smsToken } = data;

        setLoading(true);
        const formData = {
            key: otpKey,
            smsToken: smsToken,
            value: newPassword
        }
        return serverAxios.authAxios
            .post(AuthURL.reset_new_pass, formData, {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then((res) => {
                showToast(res.data.data)
                fnSuccessNewPassword();


            })
            .catch((oError) => {
                console.log("Error response:: ", oError)
                showToast(oError.message);
            })
            .finally(() => {
                setLoading(false);
            });

    }
    const handleRefreshApi = async (token) => {
        try {
            const RefreshTokenDto = {
                token: token,
            };
            const res = await serverAxios.authAxios.post("refresh", RefreshTokenDto, {
                headers: { 'Content-Type': 'application/json' }
            });
            const responseData = res.data.data;

            if (res.status === 200) {
                const oUser = {
                    status: responseData.status,
                    isOtpEnable: responseData.isOtpEnable,
                    jwtToken: responseData.jwtToken,
                    password: responseData.password,
                    refreshToken: responseData.refreshToken,
                    tokenObj: responseData.tokenObj,
                    userName: responseData.userName,
                };

                sessionStorage.setItem("info", JSON.stringify(oUser));
                localStorage.setItem("info", JSON.stringify(oUser));

                if (responseData?.jwtToken) {
                    await serverAxios.updateToken(responseData.jwtToken);
                    updateAuthState(AuthState.Authenticated);
                    axios.defaults.headers.common["Authorization"] = `Bearer ${oUser.jwtToken}`;
                } else {
                    console.error('JWT token is null');
                }
            } else {
                console.error('Failed to refresh token, status:', res.status);
            }
        } catch (err) {
            console.error('Error refreshing token:', err);
        }
    };

    const refreshBeforeExp = async (token) => {
        try {
            const RefreshTokenDto = {
                token: token,
            };
            const res = await serverAxios.authAxios.post("modify-token", RefreshTokenDto, {
                headers: { 'Content-Type': 'application/json' }
            });
            const responseData = res.data.data;

            if (res.status === 200) {
                const oUser = {
                    status: responseData.status,
                    isOtpEnable: responseData.isOtpEnable,
                    jwtToken: responseData.jwtToken,
                    password: responseData.password,
                    refreshToken: responseData.refreshToken,
                    tokenObj: responseData.tokenObj,
                    userName: responseData.userName,
                };

                sessionStorage.setItem("info", JSON.stringify(oUser));
                localStorage.setItem("info", JSON.stringify(oUser));

                if (oUser.jwtToken) {
                    await serverAxios.updateToken(responseData.jwtToken);
                    updateAuthState(AuthState.Authenticated);
                    axios.defaults.headers.common["Authorization"] = `Bearer ${oUser.jwtToken}`;
                } else {
                    console.error('JWT token is null');
                }
            } else {
                console.error('Failed to refresh token, status:', res.status);
            }
        } catch (err) {
            console.error('Error refreshing token:', err);
        }
    };

    const handleLogOutApi = async (refreshToken, jwtToken) => {
        try {
            const logOutDto = { refreshToken };
            updateAuthState(AuthState.Unauthenticated);
            sessionStorage.removeItem("info");
            localStorage.removeItem("info");
            await serverAxios.authAxios.post("sign-out", logOutDto, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`,
                }
            });

            // fnSuccess();
        } catch (error) {
            console.error("Error logging out:", error);
        }
    };


    const emailProcessor = (email) => {
        return email.replaceAll("@", "").replaceAll(".", "");
    };
    
    return {
        emailProcessor,
        loginUser,
        registerUser,
        resetPasswordVerifyInfo,
        errorInLogin,
        setUserContext,
        resetPasswordOTPVerify,
        resetNewPassword,
        handleRefreshApi,
        handleLogOutApi,
        refreshBeforeExp
    };
}
