import React, {
  useEffect,
  useMemo,
  useState,
  useContext,
  useInsertionEffect,
} from "react";
import { Button } from "react-bootstrap";
import "./Table.css";
import axios from "axios";
import { UserContext } from "../../hooks/UserContext";
import DataTable from "react-data-table-component";
import { BiUserX, BiCoinStack, BiUserCheck } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";

const { REACT_APP_SITE_KEY, REACT_APP_SITE_TITLE } = process.env;
const Datatable = () => {
  const [userData, setUserData] = useState([]);
  const [showToast, setShowToast] = useState(false);

  const { user, setUser, setLoading } = useContext(UserContext);
  const [toastMessage, setToastMessage] = useState("");
  const [handle, setHandle] = useState(false);
  const [status, setStatus] = useState(false);
  const [search, setSearch] = useState("");
  const [filterdata, setFilterData] = useState([]);

  const hideToast = () => {
    setShowToast(false);
  };

  const getUdata = async () => {
    setLoading(true);
    axios
      .get("https://apiprod.mayadataprivacy.eu/mdp/api/user/org")
      .then((res) => {
        setUserData(res.data);
        setFilterData(res.data);
        // console.log(res.data);
      })
      .catch((oError) => {
        console.error(`Reading User Jobs ::::: ${oError}`);
        setToastMessage("Failed to read Jobs data.");
        setShowToast(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getUdata();
  }, []);

  userData.forEach((userData, index) => {
    userData.serial = index + 1;
  });

  const columns = [
    {
      name: "id",
      selector: (row) => row.serial,
    },
    {
      name: "Email",
      selector: (row) => row.emialId,
      cell: (row) => (
        <>
          <div className={`rowtext ${status === true ? "disabled" : ""}`}>
            {row.emialId}
          </div>
        </>
      ),
    },
    {
      name: "First Name",
      selector: (row) => row.firstName,
      cell: (row) => (
        <>
          <div className={` rowtext ${status === true ? "disabled" : ""}`}>
            {row.firstName}
          </div>
        </>
      ),
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
      cell: (row) => (
        <>
          <div className={` rowtext ${status === true ? "disabled" : ""}`}>
            {row.lastName}
          </div>
        </>
      ),
    },
    {
      name: "Role",
      selector: (row) => row.role,
      cell: (row) => (
        <>
          <div
            className={`${row.role === "User" ? "roleuser" : "superuser"}  ${
              status === true ? "disabled" : ""
            }`}
          >
            {row.role}
          </div>
        </>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => (
        <>
          <div
            className={`${
              { status } === "Active" ? "activedata" : "unactive"
            }  `}
          >
            {status ? "Inactive" : "Active"}
          </div>
        </>
      ),
    },
    {
      name: "Coin",
      selector: (row) => row.coins,
      cell: (row) => (
        <>
          <div className={` rowtext ${status === true ? "disabled" : ""}`}>
            {row.coins}
          </div>
        </>
      ),
    },
    {
      name: "Action",
      selector: (row) => row.id,
      cell: (roww) => (
        <>
          {" "}
          <Button
            className=" rowtext transparent"
            value={roww.id}
            onClick={() => handledisable(roww.serial)}
          >
            {handle ? <BiUserCheck /> : <BiUserX />}
          </Button>{" "}
          <Button
            className={` rowtext transparent ${
              status === "Inactive" ? "disabled-btn" : "transparent"
            }`}
          >
            <BiCoinStack />
          </Button>
        </>
      ),
    },
  ];
  function handledisable(id) {
    // console.log(id);
    userData.setHandle(!handle);
    setStatus(!status);
  }

  useEffect(() => {
    const result = userData.filter((udata) => {
      return (
        udata.firstName.toLowerCase().match(search.toLowerCase()) ||
        udata.lastName.toLowerCase().match(search.toLowerCase()) ||
        udata.emialId.toLowerCase().match(search.toLowerCase())
      );
    });
    setFilterData(result);
  }, [search]);

  return (
    <div className="edittable">
      {/* _______________Table Header____________ */}
      <div className="row">
        <div className="col">
          <h4>Manage Users</h4>
        </div>
        <div className="col ">
          <div className="container">
            <div className="search-container">
              <form className="searchform" action="/action_page.php">
                <div className="sub">
                  <FaSearch />

                  <input
                    className="sub1 no-outline p-1"
                    type="text"
                    placeholder="Search"
                    name="search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* ____________Table data_____________ */}
      <DataTable
        className="tabledata table-responsive responsive table table-striped table-bordered"
        //   title="Manage Users"
        columns={columns}
        data={filterdata.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        )}
        minRows={5}
        refresh={true}
        refreshHandler={getUdata}
        pagination
        fixedHeader
        fixedHeaderScrollHeight="400px"
        highlightOnHover
        // paginationDefaultPage={4}
        defaultPageSize={5}
        // searching={false}
        // subHeader
        // subHeaderComponent={
        //     <input className='sub1 no-outline p-1' type="text" placeholder="Search" name="search" value={search}  onChange={(e)=>setSearch(e.target.value)} />

        // }
      />
    </div>
  );
};

export default Datatable;
