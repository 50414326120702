
import * as CONSTANTS from "../../../model/Constants";
export const formatFileSize = (number) => {
    if (number < 1024) {
      return `${number} bytes`;
    } else if (number >= 1024 && number < 1048576) {
      return `${(number / 1024).toFixed(1)} KB`;
    } else if (number >= 1048576) {
      return `${(number / 1048576).toFixed(1)} MB`;
    }
  };
export const DateRegexExp =
  /(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})/gi;

export const dataTypeMap = {
  Text: 1,
  Date: 2,
  Number: 3,
};
export const utilitySetMap = {
  "No Change": "NO_CHANGE",
  Names: "NAME",
  Emails: "EMAIL",
  Phone: "PHONE",
  "Consistent Text": "CONSISTENT_ID",
  "Consistent ID": "CONSISTENT_ID",
  Random: "NO_CHANGE",
  "Date Same Year": "DATE",
  "Date Adult": "DATE",
  "Consistent Date": "NO_CHANGE",
  "Clear values": "CLEAR",
  "Differential Privacy": "DIFFERENTIAL_PRIVACY",
  Randomization: "RANDOMIZATION",
  "Consistent Number": "NO_CHANGE",
  Number: "NUMBER",
};
export const utilityMap = {
  "No Change": 0,
  Names: 1,
  Emails: 2,
  Phone: 3,
  "Consistent Text": 4,
  "Clear values": 5,
  Random: 6,
  "Date Same Year": 7,
  "Date Adult": 8,
  "Consistent Date": 9,
  "Differential Privacy": 10,
  Randomization: 11,
  "Consistent Number": 12,
};
export const availablePrivacyRelevance = [
  { value: "NOT_RELEVANT", label: "Not Relevant" },
  { value: "CONFIDENTIAL", label: "Confidential" },
  { value: "PERSONAL", label: "Personal" },
];
export const selectDropdownStyle = {
  width: "100%",
  fontSize: "16px",
  lineHeight: "24px",
  color: "var(--gray-500, #667085)",
  display: "flex",
  padding: "8px 12px",
  gap: "8px",
  borderRadius: "8px",
  border: "1px solid var(--gray-300, #D0D5DD)",
  background: "var(--White, #FFF)",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
};
export const privacyRelevanceMap = {
  "Not Relevant": "NOT_RELEVANT",
  Confidential: "CONFIDENTIAL",
  Personal: "PERSONAL",
};
export const validFileType = (file) => {
  return CONSTANTS.SUPPORTED_FILE_TYPES.includes(file.type);
};
export function formatLabel(value) {
  return value
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
}