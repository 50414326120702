import { serverAxios } from "../utils/AxiosUtils"

const URLS = {
    tableView: "utility-set/table/view"
}

export const getTableFirst10Row = async ({ configsetid, utilitysetid, tableid }) => {
    const dataForSend = {
        configSetId: configsetid,
        utilitySetId: utilitysetid,
        tableId: tableid
    }
    const response = await serverAxios.appSafeAxios.get(URLS.tableView, {
        params: dataForSend
    }).then(res => res.data);
    if (response.success) {
        return response.data || []
    }
    throw new Error(response.message);
}