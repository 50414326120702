import React, { useState } from 'react';
import { TbReplaceFilled } from "react-icons/tb";
import { RxCross2 } from "react-icons/rx";
const PromptHighlighter = ({ piiDetectionList, prompt, updatedPrompt, setPii }) => {
    // const initialText = 'My email is xyz@gmail.com, and my phone number is 098765433. My name is Anjali.';
    // const initialPiiDetection = {
    //     Names: ['Anjali'],
    //     Emails: ['xyz@gmail.com'],
    //     Phones: ['098765433'],
    // };
    const initialText = prompt
    const initialPiiDetection = piiDetectionList
    const [updatedPii, setUpdatedPii] = useState(initialPiiDetection)
    const [piiDetection, setPiiDetection] = useState(initialPiiDetection);
    const [editableText, setEditableText] = useState(initialText);
    const initializeClearValue = (piiDetection) => {
        return {
            Names: Array(piiDetection.Names?.length || 0).fill(false),
            Emails: Array(piiDetection.Emails?.length || 0).fill(false),
            Phones: Array(piiDetection.Phones?.length || 0).fill(false),
        };
    };
    const [clearValue, setClearValue] = useState(() => initializeClearValue(initialPiiDetection));

    const escapeRegExp = (string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    };

    const handlePiiChange = (e, piiType, index, clear) => {
        let updatedValue = "";
        if (clear === false) {
            updatedValue = e.target.textContent.trim();
        }
        const updatedPiiData = {
            ...updatedPii,
            [piiType]: updatedPii[piiType]?.map((value, i) => (i === index ? updatedValue : value)) || [],
        };
        setUpdatedPii(updatedPiiData);
    };




    const handleReplaceAllPii = () => {
        let newText = initialText;
        const replacements = new Set(); // Track already replaced strings

        // Iterate through each PII type (Names, Emails, Phones)
        Object.keys(updatedPii)?.forEach((piiKey) => {
            if (updatedPii[piiKey] && initialPiiDetection[piiKey]) {
                updatedPii[piiKey].forEach((piiValue, i) => {
                    const originalValue = initialPiiDetection[piiKey]?.[i]?.trim();
                    if (originalValue && !replacements.has(originalValue)) {
                        const escapedOriginal = escapeRegExp(originalValue);
                        const regex = new RegExp(`\\b${escapedOriginal}\\b`, 'gi');
                        newText = newText.replace(regex, piiValue);
                        replacements.add(originalValue);
                    }
                });
            }
        });
        updatedPrompt(newText);
        setPii({})
    };

    const handleClearValue = (piiType, index) => {
        setClearValue((prevClearValue) => {
            const updatedPiiTypeArray = [...prevClearValue[piiType]];
            updatedPiiTypeArray[index] = true;
            return {
                ...prevClearValue,
                [piiType]: updatedPiiTypeArray
            };
        });
    };

    const highlightText = () => {
        let parts = [editableText];

        const processHighlight = (regex, highlightValue, piiType, index) => {
            parts = parts.flatMap((part) => {
                if (typeof part !== 'string') return [part];

                const splitText = part.split(regex);
                return splitText.flatMap((split, i) => {
                    if (i < splitText.length - 1) {
                        return [
                            split, // non-highlighted text
                            <div className="d-inline-flex align-items-center gap-2" style={{
                                backgroundColor: '#101828',
                                color: '#BDA6F2',
                                padding: '0 5px',
                                borderRadius: '12px',
                                caretColor: 'auto',
                                fontSize: '12px'
                            }}>
                                <span
                                    className='font-4 no-outline  border-0'
                                    key={`${highlightValue}-${i}`}
                                    contentEditable={true}
                                    suppressContentEditableWarning={true}
                                    onInput={(e) => handlePiiChange(e, piiType, index, false)}
                                    style={{
                                        padding: '0.5px 8px',
                                        caretColor: 'auto',

                                    }}
                                >
                                    {clearValue[piiType]?.[index]?"":highlightValue} 
                                </span> <button onClick={(e) => {handlePiiChange(e, piiType, index, true)
                                    handleClearValue(piiType,index)
                                }} className="transparent no-outline  p-0 border-0" style={{ color: '#BDA6F2', }}><RxCross2 size={'11px'} /></button></div>,
                        ];
                    }
                    return [split]; // last non-highlighted text
                });
            });
        };

        // Process highlighting for Names, Emails, Phones
        if (initialPiiDetection) {
            if (initialPiiDetection.Names) {
                piiDetection?.Names?.forEach((name, index) => {
                    const regex = new RegExp(escapeRegExp(name), 'gi');
                    processHighlight(regex, name, 'Names', index);
                });
            }
            if (initialPiiDetection.Emails) {
                piiDetection?.Emails?.forEach((email, index) => {
                    const regex = new RegExp(escapeRegExp(email), 'gi');
                    processHighlight(regex, email, 'Emails', index);
                });
            }
            if (initialPiiDetection.Phones) {
                piiDetection?.Phones?.forEach((phone, index) => {
                    const regex = new RegExp(escapeRegExp(phone), 'gi');
                    processHighlight(regex, phone, 'Phones', index);
                });
            }
        }

        return parts;
    };



    const highlightedParts = highlightText();

    return (
        <div style={{ position: 'relative' }}>
            <label className="font-4 " style={{ color: "#BDA6F2", marginLeft: "20px" }}>Prompt anoymised, please verify before sending to the AI model. </label>
            <div className="textarea_container w-100 highlight-Prompt" style={{ position: 'relative' }}>
                <div className=" input_ai_safe  " style={{ marginLeft: 0, width: '90%' }}>

                    <div
                        contentEditable={false}
                        suppressContentEditableWarning={true}
                        style={{
                            whiteSpace: 'pre-wrap',
                            overflowY: 'auto',
                            maxHeight: '200px',
                            // padding: '10px',
                            borderRadius: '4px',
                            backgroundColor: 'transparent',
                        }}
                    >
                        {highlightedParts.map((part, index) =>
                            typeof part === 'string' ? (
                                <span key={index}>{part}</span>
                            ) : (
                                part
                            )
                        )}
                    </div>
                </div>
                <button onClick={handleReplaceAllPii} className='btn-low-purple replaceButton' style={{
                    position: 'absolute',
                    right: '5px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    backgroundColor: '#101828',
                    color: '#BDA6F2'
                }}><TbReplaceFilled /></button>
            </div>
        </div>
    );
};

export default PromptHighlighter;
