import CryptoJS from 'crypto-js';
import { serverAxios } from "../utils/AxiosUtils";
import JSEncrypt from 'jsencrypt';

import forge from 'node-forge';
const fetchPublicKey = async () => {
    try {
        const response = await serverAxios.appSafeAxios.get('public-key');
        return response.data.data;
    } catch (error) {
        console.error('Error fetching public key:', error);
        return null;
    }
};
const encryptFile = async (file) => {
    try {
        const backendPublicKey = await fetchPublicKey()
        if (!file || !backendPublicKey) return;

        const scriptFile = await readFileAsText(file);
        const symmetricKey = await generateSymmetricKey()
        const encryptedFile = await CryptoJS.AES.encrypt(scriptFile, symmetricKey).toString();
        const encryptedKey = await encryptWithRSA(symmetricKey, backendPublicKey).toString();
        return { encryptedFile, encryptedKey };

    } catch (error) {
        console.error('Error encrypting file:', error);
        return null;
    }
};
export const readFileAsText = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsText(file);
    });
};

const encryptWithRSA = (data, publicKey) => {
    const encryptor = new JSEncrypt();
    encryptor.setPublicKey(publicKey);
    return encryptor.encrypt(data);
};
const generateSymmetricKey = async () => {
    const generatedKey = CryptoJS.lib.WordArray.random(256 / 8);
    const symmetricKey = generatedKey.toString(CryptoJS.enc.Base64);
    return symmetricKey
}
const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
};
export {
    encryptFile,
}
