import React from "react";
import "./Popup.scss";

const ThemePopup = ({ isOpen, onClose, onThemeChange, currentTheme }) => {
  if (!isOpen) return null;

  return (
    <div className="theme-popup-overlay" onClick={onClose}>
      <div className="theme-popup" onClick={(e) => e.stopPropagation()}>
        <h3>Choose Theme</h3>
        <div>
          <button
            className={`theme-button ${
              currentTheme === "light" ? "active" : ""
            }`}
            onClick={() => onThemeChange("light")}
          >
            Light Mode
          </button>
          <button
            className={`theme-button ${
              currentTheme === "dark" ? "active" : ""
            }`}
            onClick={() => onThemeChange("dark")}
          >
            Dark Mode
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThemePopup;
