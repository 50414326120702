
export const databaseValidation = (source, setFormDataForDataBase, isSource) => {
    const { databaseType, config } = source
    const {
        databaseHost,
        databasePort,
        authType,
        databaseUser,
        databasePassword,
        databaseName,
        schemaName,
        backupSchema,
        databaseMode,
        hanaDbType,
    } = config

    if (databaseHost === '' || databasePort === '') return false
    if (authType === 'PASSWORD') {
        if (databaseUser === '' || databasePassword === '') return false
        if (databaseType === 'HANA' || databaseType === 'ORACLE') {
            if (databaseUser === '') {
                if (schemaName === '') return false
            }

        }
    }
    if (databaseType === 'HANA') {
        if (databaseMode === 'MULTIPLE_CONTAINER' && hanaDbType === 'TENANT') {
            if (databaseName === '') return false
        }
    }



}
export const handleDto = async (database, SharePoint, formdata) => {
    console.log("database", database);
    console.log("sharepoint", SharePoint);

    // Helper function for HANA database configuration
    const getHanaConfig = (sourceOrTarget) => {
        const config = sourceOrTarget.config;
        const mode = config.databaseMode || 'SINGLE_CONTAINER';
        const authType = config.authType;
        const schemaName = (mode === 'SINGLE_CONTAINER')
            ? (authType === 'NONE' ? "" : config.databaseUser || config.schemaName)
            : config.schemaName;

        return {
            databaseType: sourceOrTarget.databaseType || 'HANA',
            config: {
                databaseHost: config.databaseHost || 'default_host',
                databasePort: config.databasePort || 30015,
                authType: authType,
                databaseUser: authType === 'PASSWORD' ? config.databaseUser : null,
                databasePassword: authType === 'PASSWORD' ? config.databasePassword : null,
                databaseName: config.databaseName,
                databaseMode: mode,
                hanaDbType: mode === 'MULTIPLE_CONTAINER' ? 'TENANT' : 'SINGLE',
                schemaName: schemaName,
                backupSchema: config.backupSchema || schemaName || (authType === 'NONE' ? "" : config.databaseUser),
            }
        };
    };

    // Helper function for POSTGRES database configuration
    const getPostgresConfig = (sourceOrTarget) => {
        const config = sourceOrTarget.config;
        return {
            databaseType: sourceOrTarget.databaseType || 'POSTGRES',
            config: {
                databaseHost: config.databaseHost,
                databasePort: config.databasePort === "" ? '5432' : config.databasePort,
                authType: config.authType,
                databaseUser: config.databaseUser,
                databasePassword: config.databasePassword,
                databaseName: config.databaseName,
                schemaName: config.schemaName || "public",
                backupSchema: config.backupSchema || config.schemaName || "public",
            }
        };
    };

    // Choose source and target configurations based on database type
    const databse_sourceConfig = database.sourceConfig.databaseType === 'HANA'
        ? getHanaConfig(database.sourceConfig)
        : getPostgresConfig(database.sourceConfig);

    const databse_targetConfig = database.targetConfig
        ? (database.targetConfig.databaseType === 'HANA'
            ? getHanaConfig(database.targetConfig)
            : getPostgresConfig(database.targetConfig))
        : null;

    // Main DTO Configuration
    const databaseDTO = {
        sameConfig: database.sameConfig,
        sourceConfig: databse_sourceConfig,
        ...(database.sameConfig ? { targetConfig: null } : { targetConfig: databse_targetConfig })
    };

    // Final DTO with condition for configSetType
    const dto = {
        ...formdata,
        ...(formdata.configSetType === 'FTP' ? { ...SharePoint } : { ...databaseDTO })
    };

    return dto;
};

export const validateFormData = (dto) => {
    console.log(dto)
    const isFilled = (value) => value !== null && value !== undefined && value !== '';

    // Check initial form data
    if (!isFilled(dto.configSetName) || !isFilled(dto.configSetType)) {
        console.log("1")
        return false;
    }

    // Check SharePoint data if configSetType is 'FTP'
    if (dto.configSetType === 'FTP') {
        const { sameConfig, sourceConfig, targetConfig } = dto;
        console.log("2")
        // Validate source config
        if (!isFilled(sourceConfig.fileSystemType) ||
            !isFilled(sourceConfig.config.siteName) ||
            !isFilled(sourceConfig.config.directoryPath) ||
            !isFilled(sourceConfig.config.clientId) ||
            !isFilled(sourceConfig.config.tenantId) ||
            !isFilled(sourceConfig.config.clientSecret)) {
            console.log("3")

            return false;
        }
        if (!sameConfig) {
            if (!isFilled(targetConfig.fileSystemType) ||
                !isFilled(targetConfig.config.siteName) ||
                !isFilled(targetConfig.config.directoryPath) ||
                !isFilled(targetConfig.config.clientId) ||
                !isFilled(targetConfig.config.tenantId) ||
                !isFilled(targetConfig.config.clientSecret)) {
                console.log("4")

                return false;
            }
        }

    } else {
        const { sameConfig, sourceConfig, targetConfig } = dto;
        if (!isFilled(sourceConfig.databaseType) ||
            !isFilled(sourceConfig.config.databaseHost) ||
            !isFilled(sourceConfig.config.databasePort) ||
            !isFilled(sourceConfig.config.authType) ||
            (sourceConfig.config.authType === 'PASSWORD' && !isFilled(sourceConfig.config.databaseUser) ||
                !isFilled(sourceConfig.config.databasePassword) )||

            // !isFilled(sourceConfig.config.databaseName) ||
            (sourceConfig.databaseType==='HANA'&&sourceConfig.config.authType !== 'PASSWORD'&&!isFilled(sourceConfig.config.schemaName) )||
            // !isFilled(sourceConfig.config.backupSchema) ||
            (sourceConfig.databaseType==='HANA' && !isFilled(sourceConfig.config.databaseMode)) ||
            (sourceConfig.databaseType === 'HANA' &&sourceConfig.config.databaseMode ==='MULTIPLE_CONTAINER'&&!isFilled(sourceConfig.config.hanaDbType))
        ) {
            console.log("5")

            return false;
        }
        if (!sameConfig) {
            if (!isFilled(targetConfig.databaseType) ||
                !isFilled(targetConfig.config.databaseHost) ||
                !isFilled(targetConfig.config.databasePort) ||
                !isFilled(targetConfig.config.authType) ||
                !isFilled(targetConfig.config.databaseUser) ||
                !isFilled(targetConfig.config.databasePassword) ||
                // !isFilled(targetConfig.config.databaseName) ||
                // !isFilled(targetConfig.config.schemaName) ||
                // !isFilled(targetConfig.config.backupSchema) ||
                !isFilled(targetConfig.config.databaseMode) ||
                !isFilled(targetConfig.config.hanaDbType)) {
                console.log("6")

                return false;
            }
        }
    }

    // If all checks pass, return true
    return true;
};

