import React from 'react'
import { Button } from 'react-bootstrap'

const Filesafe_Footer = ({ isLoading, createGroup, onDeriveDataTypes, executeJob }) => {
  return (
    <>
          <div className="footer">
              <div className="footer-buttons">
                  <div className="leftSideButtonList"></div>
                  <div className="rightSideButtonList">
                      <Button
                          className="btn btn-primary me-md-2 my-1 execute"
                          type="button"
                          onClick={createGroup}
                          disabled={isLoading}
                      >
                          Create Group
                      </Button>{" "}
                      <Button
                          className="btn btn-primary me-md-2 my-1 execute"
                          type="button"
                          onClick={onDeriveDataTypes}
                      //disabled={isLoading}
                      >
                          Derive PII Types
                      </Button>{" "}
                      <Button
                          className="btn btn-primary me-md-2 my-1 execute"
                          type="button"
                          onClick={executeJob}
                          disabled={isLoading}
                      >
                          Execute
                      </Button>{" "}
                  </div>
              </div>
          </div>
    </>
  )
}

export default Filesafe_Footer
