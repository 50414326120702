export const newTabForFailure = (item) => {
    return (
        <p
            className={`font-4 w-100  ${item?.failureReason &&'text-primary cursor-pointer'}`}
            style={{ height: '30px' }}
            disabled={!item?.failureReason && !item?.failureMessage}
            onClick={() => {
                if (item?.failureReason || item?.failureMessage) {
                    const newWindow = window.open();

                    if (newWindow) {
                        newWindow.document.write(`
                            <html>
                                <head>
                                    <title>Failure Details</title>
                                    <link rel="icon" href="../../../../public/lago_maya.ico" />
                                    <meta name="description" content="MAYA Data Privacy | Safe personal copies" />
                                    <style>
                                        body {
                                            font-family: Arial, sans-serif;
                                            padding: 20px;
                                        }
                                        .table-name {
                                            font-weight: bold;
                                            font-size: 1.5em;
                                        }
                                        .failure-message, .failure-reason {
                                            margin-top: 20px;
                                            font-size: 1.2em;
                                        }
                                    </style>
                                </head>
                                <body>
                                    <pre><span class="table-name">${item?.tableName || 'Unknown Table'}</span><span class="failure-message">: ${item?.failureMessage || 'No failure message'}</span></pre>
                                    <pre class="failure-reason">Failure Reason: ${item?.failureReason || 'No failure reason'}</pre>
                                </body>
                            </html>
                        `);
                        newWindow.document.close();
                    } else {
                        console.error('Popup blocked or failed to open.');
                    }
                }
            }}
        >
            {item?.status}
        </p>
    );
}