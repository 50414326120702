import create from "zustand";

const useSidebarStore = create((set) => ({
  isCollapsed: localStorage.getItem("isCollapsed") === "true",
  selectedItem: "dashboard",
  firstName: "",
  email: "",
  toggleSidebar: () =>
    set((state) => {
      const newCollapsedState = !state.isCollapsed;
      localStorage.setItem("isCollapsed", newCollapsedState);
      return { isCollapsed: newCollapsedState };
    }),
  setIsCollapsed: (collapsed) => {
    localStorage.setItem("isCollapsed", collapsed);
    set({ isCollapsed: collapsed });
  },
  setSelectedItem: (item) => set({ selectedItem: item }),
  setFirstName: (name) => set({ firstName: name }),
  setEmail: (email) => set({ email: email }),
}));

export default useSidebarStore;
