import { serverAxios } from "../../utils/AxiosUtils";
export async function anonimise(prompt, choices, model) {
  try {
    // Log the outgoing request data
    console.log("Sending POST request to /detect with data:", {
      prompt,
      choices,
      model,
    });

    // Send the POST request to the /detect endpoint
    const response = await serverAxios.aiSafeAxios.post("/anonymize", {
      prompt,
      choices,
      model,
    });

    // Log the received response for debugging
    console.log("Received response:", response.data);

    // Check if the response was successful
    if (response.status !== 200 || !response.data.success) {
      throw new Error(response.data.message || "Anonymisation failed");
    }

    // Ensure the data field is present in the response
    const { data } = response.data;
    if (!data) {
      throw new Error("Anonymisation response is missing 'data' field");
    }

    // Return the data field from the response
    return data;
  } catch (error) {
    // Log the error for better debugging
    console.error("Error in anonymisation request:", error);

    // Check if the error response contains a message
    const errorMessage =
      error.response?.data?.message || "Anonymisation request failed.";

    // Rethrow the error with a user-friendly message
    throw new Error(errorMessage);
  }
}

export async function upload(file) {
  try {
    console.log("Sending POST request to /upload with file:", file);

    // Create a FormData object to hold the file
    const formData = new FormData();
    formData.append("file", file);

    // Send the file to the backend
    const response = await serverAxios.aiSafeAxios.post("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log("Received response:", response.data);

    if (!response.data.success) {
      throw new Error(response.data.message || "File upload failed");
    }

    // Ensure the data field is valid
    if (!response.data.data) {
      throw new Error("Response data is missing");
    }

    return response.data.data;
  } catch (error) {
    // Enhanced error handling and logging
    console.error("Error in file upload request:", error);

    if (error.response) {
      // Server responded with a status code outside the 2xx range
      console.error("Response status:", error.response.status);
      console.error("Response data:", error.response.data);

      if (error.response.data && error.response.data.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(
          `File upload failed with status ${error.response.status}`
        );
      }
    } else if (error.request) {
      // No response received from the server
      console.error("No response received:", error.request);
      throw new Error(
        "No response received from the server. Please try again later."
      );
    } else {
      // Error setting up the request
      console.error("Error setting up the request:", error.message);
      throw new Error(
        "An error occurred while setting up the file upload request."
      );
    }
  }
}

export async function chat(prompt, choices, new_chat, doc, model, anonymized) {
  try {
    // console.log("Sending POST request to /chat with data:", {
    //   prompt,
    //   choices,
    //   new_chat,
    //   doc,
    // });

    const response = await serverAxios.aiSafeAxios.post("/chat", {
      prompt,
      choices,
      new_chat,
      doc,
      model,
      anonymized,
    });

    // console.log("Received response:", response.data);

    if (!response.data.success) {
      throw new Error(response.data.message || "Chat request failed");
    }

    // Ensure the data field is valid
    if (!response.data.data) {
      throw new Error("Response data is missing");
    }

    return response.data;
  } catch (error) {
    // Enhanced error handling and logging
    console.error("Error in chat request:", error);

    if (error.response) {
      // Server responded with a status code outside the 2xx range
      console.error("Response status:", error.response.status);
      console.error("Response data:", error.response.data);

      if (error.response.data && error.response.data.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(
          `Chat request failed with status ${error.response.status}`
        );
      }
    } else if (error.request) {
      // No response received from the server
      console.error("No response received:", error.request);
      throw new Error(
        "No response received from the server. Please try again later."
      );
    } else {
      // Error setting up the request
      console.error("Error setting up the request:", error.message);
      throw new Error("An error occurred while setting up the chat request.");
    }
  }
}

export const history = async () => {
  try {
    console.log("Sending GET request to /history");
    const response = await serverAxios.aiSafeAxios.get("/history");
    console.log("Received response:", response.data);

    if (!response.data?.success) {
      throw new Error(response.data?.message ?? "History request failed");
    }

    // Ensure the data field is valid
    const historyData = response.data;
    if (!historyData) {
      throw new Error("Response data is missing");
    }

    return response;
  } catch (error) {
    console.error("Error in history request:", error);
    throw new Error(error.response?.data?.message ?? "History request failed.");
  }
};

export const fetchChatHistory = async (chatId) => {
  try {
    console.log(`Sending GET request to /history/${chatId}`);
    const response = await serverAxios.aiSafeAxios.get(`/history/${chatId}`);
    if (!response.data?.success) {
      throw new Error(response.data?.message ?? "History request failed");
    }

    // Ensure the data field is valid
    const historyData = response.data.data;
    if (!historyData || !Array.isArray(historyData) ) {
      console.error("Response data is missing or invalid")
      throw new Error("Internal server error! Please contact admin.");
    }
    if (historyData.length < 2){
      console.error("History data is too short")
      throw new Error("No chat history available");
    }

    // Return only the chat messages, excluding the first item (ID)
    // console.log("Returning chat history:", historyData);
    return historyData;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message || "History request failed.");
  }
};

export const fetchCollaborationGroups = async () => {
  try {
    // console.log("Sending GET request to /mdp/maya-utils/project/all-projects");
    const response = await serverAxios.aiSafeAxios.get(
      "/maya-utils/project/all-projects"
      // console.log("response", response)
    );

    const result = await response.json();
    console.log("result", result);

    if (result.success) {
      return result.data;
    } else {
      console.error("Failed to fetch collaboration groups:", result.message);
      throw new Error(result.message);
    }
  } catch (error) {
    console.error("Error fetching collaboration groups:", error);
    throw new Error("Error fetching collaboration groups");
  }
};

export const deleteHistoryItem = async (itemId) => {
  try {
    console.log(`Sending DELETE request to /delete-session/${itemId}`);
    const response = await serverAxios.aiSafeAxios.delete(
      `/delete-session/${itemId}`
    );
    console.log("Received response:", response.data);

    if (!response.data?.success) {
      throw new Error(response.data?.message ?? "Delete request failed");
    }

    return response.data;
  } catch (error) {
    console.error("Error in delete request:", error);
    throw new Error(error.response?.data?.message ?? "Delete request failed.");
  }
};

export const renameHistoryItem = async (new_name, session_id) => {
  try {
    console.log(
      "Sending POST request to /rename-session with item ID:",
      new_name,
      session_id
    );
    const response = await serverAxios.aiSafeAxios.post("/rename-session", {
      new_name,
      session_id,
    });
    console.log("Received response:", response.data);

    if (!response.data.success) {
      throw new Error(response.data.message || "Rename request failed");
    }

    return response.data;
  } catch (error) {
    console.error("Error in rename request:", error);
    throw new Error(error.response?.data?.message ?? "Rename request failed.");
  }
};
