import React, { useState } from 'react'
import TablePro from '../../../components/newTable/TablePro'
import { dummyDataFor_copysql } from '../name_source/nameSource_dummyData'
import { BiEdit, BiTrash } from 'react-icons/bi'
import { UserContext, useUserContext } from "../../../hooks/UserContext";
import Copy_name from '../name_source/Copy_name';
import { inputField_manage_Project } from './_constant';

const Manage_projects = () => {
  // const { setLoading } = useContext(UserContext);
  const { showToast } = useUserContext();
  const [copyName, setCopyName] = useState({ show: false, id: {} });

  const column = [
    {
      name: 'Project Name',
      field: "projectName"
    },
    {
      name: "Project description",
      field: "configSet"
    },
    {
      name: "Bussines Area",
      field: "firstNameField"
    },
    {
      name: "Insdustry Area",
      field: "lastNameField"
    },
    {
      name: "Project Attributes",
      field: "table"
    },
    {
      name: "Actions",
      type: "customControl",
      color: "rgba(16, 24, 40, 1)",
      fnRenderCustomControl: (i) => {
        return <div className=' d-flex gap-3'>
          <div className="level-text cursor-pointer" onClick={() => setCopyName({ show: true, id: i })}><BiEdit  size={25}/></div>
          <div className="level-text cursor-pointer" onClick={() => showToast("Delete Functionality is Under Construction!", 'fail')}><BiTrash size={25} /></div>
        </div>;
      }
    }

  ]
  return (
    <div>
      {copyName.show && (
          <Copy_name setCopyName={setCopyName} copyName={copyName} inputField={inputField_manage_Project} />
       
      )}
      <div className="d-flex justify-content-between pt-3">
        <h2 className="font-4">Manage Projects</h2>
        <div className="d-flex gap-2">
          <button className="btn-purple"onClick={()=>setCopyName({show:true, id:''})}><BiEdit size={22}/> Create project</button>
        </div>
      </div>
      <div className="pt-3">
        <TablePro columns={column} data={dummyDataFor_copysql} />
      </div>

    </div>
  )
}

export default Manage_projects
