import React, { useState, useContext } from "react";
import { serverAxios } from "../../utils/AxiosUtils";
import { RxCross1 } from "react-icons/rx";
import { UserContext, useUserContext } from "../../hooks/UserContext";
import { useTranslation } from "react-i18next";

const ConditionTable = ({ utilityId, isPanel, refresh, listOfTableIds }) => {
  const { t } = useTranslation("utility");

  const { showToast } = useUserContext();
  const { isLoading, setLoading } = useContext(UserContext);
  const [secondFieldType, setSecondFieldType] = useState(true);
  const [disabledForBlank, setDisabledForBlank] = useState(false);
  const [funtionFieldOnCondition, setFuntionFieldOnCondition] = useState("");

  const [formFieldsConditionTable, setFormFieldsConditionTable] = useState({
    conditionField: "",
    conditionName: "",
    selectedCompare: "",
    conditionRule: "",
    conditionTablesIds: [""],
    conditionTablesIdsSave: [""],
    conditionValue: "",
  });
  const handleChangeInputCondition = (fieldName, value) => {
    const updatedFormFields = { ...formFieldsConditionTable };

    switch (fieldName) {
      case "conditionField":
        // updatedFormFields.conditionField = value;
        if (/\s|,/.test(value)) {
          // setShowToast(true);
          showToast("Condition Field cannot contain spaces or commas");
        } else {
          updatedFormFields.conditionField = value;
        }
        break;
      case "conditionName":
        updatedFormFields.conditionName = value;
        break;
      case "conditionTablesIds":
        if (value === "select-all-option") {
          const allTableIds = listOfTableIds.map((value) => value.id);
          updatedFormFields.conditionTablesIds = allTableIds;
          updatedFormFields.conditionTablesIdsSave = value;
        } else {
          updatedFormFields.conditionTablesIds = [value];
          updatedFormFields.conditionTablesIdsSave = value;
        }
        break;
      case "conditionValue":
        updatedFormFields.conditionValue = value;
        break;
      case "selectedCompare":
        if (funtionFieldOnCondition === "Is") {
          if (value === "blank") {
            updatedFormFields.conditionRule = "EQUAL";
          } else if (value === "Equal") {
            updatedFormFields.conditionRule = "EQUAL";
          } else if (value === "GreaterThan") {
            updatedFormFields.conditionRule = "GREATER_THAN";
          } else if (value === "LessThan") {
            updatedFormFields.conditionRule = "LESS_THAN";
          } else if (value === "StartWith") {
            updatedFormFields.conditionRule = "START_WITH";
          } else if (value === "onTheListManualEntry") {
            updatedFormFields.conditionRule = "IN";
          } else if (value === "linkedTableField") {
            setSecondFieldType(true);
            // setDisabledForBlank(true);
            updatedFormFields.conditionRule = "LINKED_TO";

          } 
        } else if (funtionFieldOnCondition === "IsNot") {
          if (value === "blank") {
            updatedFormFields.conditionRule = "NOT_EQUAL";
          } else if (value === "Equal") {
            updatedFormFields.conditionRule = "NOT_EQUAL";
          } else if (value === "GreaterThan") {
            updatedFormFields.conditionRule = "LESS_THAN_OR_EQUAL";
          } else if (value === "LessThan") {
            updatedFormFields.conditionRule = "GREATER_THAN_OR_EQUAL";
          } else if (value === "StartWith") {
            updatedFormFields.conditionRule = "NOT_START_WITH";
          } else if (value === "onTheListManualEntry") {
            updatedFormFields.conditionRule = "NOT_IN";
            setSecondFieldType(false);
          } else if (value === "linkedTableField") {
            updatedFormFields.conditionRule = "NOT_LINKED_TO";
            // setDisabledForBlank(true);
          } else {
            setSecondFieldType(true);
            setDisabledForBlank(false);
          }
        }
        updatedFormFields.selectedCompare = value;
        break;
      default:
        break;
    }
    setFormFieldsConditionTable(updatedFormFields);
  };
  const onSaveConditionAddTableData = () => {
    if (!formFieldsConditionTable.conditionName) {
      showToast("Condition Name is Empty");
    } else if (!formFieldsConditionTable.conditionRule) {
      showToast("Condition Rule is Empty");
    } else if (!formFieldsConditionTable.conditionTablesIds) {
      showToast("Table Field is Empty");
    } else {
      const formFieldsConditionTableOnSave = {
        conditionField: formFieldsConditionTable.conditionField,
        conditionName: formFieldsConditionTable.conditionName,
        // selectedCompare: formFieldsConditionTable.selectedCompare,
        conditionRule: formFieldsConditionTable.conditionRule,
        conditionTablesIds: formFieldsConditionTable.conditionTablesIds,
        conditionValue: formFieldsConditionTable.conditionValue,
      };
      setLoading(true);
      // console.log("conditon data which is going to save::: ", formFieldsConditionTableOnSave)
      serverAxios.appSafeAxios
        .post(
          `utility-set//${utilityId}/conditions`,
          formFieldsConditionTableOnSave,
          {
            "Content-Type": "application/json",
          }
        )
        .then((res) => {
          showToast(res.data.message, "success");
          isPanel(false);
          refresh();
        })
        .catch((oError) => {
          // showToast()
          console.error("condition data saving error:::: ", oError);
          showToast(oError.message, "fail");
        })
        .finally(() => {
          setLoading(false);
          // console.log("condition finally---");
        });
    }
  };
  return (
    <>
      <div className="panel-utility">
        <div className="panel-screen">
          <div className="header-panel">
            <div className="left font-5">{t("Conditions")}</div>
            <div className="right">
              <RxCross1
                className="cross cursor-pointer"
                onClick={() => isPanel(false)}
              />
            </div>
          </div>
          <div className="field-group-section-panel">
            <div className="order1 field-section-panel">
              <label className="level-text font-5">
                {t("Condition Name")} *
              </label>
              <input
                type="text"
                name="conditionName"
                value={formFieldsConditionTable.conditionName}
                onChange={(e) =>
                  handleChangeInputCondition("conditionName", e.target.value)
                }
                className="width-of-panel-input input-field-shadow font-4 "
                placeholder={t("Do not apply to...")}
              />
            </div>
            <div className="order2 drop-1-util ">
              <label className=" level-text font font-5">{t("Tables")} *</label>
              <select
                className="dropDown-field-maya font-4"
                name="conditionTablesIdsSave"
                value={formFieldsConditionTable.conditionTablesIdsSave}
                onChange={(e) =>
                  handleChangeInputCondition(
                    "conditionTablesIds",
                    e.target.value
                  )
                }
              >
                <option></option>
                <option value="select-all-option">All in Utility Set</option>
                {listOfTableIds.map((value, i) => (
                  <option key={value.tableId} value={value.id}>
                    Table{" - "}
                    {value.tableName}
                  </option>
                ))}
              </select>
            </div>
            <div className="field-section-panel ">
              <label className="  level-text font font-5">
                {t("Fields")} *
              </label>
              <input
                type="text"
                name="conditionField"
                value={formFieldsConditionTable.conditionField}
                onChange={(e) =>
                  handleChangeInputCondition("conditionField", e.target.value)
                }
                className="input-field-shadow font-4 "
                placeholder={t("Do not apply to...")}
              />
            </div>
            <div className="field-section-panel ">
              <label className=" level-text font font-5">
                {t("Function")} *
              </label>
              <div className="selection">
                <select
                  className="width-of-fun-field dropDown-field-maya font-4"
                  name="selectedFunction"
                  value={funtionFieldOnCondition}
                  onChange={(e) => setFuntionFieldOnCondition(e.target.value)}
                >
                  <option></option>
                  <option value="IsNot" className="drop-select">
                    Is Not
                  </option>
                  <option value="Is" className="drop-select">
                    Is
                  </option>
                </select>
                <select
                  className="width-of-comapre-field dropDown-field-maya font-4"
                  name="selectedCompare"
                  value={formFieldsConditionTable.selectedCompare}
                  onChange={(e) =>
                    handleChangeInputCondition(
                      "selectedCompare",
                      e.target.value
                    )
                  }
                >
                  <option value="select..." className="drop-select"></option>
                  <option value="blank" className="drop-select">
                    [Blank]
                  </option>
                  <option value="Equal" className="drop-select">
                    Equal
                  </option>
                  <option value="GreaterThan" className="drop-select">
                    Greater than
                  </option>
                  <option value="LessThan" className="drop-select">
                    Less than
                  </option>
                  <option value="StartWith" className="drop-select">
                    Start with
                  </option>
                  <option value="onTheListManualEntry" className="drop-select">
                    On the list (manual entry)
                  </option>
                  <option value="linkedTableField" className="drop-select">
                    Linked [table].[field]
                  </option>
                </select>
              </div>
            </div>
            <div className="field-section-panel">
              <div className="field-section-panel">
                <label className="font-5 level-text">{t("Value(s)")}</label>
                {formFieldsConditionTable.selectedCompare ===
                "onTheListManualEntry" ? (
                  <>
                    <textarea
                      name="conditionValue"
                      value={formFieldsConditionTable.conditionValue}
                      onChange={(e) =>
                        handleChangeInputCondition(
                          "conditionValue",
                          e.target.value
                        )
                      }
                      rows="4"
                      cols="50"
                      className=" panel-textarea"
                    />
                  </>
                ) : (
                  <>
                    {formFieldsConditionTable.selectedCompare ===
                      "linkedTableField" && (
                      <p className="disabled font-4">
                        Start typing for suggestions
                      </p>
                    )}
                    <input
                      type="text"
                      name="conditionValue"
                      // disabled={
                      //   formFieldsConditionTable.selectedCompare ===
                      //   "linkedTableField"
                      //     ? true
                      //     : false
                      // }
                      value={formFieldsConditionTable.conditionValue}
                      placeholder={
                        formFieldsConditionTable.selectedCompare ===
                        "linkedTableField"
                          ? "[schema].[table].[field].[condition] | schema & condition is optional"
                          : "values"
                      }
                      onChange={(e) =>
                        handleChangeInputCondition(
                          "conditionValue",
                          e.target.value
                        )
                      }
                      className=" width-of-panel-input input-field-shadow font-4"
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="footer-panel">
            <button
              className="btn-purple font-6"
              onClick={onSaveConditionAddTableData}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M14.1667 17.5V10.8333H5.83333V17.5M5.83333 2.5V6.66667H12.5M15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H13.3333L17.5 6.66667V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5Z"
                  stroke="#FCFAFF"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {t("Save")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConditionTable;
