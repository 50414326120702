import React from 'react'
import TablePro from '../../components/newTable/TablePro'
import { RiArrowDropDownLine, RiArrowDropUpLine} from 'react-icons/ri'
import "../../components/ExecutedTable/ExecutedTable.scss";
import conditionDataJson from "../../model/condition_data.json"
const Condition = ({ data, isOpenCondition, setIsOpenCondition}) => {
    const conditionTableHeaders = [
        {
            name: "Condition Name",
            field: "conditionName",
            type: "text",
        },
        
        {
            name: "Field",
            field: "conditionField",
            type: "text",
        },
        {
            name: "Rule & Value",
            field: "conditionRule",
            type: "customControl",
            fnRenderCustomControl: (item) => {
                return (<div className="ruleAndValue">
                    <p className='font-4'>{conditionDataJson[item.conditionRule]}{" : "}{item.conditionValue}</p>
                </div>)

            }

        },
        {
            name: "Table",
            field: "conditionTables",
            type: "customControl",
            width: "46%",
            fnRenderCustomControl : (item) => {
                const fieldNames = typeof item.conditionTables !== 'string'
                    ? item.conditionTables.map(({ tableName }) => tableName).join(', ')
                    : item.conditionTables;

                return <p className="font-4" key={fieldNames}>{fieldNames}</p>;
            }
        },
        
    ];
  return (
   <>
          <div className="section-mid ">
              <div className="mid-line-one">
                  <div className="left-a">
                      <div className="row-number font-5">
                          {data.length} Conditions
                      </div>
                  </div>
                  {/* <div className="right-a">
                      <div className="right">
                          {isOpenCondition && (
                              <button
                                  className="btn-low-purple font-6"
                                  onClick={() =>
                                      setIsOpenConditionPanel(!isOpenConditionPanel)
                                  }
                              >
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                  >
                                      <path
                                          d="M10.0001 4.16797V15.8346M4.16675 10.0013H15.8334"
                                          stroke="#344054"
                                          strokeWidth="1.67"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                      />
                                  </svg>
                                  Add a Condition
                              </button>
                          )}
                      </div>
                  </div> */}
              </div>
              <div className="mid-line-tab border rounded-3 p-3 bg-white w-100 d-flex flex-column gap-3">
                  <div className="midle-line">
                      <div
                          className="inner-con-line cursor-pointer left d-flex justify-content-between w-100"
                          onClick={() => setIsOpenCondition(!isOpenCondition)}
                      >
                          <div className="d-flex  con-header d-flex align-items-center gap-2 ">
                              <div className="anomi d-flex align-items-center gap-2">
                                  <div className="circle d-flex justify-content-center align-items-center rounded-circle">
                                      <svg
                                          className="con-icon"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                      >
                                          <path
                                              d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10ZM12 10V5.33333C12 4.97971 11.8595 4.64057 11.6095 4.39052C11.3594 4.14048 11.0203 4 10.6667 4H8.66667M4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6ZM4 6V14"
                                              stroke="white"
                                              strokeWidth="1.33333"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                          />
                                      </svg>
                                  </div>
                                  <div className="text1-util font-5">
                                      {window.innerWidth <= 767
                                          ? "Anonymize records where all..."
                                          : "Anonymize records where all conditions are met"}
                                  </div>
                              </div>

                              <div className="conditions-list d-flex gap-2">
                                  {data.length > 0 ? (
                                      data.slice(0, 4).map((condition, index) => (
                                          <>
                                              <div
                                                  key={index}
                                                  className="text2-util text-condition font-4 d-flex justify-content-center align-items-center text-center"
                                              >
                                                  {condition.conditionName}
                                              </div>
                                          </>
                                      ))
                                  ) : (
                                      <div className="text2-util text-condition font-4">
                                          No data
                                      </div>
                                  )}

                                  {data.length > 4 && (
                                      <div className="text2-util text-condition font-4">
                                          ...
                                      </div>
                                  )}
                              </div>
                          </div>
                          <div className="dropdown-icon cursor-pointer text6-util">
                              {isOpenCondition ? (
                                  <>
                                      <RiArrowDropDownLine size={24} />
                                  </>
                              ) : (
                                  <>
                                      <RiArrowDropUpLine size={24} />
                                  </>
                              )}
                          </div>
                      </div>
                  </div>
                  {isOpenCondition && (
                      <div className="table-condition ">
                          <TablePro
                              data={data}
                              columns={conditionTableHeaders}
                              // refreshHandler={getConditionTableData}
                              minRows={5}
                          />
                      </div>
                  )}
              </div>
          </div></>
  )
}

export default Condition