import React, { useState, useEffect } from 'react';

const Timer = ({ duration, onTimeout }) => {
    const [timeLeft, setTimeLeft] = useState(duration);

    useEffect(() => {
        if (timeLeft === 0) {
            if (onTimeout) onTimeout(); // Optionally call a timeout handler
            return;
        }

        const timerId = setInterval(() => {
            setTimeLeft(prevTime => prevTime - 1);
        }, 1000);

        return () => clearInterval(timerId);
    }, [timeLeft, onTimeout]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secondsLeft = seconds % 60;
        return `${minutes}:${secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft}`;
    };

    return (
            <spam className='mb-0'>{formatTime(timeLeft)}</spam>
    );
};

export default Timer;
