import React, { useContext, useState } from 'react';
import Copy_name from './Copy_name';
import TablePro from '../../../components/newTable/TablePro';
import { dummyDataFor_copysql, inputField_copy_name } from './nameSource_dummyData';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { UserContext, useUserContext } from "../../../hooks/UserContext";
import { MdCreate } from 'react-icons/md';

const Name_source = () => {
    const { setLoading } = useContext(UserContext);
    const { showToast } = useUserContext();
    const [copyName, setCopyName] = useState({ show: false, id: {} });
    const column = [
        {
            name: 'Project Name',
            field: "projectName"
        },
        {
            name: "Config Set",
            field: "configSet"
        },
        {
            name: "First Name Field",
            field: "firstNameField"
        },
        {
            name: "Last Name Field",
            field: "lastNameField"
        },
        {
            name: "Table",
            field: "table"
        },
        {
            name: "Action",
            type: "customControl",
            color: "rgba(16, 24, 40, 1)",
            fnRenderCustomControl: (i) => {
                return <div className=' d-flex gap-2'>
                    <div className="level-text cursor-pointer" onClick={() => setCopyName({ show: true, id: i })}><BiEdit size={25} /></div>
                    <div className="level-text cursor-pointer" onClick={() => showToast("Delete Functionality is Under Construction!", 'fail')}><BiTrash size={25} /></div>
                </div>;
            }
        }

    ]

    return (
        <>
            {copyName.show && (
                    <Copy_name setCopyName={setCopyName} copyName={copyName} inputField={inputField_copy_name} />
            )}
            <div className='d-flex justify-content-between pt-3'>
                <h2 className="font-5">Name Source</h2>
                <div className="">
                    <button className="btn-purple " onClick={() => setCopyName({ show: true, id: '' })}>
                        <MdCreate /> Create
                    </button>
                </div>
            </div>
            <TablePro data={dummyDataFor_copysql} columns={column} />

        </>
    );
};

export default Name_source;
