import React from "react";
import PropTypes from "prop-types";

const PrivacySettings = ({
  privacySettings,
  handleCheckboxChange,
  closeSidebar,
  modelLanguage,
  handleLanguageChange,
  handleIsChatAnonymized,
}) => (
  <div className="">
    <div className="header">
      <h2 className="font-5">Privacy Settings</h2>
      <button className="close-button" onClick={closeSidebar}>
        ×
      </button>
    </div>
    <p className="font-4">Enable Privacy for:</p>
    {Object.keys(privacySettings).map((key) => (
      <div className="switch_body" key={key}>
        <label className="switch">
          <input
            type="checkbox"
            checked={privacySettings[key]}
            onChange={() => handleCheckboxChange(key)}
          />
          <span className="slider round"></span>
        </label>
        <span className="switch_label level-text level-text font-4">{key}</span>
      </div>
    ))}
    <div className="language_toggle">
      <label className="level-text level-text font-5">Model Language:</label>
      <div className="d-flex gap-3 font-4">
        <label className="font-4 d-flex gap-1 level-text level-text">
        <input
          type="radio"
          name="modelLanguage"
          value="eng"
          checked={modelLanguage === "eng"}
          onChange={() => handleLanguageChange("eng")}
        />
        English
      </label>
        <label className="font-4 d-flex gap-1 level-text level-text">
        <input
          type="radio"
          name="modelLanguage"
          value="deu"
          checked={modelLanguage === "ger"}
          onChange={() => handleLanguageChange("ger")}
        />
        German
      </label>
      </div>
    </div>
    <div>
     
    </div>
  </div>
);

PrivacySettings.propTypes = {
  privacySettings: PropTypes.object.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  closeSidebar: PropTypes.func.isRequired,
  modelLanguage: PropTypes.string.isRequired,
  handleLanguageChange: PropTypes.func.isRequired,
};

export default PrivacySettings;
