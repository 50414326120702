import React, { useEffect, useState } from 'react';
import { FiCheck } from 'react-icons/fi';
import { getTableFirst10Row } from '../../api/UtilityApi';
import TablePro from '../newTable/TablePro';

const getColumnHeaders = (data) => {
    if (data.length > 0) {
        return Object.keys(data[0])
            .reverse().map((key) => {
                return {
                    name: key,
                    field: key,
                    accessor: key,
                }
            })
    }
    return []
}

const Top10Popup = ({ configSetId, utilitySetId, table, onCancel }) => {
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '1050',
    };
    const [data, setdata] = useState([]);
    const [columns, setColumns] = useState([]);
    useEffect(() => {
        getTableFirst10Row({ configsetid: configSetId, utilitysetid: utilitySetId, tableid: table?.id }).then((res) => {
            setdata(res);
            setColumns(getColumnHeaders(res));
        }).catch((err) => {
            console.log(err);
        })
    }, [configSetId, utilitySetId, table, onCancel])
    return (
        <>
            <div style={modalStyle} className="modal-dialog modal-dialog-centered " role="document">
                <div  >
                    <div className="modal-content" style={{ border: '1px solid var(--gray-300, #D0D5DD)' }}>
                        <div className="modal-header">
                            <h5 className="modal-title font-5" id="exampleModalLongTitle">{table?.tableName}</h5>
                            <button onClick={() => onCancel()} type="button" className="close btn btn-transparent border-0 font-6" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" className='font-6' >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body font-4">
                            {data.length === 0 && <p>No Data Found</p>}
                            {data.length > 0 && <TablePro data={data}
                                columns={columns} minRows={5} />}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Top10Popup