import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAppContext, { AuthState } from "../../hooks/useAppContext";

const AuthRoot = () => {
  const { authState } = useAppContext();
  const location = useLocation();

  if (authState !== AuthState.Unauthenticated) {
    const from =
      location.state?.from?.pathname === "/" ||
        location.state?.from?.pathname === undefined
        ? "/test/landing"
        : location.state?.from?.pathname;
    window.location.reload();

    // if (from === "/test/landing") {
    //   const hasRefreshed = sessionStorage.getItem("hasRefreshed");

    //   if (!hasRefreshed) {
    //     sessionStorage.setItem("hasRefreshed", "true");
    //     window.location.href = from;
    //     window.location.reload();
    //     return null;
    //   }
    // }

    if (from.startsWith("http")) {
      window.location.href = from;
      window.location.reload();
      return null;
    }

    return <Navigate to={from} replace />;
  }

  return (
    <main>
      <Outlet />
    </main>
  );
};

export default AuthRoot;
