import React, { useContext, useEffect, useState } from "react";
import "./ExecutedTable.scss";
import { UserContext, useUserContext } from "../../hooks/UserContext";
import { BiFilter, BiPlus, BiSort } from "react-icons/bi";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import dUtilitySetSingleEntry from "../../model/UtilitySetSingleEntry.json";
import midDataTable from "../../model/UtilitySetMidleTable.json";
import TablePro from "../newTable/TablePro";
import conditionDataJson from "../../model/condition_data.json";
import { BsThreeDotsVertical } from "react-icons/bs";
import UtilityColumnComp from "../UtilityTable/UtilityColumnComp";
import AddTableUtility from "./AddTableUtility";
import ConditionTable from "./ConditionTable";
import {
  handleDeleteRowOfConditionTable,
  deleteTableInUtilitySet,
  getConditionTable,
  getTopTable,
} from "../../hooks/apis/useAppSafeApi";
// import { getTableFirst10Row } from "../../api/UtilityApi";
import Top10Popup from "../popupModel/Top10Popup";
import useAppContext from "../../hooks/useAppContext";
// import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ExecutedTable = ({ configSetId, encodedUtilityId }) => {
  const { t } = useTranslation("utility");

  const { popupModel } = useAppContext();
  const { showToast } = useUserContext();
  const { isLoading, setLoading } = useContext(UserContext);
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [midTableData, setMidTableData] = useState([]);
  const [utilitySetTables, setUtilitySetTables] = useState(
    dUtilitySetSingleEntry
  );
  const [utilityTables, setUtilityTables] = useState([]);
  const [conditionTableData, setConditionTableData] = useState([]);
  const [blueColour, setBlueColour] = useState(false);
  const [isOpenCondition, setIsOpenCondition] = useState(false);
  const [isOpenConditionPanel, setIsOpenConditionPanel] = useState(false);
  const [isOpenAddTablePanel, setIsOpenAddTablePanel] = useState(false);
  const [listOfTableIds, setListOfTableIds] = useState([]);
  const [currentTableForView, setCurrentTableForView] = useState(null);

  const editLogo = (
    <svg
      className="cursor-pointer item-1"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_7119_14588)">
        <path
          d="M14.167 2.49895C14.3859 2.28008 14.6457 2.10646 14.9317 1.98801C15.2176 1.86956 15.5241 1.80859 15.8337 1.80859C16.1432 1.80859 16.4497 1.86956 16.7357 1.98801C17.0216 2.10646 17.2815 2.28008 17.5003 2.49895C17.7192 2.71782 17.8928 2.97766 18.0113 3.26362C18.1297 3.54959 18.1907 3.85609 18.1907 4.16562C18.1907 4.47514 18.1297 4.78164 18.0113 5.06761C17.8928 5.35358 17.7192 5.61341 17.5003 5.83228L6.25033 17.0823L1.66699 18.3323L2.91699 13.7489L14.167 2.49895Z"
          stroke="#667085"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7119_14588">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const deleteRowOfConditionTable = (id) => {
    setLoading(true);
    handleDeleteRowOfConditionTable(encodedUtilityId, id)
      .then((res) => {
        showToast(res, "success");
        getConditionTableData();
      })
      .catch((err) => {
        showToast("Something went wrong, please try again later!", "fail");
        console.log("condition delete get error ", err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handelDeleteTableInUtilitySet = (id) => {
    setLoading(true);
    deleteTableInUtilitySet(encodedUtilityId, id)
      .then((res) => {
        showToast(res, "success");
        getTopTableData();
        setSelectedTable(null);
      })
      .catch((err) => {
        showToast("Something went wrong, please try again later!", "fail");
        console.log("util delete get error ", err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getTopTableData();
    getConditionTableData();
    fetchMidTableData();
  }, []);
  const getConditionTableData = () => {
    getConditionTable(encodedUtilityId)
      .then((res) => {
        setConditionTableData(res);
      })
      .catch((err) => {
        console.log("util delete get error ", err.message);
      });
  };
  const getTopTableData = () => {
    getTopTable(encodedUtilityId)
      .then((res) => {
        setUtilityTables(res);
        setListOfTableIds(res);
      })
      .catch((err) => {
        console.log("util delete get error ", err.message);
      });
  };
  const handleFilterClick = () => {
    const sortedData = [...utilityTables].sort((a, b) =>
      a.createdAt.localeCompare(b.tableName)
    );
    setUtilityTables(sortedData);
  };
  const handleSortByName = () => {
    const sortedData = [...utilityTables].sort((a, b) =>
      a.tableName.localeCompare(b.tableName)
    );
    setUtilityTables(sortedData);
  };

  const fetchMidTableData = async () => {
    if (midDataTable.rule === "Is: [List]") {
      setBlueColour(true);
    }
    const jsonData = await midDataTable();
    setMidTableData(jsonData);
  };
  const [selectedTable, setSelectedTable] = useState(null);
  const [selectedUtilityTableId, setSelectedUtilityTableId] = useState("");
  const handleTableRowClick = (table) => {
    setSelectedTable(table);
    const targetSection = document.getElementById("targetSection");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  const topTableHeaders = [
    {
      name: t("Table Name"),
      field: "tableName",
      width: "11%",
      accessor: "name",
      type: "customControl",
      fnRenderCustomControl: (item) => {
        return (
          <>
            <div
              data-toggle="tooltip"
              data-placement="top"
              title="Click to edit parameters and its conditions."
            >
              <div
                className={`font-6 cursor-pointer ${
                  selectedTable?.id === item.id ? "highlighted " : "ano-col s "
                }`}
                data-actionable={true}
                onClick={() => handleTableRowClick(item)}
              >
                {item.tableName}
              </div>
            </div>
          </>
        );
      },
    },
    {
      name: t("tableId"),
      field: "tableName",
      type: "text",
      width: "12%",
    },
    {
      ...(configSetId && {
        name: "entries",
        field: "entries",
        type: "text",
        width: "10%",
      }),
    },
    {
      name: t("Fields"),
      field: "name",
      type: "customControl",
      width: "51%",
      fnRenderCustomControl: (item) => {
        const fieldNames = item.anonymizeFields
          .map((field) => field.name)
          .join(", ");
        return (
          <p className="font-4" key={fieldNames}>
            {fieldNames}
          </p>
        );
      },
    },
    {
      name: t("Status"),
      field: "status",
      type: "customControl",
      width: "20%",
      fnRenderCustomControl: (item) => {
        return (
          <div className="mid-table-col-5">
            <div className="action-btn-h">
              {configSetId && (
                <svg
                  className="item-1 cursor-pointer view_button"
                  onClick={() => {
                    if (configSetId) {
                      console.log("handle view clicked ", item);
                      setCurrentTableForView(item);
                    }
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M0.833252 9.9987C0.833252 9.9987 4.16658 3.33203 9.99992 3.33203C15.8333 3.33203 19.1666 9.9987 19.1666 9.9987C19.1666 9.9987 15.8333 16.6654 9.99992 16.6654C4.16658 16.6654 0.833252 9.9987 0.833252 9.9987Z"
                    stroke="#667085"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.99992 12.4987C11.3806 12.4987 12.4999 11.3794 12.4999 9.9987C12.4999 8.61799 11.3806 7.4987 9.99992 7.4987C8.61921 7.4987 7.49992 8.61799 7.49992 9.9987C7.49992 11.3794 8.61921 12.4987 9.99992 12.4987Z"
                    stroke="#667085"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
              <div
                onClick={() => {
                  setSelectedUtilityTableId(item.id);
                  setIsOpenAddTablePanel(true);
                }}
              >
                {editLogo}
              </div>
              <svg
                className="item-1 cursor-pointer"
                onClick={() =>
                  popupModel(
                    "Trash",
                    "Do you wish to proceed with deleting this row?",
                    "Delete",
                    () => handelDeleteTableInUtilitySet(item.id)
                  )
                }
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clipPath="url(#clip0_6470_50043)">
                  <path
                    d="M12.5001 7.5013L7.50008 12.5013M7.50008 7.5013L12.5001 12.5013M18.3334 10.0013C18.3334 14.6037 14.6025 18.3346 10.0001 18.3346C5.39771 18.3346 1.66675 14.6037 1.66675 10.0013C1.66675 5.39893 5.39771 1.66797 10.0001 1.66797C14.6025 1.66797 18.3334 5.39893 18.3334 10.0013Z"
                    stroke="#667085"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6470_50043">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        );
      },
    },
  ];
  const conditionTableHeaders = [
    {
      name: t("Condition Name & Category"),
      field: "conditionName",
      type: "text",
      width: "11%",
      accessor: "name",
    },
    {
      name: t("Fields"),
      field: "conditionField",
      type: "text",
      width: "12%",
    },
    {
      name: t("Rule & Value"),
      field: "conditionRule",
      type: "customControl",
      width: "15%",
      fnRenderCustomControl: (item) => {
        // Assuming item.anonymizeFields is an array of objects with a "name" property
        return (
          <div className="ruleAndValue">
            <p className="font-4">
              {conditionDataJson[item.conditionRule]}
              {" : "}
              {item.conditionValue}
            </p>
          </div>
        );
      },
    },
    {
      name: t("Tables"),
      field: "conditionTables",
      type: "customControl",
      width: "46%",
      fnRenderCustomControl: (item) => {
        // Assuming item.anonymizeFields is an array of objects with a "name" property
        const fieldNames = item.conditionTables
          .map((field) => field.tableName)
          .join(", ");
        return (
          <p className="font-4" key={fieldNames}>
            {fieldNames}
          </p>
        );
      },
    },
    {
      name: t("Actions"),
      field: "",
      type: "customControl",
      width: "20%",
      fnRenderCustomControl: (item) => {
        return (
          <div className="mid-table-col-5">
            <div className="action-btn-h">
              {/* <BiFilter color="#667085" size={20}/> */}
              
              <svg
                className="item-1 cursor-pointer"
                //  onClick={() => handleDeleteRowOfConditionTable(item.tableConditionId)}
                onClick={() => {
                  popupModel(
                    "Trash",
                    "Do you wish to proceed with deleting this row?",
                    "Delete",
                    () => deleteRowOfConditionTable(item.tableConditionId)
                  );
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clipPath="url(#clip0_6470_50043)">
                  <path
                    d="M12.5001 7.5013L7.50008 12.5013M7.50008 7.5013L12.5001 12.5013M18.3334 10.0013C18.3334 14.6037 14.6025 18.3346 10.0001 18.3346C5.39771 18.3346 1.66675 14.6037 1.66675 10.0013C1.66675 5.39893 5.39771 1.66797 10.0001 1.66797C14.6025 1.66797 18.3334 5.39893 18.3334 10.0013Z"
                    stroke="#667085"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6470_50043">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="page-two-util-table-maya">
        {currentTableForView && (
          <div className="backdropPopupStyle">
            {/* <Popup popupTitle={"Deletion"}
                            popupMessage={popupText}
                            isConfirm={handleConfirm} /> */}
            <Top10Popup
              configSetId={configSetId}
              utilitySetId={encodedUtilityId}
              table={currentTableForView}
              onCancel={() => setCurrentTableForView(null)}
            />
          </div>
        )}
        <div className="screen-utiltiy">
          <div className="content-utility-page2">
            <div className="section-run-util ">
              <div className="sec-2-util">
                <div className="table-content-step-2">
                  <div className="table-header">
                    <div className="rowone">
                      <div className="row-number font-4">
                        {utilityTables.length} {t("Fields")}
                      </div>
                      <BsThreeDotsVertical
                        className="button-container-table-collased-btn cursor-pointer"
                        onClick={() => setOpenFilterMenu(!openFilterMenu)}
                      />
                      <div className="button-container-table">
                        <div className="button-group-container-table ">
                          <button
                            type="button"
                            className="btn-purple font-6 "
                            onClick={() => {
                              setIsOpenAddTablePanel(true);
                              setSelectedUtilityTableId(null);
                            }}
                          >
                            <BiPlus></BiPlus> {t("add")} {t("Tables")}
                          </button>
                          <button
                            type="button"
                            className="btn-low-purple font-6 "
                            onClick={handleSortByName}
                          >
                            {t("sort")} <RiArrowDropDownLine />{" "}
                          </button>

                          <button
                            type="button"
                            className="res-back-btn font-6 "
                            onClick={handleFilterClick}
                          >
                            <BiFilter></BiFilter> {t("Filters")}
                          </button>
                        </div>
                      </div>
                    </div>
                    {openFilterMenu && (
                      <>
                        <div className="item-list">
                          <div
                            className="font-6 text-sm"
                            onClick={() => {
                              setIsOpenAddTablePanel(true);
                              setSelectedUtilityTableId(null);
                            }}
                          >
                            <BiPlus /> {t("add")} {t("Tables")}
                          </div>
                          <div className="font-6" onClick={handleSortByName}>
                            <BiSort /> {t("sort")}
                          </div>
                          <div className="font-6" onClick={handleFilterClick}>
                            <BiFilter /> {t("Filters")}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="responsive-table">
                    <TablePro
                      data={utilityTables}
                      columns={topTableHeaders}
                      minRows={4}
                      refreshHandler={getTopTableData}
                    />
                  </div>
                </div>
              </div>
              <div className="section-mid ">
                <div className="mid-line-one">
                  <div className="left-a">
                    <div className="row-number font-5">
                      {conditionTableData.length} {t("Conditions")}
                    </div>
                  </div>
                  <div className="right-a">
                    <div className="right">
                      {isOpenCondition && (
                        <button
                          className="btn-low-purple font-6"
                          onClick={() =>
                            setIsOpenConditionPanel(!isOpenConditionPanel)
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M10.0001 4.16797V15.8346M4.16675 10.0013H15.8334"
                              stroke="#344054"
                              strokeWidth="1.67"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          {t("Add a Condition")}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mid-line-tab">
                  <div className="midle-line">
                    <div
                      className="inner-con-line cursor-pointer left"
                      onClick={() => setIsOpenCondition(!isOpenCondition)}
                    >
                      <div className="con-header">
                        <div className="anomi">
                          <div className="circle">
                            <svg
                              className="con-icon"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10ZM12 10V5.33333C12 4.97971 11.8595 4.64057 11.6095 4.39052C11.3594 4.14048 11.0203 4 10.6667 4H8.66667M4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6ZM4 6V14"
                                stroke="white"
                                strokeWidth="1.33333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <div className="text1-util font-5">
                            {window.innerWidth <= 767
                              ? t("anonymize-short")
                              : t("anonymize")}
                          </div>
                        </div>

                        <div className="conditions-list">
                          {conditionTableData.length > 0 ? (
                            conditionTableData
                              .slice(0, 4)
                              .map((condition, index) => (
                                <>
                                  <div
                                    key={index}
                                    className="text2-util text-condition font-4"
                                  >
                                    {condition.conditionName}
                                  </div>
                                </>
                              ))
                          ) : (
                            <div className="text2-util text-condition font-4">
                              No Conditions
                            </div>
                          )}

                          {conditionTableData.length > 4 && (
                            <div className="text2-util text-condition font-4">
                              ...
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="dropdown-icon cursor-pointer text6-util">
                        {isOpenCondition ? (
                          <>
                            <RiArrowDropDownLine size={24} />
                          </>
                        ) : (
                          <>
                            <RiArrowDropUpLine size={24} />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {isOpenCondition && (
                    <div className="table-condition ">
                      <TablePro
                        data={conditionTableData}
                        columns={conditionTableHeaders}
                        refreshHandler={getConditionTableData}
                        minRows={5}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="table-add">
                {selectedTable && (
                  <UtilityColumnComp
                    utilitySetId={encodedUtilityId}
                    selectedTable={selectedTable}
                    refreshHandle={getTopTableData}
                    refreshParam={() => handleTableRowClick}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {isOpenConditionPanel && (
          <ConditionTable
            utilityId={encodedUtilityId}
            isPanel={setIsOpenConditionPanel}
            refresh={getConditionTableData}
            listOfTableIds={listOfTableIds}
          />
        )}
        {isOpenAddTablePanel && (
          <AddTableUtility
            refresh={getTopTableData}
            isPanel={setIsOpenAddTablePanel}
            utilityId={encodedUtilityId}
            utilityTableId={selectedUtilityTableId}
          />
        )}
      </div>
    </>
  );
};

ExecutedTable.defaultProps = {
  configSetId: undefined, // replace 'default value' with the actual default value you want to use
};
export default ExecutedTable;
