import React, { useEffect, useState } from 'react'
import { InputSection } from '../../../components/FieldSection.jsx/field'
import Select from 'react-select';
import { getUtilityParam } from '../../../hooks/apis/useAppSafeApi';
import { selectDropdownStyle } from '../../defiSettings/PII/PiiData';

const SensitiveData = ({ data, handleInputChange, index, required }) => {
    const [utilityParamsCondition, setUtilityParamsCondition] = useState([]);
    // console.log(data)

    useEffect(() => {
        getUtilityParam().then((res) => {
            setUtilityParamsCondition(res);
        }).catch(err => {
            console.log("err:: ", err.message)
        })
    }, []);
    const displayCondition = () => {
        const utilityParam = data.utilityParam.utilityParamConditions;
        // const availableConditions = utilityParamsCondition
        //     .filter(u => {
        //         if (!utilityParam) return false;

        //         return u.label === utilityParam;
        //     })
        //     .flatMap(u => {
        //         if (u.utilityParamConditions) return u.utilityParamConditions;
        //         return []
        //     }
        //     );
        return (
            <div className="" style={{ marginLeft: '22px' }}>
                <Select classNames='font-4 multi-select'
                    options={utilityParam}
                    value={data.utilityParamConditions}
                    onChange={(selectedOption) => handleInputChange(index, selectedOption,'utilityParamConditions')}
                    isMulti
                    // placeholder={availableConditions.length === 0 ? 'No Options' : data.utilPramCondition.length === 0 ? 'Use Default' : 'Select...'}
                    menuPlacement="auto"
                    styles={selectDropdownStyle}
                // menuPortalTarget={document.body}
                // styles={}
                /></div>)
    }
    return (
        <div className='w-100 d-flex gap-4'>

            <InputSection label="Lead_Table.field_name" value={data.leadTable}
                onChange={(e) => handleInputChange(index, e.target.value, "leadTable")}
                placeholder="" required={true} error={required}/>
            <div className="input-section">
                <label className="level-text font-5">
                    Utility Parameter *
                </label>
                <Select
                    value={data.utilityParam}
                    onChange={(selectedOption) => handleInputChange(index, selectedOption, "utilityParam")}
                    options={utilityParamsCondition}
                    placeholder="Select..."
                    styles={selectDropdownStyle}
                    isSearchable={true}
                    menuPlacement="auto"
                    required={true}

                />
                {required && data.utilityParam.length===0&& <p className='text-danger font-4 fs-6'> Util Param is required </p>}
            </div>
            <div className="input-section">
                <label className="level-text font-5">
                    Util Parameter Conditions 
                </label>
                {displayCondition()}
                {/* {required &&data.utilityParamConditions.length===0&& <p className='text-danger font-4 fs-6'> Util Param Conditions is required </p>} */}

            </div>
            <div className="input-section w-50">
                <label className="level-text font-5">
                    Threshold % 
                </label>
                <input value={data.threshold}
                    onChange={(e) => handleInputChange(index, e.target.value, "threshold")}
                    placeholder=""
                    type="number"
                    min="0"
                    max="100"
                    className='w-50 input-field-shadow font-4'
                />
                {required && data.threshold === '' && <p className='text-danger font-4 fs-6'> Threshold is required </p>}

            </div>


        </div>
    )
}

export default SensitiveData