import React from 'react';
import { FiAlertCircle, FiAlertTriangle, FiCheck, FiSave } from 'react-icons/fi';
import { TiCancel } from 'react-icons/ti';
import { FaCookieBite } from 'react-icons/fa';
import { RxCross2 } from 'react-icons/rx';
import "./popup.scss";

const Popup = ({
    popupTitle,
    popupMessage,
    isConfirm,
    cancelButtonLabel = 'Cancel',
    confirmButtonLabel = 'Confirm',
    removePopup
}) => {
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '1050',
        backgroundColor: 'white',
    };

    // Determine icon based on popupTitle
    // const getTitleIcon = (title) => {
    //     const titleLower = typeof title === 'string' ? title.toLowerCase() : '';
    //     if (titleLower.includes('removal') || titleLower.includes('delete') || titleLower.includes('deletion')) {
    //         return <FiAlertCircle style={{ color: 'purple', fontSize: '20px' }} />;
    //     } else if (titleLower.includes('saving') || titleLower.includes('updating') || titleLower.includes('save')) {
    //         return <FiSave style={{ color: 'purple', fontSize: '20px' }} />;
    //     } else if (titleLower.includes('cookies')) {
    //         return <FaCookieBite style={{ color: 'purple', fontSize: '20px' }} />;
    //     } else {
    //         return <FiAlertTriangle style={{ color: 'purple', fontSize: '20px' }} />;
    //     }
    // };

    const handleClose = () => {
        try {
            if (removePopup) {
                removePopup(false);
            } else if (isConfirm) {
                isConfirm(false);
            }
        } catch (error) {
            console.error('Error handling close action:', error);
        }
    };

    const handleConfirm = () => {
        try {
            if (isConfirm) {
                isConfirm(true);
            }
        } catch (error) {
            console.error('Error handling confirm action:', error);
        }
    };

    return (
        <div style={modalStyle} >
            <div className="modal-data">
                <div className="content">
                    <div className="modal-header">
                        <div className="outer-circle">
                            <FiAlertTriangle style={{ color: 'purple', fontSize: '14px' }} />
                        </div>
                        <button
                            onClick={handleClose}
                            type="button"
                            className="close btn btn-transparent border-0 font-6"
                            aria-label="Close"
                        >
                            <RxCross2 style={{ color: '#666', fontSize: '24px' }} />
                        </button>
                    </div>
                    <div className="modal-text">
                        <h5 id="popup-title" className="modal-title font-5 ml-4">{popupTitle}</h5>
                        <div id="popup-message" className="modal-body font-4">
                            {popupMessage}
                        </div>
                    </div>
                </div>
                <div className="modal-footer w-100">
                    {confirmButtonLabel !== 'Re-login' && (
                        <button
                            className="btn-low-purple"
                            onClick={handleClose}
                        >
                            <TiCancel /> {cancelButtonLabel}
                        </button>
                    )}
                    <button
                        className="btn-purple"
                        onClick={handleConfirm}
                    >
                        <FiCheck /> {confirmButtonLabel}
                    </button>
                </div>
            </div>
        </div>
    );
};



export default Popup;
