import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { UserContext } from "./../hooks/UserContext";
import Sidebar from "./sidebar/Sidebar";
import BusySpinner from "./busySpinner/BusySpinner";
import Toast from "./toast/Toast";
import useAppContext, { AuthState } from "../hooks/useAppContext";
import { StoragePref } from "../utils/LocalStorageUtils";
import Popup from "./popupModel/Popup";
import useAuth from "../hooks/useAuth";
import { FaCookieBite } from "react-icons/fa";
import BottomBar from "./BottomBar/BottomBar";

import "./PrivateRoute.scss";

export default function PrivateRoute(props) {
  const { component: Component, ...rest } = props;
  const [error, setError] = useState(null);
  const { handleLogOutApi } = useAuth();
  const [user, setUser] = useState(StoragePref.user.load);
  const [isLoading, setLoading] = useState(false);
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastMessageType, setToastMessageType] = useState("");
  const storedIsCookies = localStorage.getItem("isCookies");
  const initialIsCookies = storedIsCookies === "true" || false;

  const [isCookies, setIsCookies] = useState(initialIsCookies);
  const { authState } = useAppContext();
  const location = useLocation();

  useEffect(() => {
    if (authState !== "Authenticated") {
      setUser(null);
      return;
    }
    try {
      const newUser = StoragePref.user.load();
      setUser({ ...newUser });

      const storedIsCookies = localStorage.getItem("isCookies");
      if (storedIsCookies === "true") {
        setIsCookies(true);
      }
    } catch (err) {
      console.log("error on private route: ", err);
    }
  }, [authState]);
  const hideToast = () => {
    setToastShow(false);
  };

  const showToast = (message, type) => {
    setToastShow(true);
    setToastMessage(message);
    setToastMessageType(type);
  };
  if (authState !== AuthState.Authenticated) {
    return (
      <Navigate
        to={"/login"}
        state={{ ...location.state, from: location }}
        replace
      />
    );
  }

  const handleConfirm = (result) => {
    if (result) {
      setIsCookies(true);
      localStorage.setItem("isCookies", "true");
    } else {
      handleLogOutApi(user.refreshToken, user.jwtToken);
      setIsCookies(true);
    }
  };
  return (
    <UserContext.Provider
      value={{ user, setUser, isLoading, setLoading, showToast }}
    >
      {!isCookies && (
        <div className="backdropPopupStyle">
          <Popup
            popupTitle={
              <span className="font-6">
                <FaCookieBite /> Cookies
              </span>
            }
            popupMessage={
              <span>
                We use cookies on our web application to enhance your browsing
                experience and for user management purposes. By continuing to
                use our website, you agree to our use of cookies in accordance
                with our Privacy Policy. To read more on Cookies policy{" "}
                <a href="https://mayadataprivacy.eu/privacy-policy">
                  click here
                </a>
                .<br />
                If you do not agree, please click Cancel, which will result in
                logging out from the page. To accept , click the 'Confirm'
                button.
              </span>
            }
            isConfirm={handleConfirm}
          />
        </div>
      )}
      <div className="pageWrapper">
        {isLoading && <BusySpinner />}
        <div className="sidebar">
          <Sidebar />
          <BottomBar />
        </div>
        <div className="pageContainer">
          <Outlet />
          <div className="ToastStyle">
            <Toast
              message={toastMessage}
              show={toastShow}
              hideToast={hideToast}
              type={toastMessageType}
            />
          </div>
        </div>
      </div>
    </UserContext.Provider>
  );
}
