import React, { useContext, useEffect, useState } from "react";
import "./Utility.scss";
import { BiAddToQueue, BiArrowBack } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { BsArrowRight } from "react-icons/bs";
import { FaRegSave } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import { UserContext, useUserContext } from "../../hooks/UserContext";
import SensitiveData from "./sensitive/SensitiveData";
import { TiDeleteOutline } from "react-icons/ti";
import { serverAxios } from "../../utils/AxiosUtils";
import { useParams } from "react-router-dom";
import Select from 'react-select';
import {
  getConfigSetData,
  getUtilityForUpadte,
} from "../../hooks/apis/useAppSafeApi";
import { useTranslation } from "react-i18next";
import { handleValidationError, initialFormDataForAI } from "./sensitive/constant";
import { selectDropdownStyle } from "../defiSettings/PII/PiiData";
const { REACT_APP_SITE_KEY, REACT_APP_SITE_TITLE } = process.env;
const Utility = () => {
  const { t } = useTranslation("utility");

  const { utilityId } = useParams();
  let navigate = useNavigate();
  const { showToast, setLoading, isLoading } = useUserContext();
  const [initialFormData, setinitalFormData] = useState({
    utilitySetName: "",
    description: "",
  });
  const [formData, setFormData] = useState({
    utilitySetName: "",
    description: "",
  });
  const [formDataForAI, setFormDataForAI] = useState(initialFormDataForAI);
  // const { isLoading } = useContext(UserContext);
  const [isdisabled, setIsdisabled] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [aiCreation, setAiCreation] = useState(false);
  const [configSetData, setConfigSetData] = useState("");
  const [alertSign, setAlertsign] = useState(<></>);
  const [linesSensitiveData, setLinesSensitiveData] = useState([]);
  const [validationError, setValidationError]= useState(false);

  const addLineSensitiveData = () => {
    setLinesSensitiveData([...linesSensitiveData, { leadTable: '', utilityParam: {}, threshold: 50, utilityParamConditions: [], privacyRelevance:'CONFIDENTIAL' }]);
  };
  const removeLine = (index) => {
    setLinesSensitiveData(linesSensitiveData.filter((_, i) => i !== index));
  };
  useEffect(() => {
    getConfigSetData().then((res) => {
      setConfigSetData(res);
    });
    if (utilityId) {
      getUtilityForUpadte(utilityId).then((res) => {
        setinitalFormData(res);
        setFormData(res);
      });
    }
  }, []);
  const handleInputChangeSensitiveData = (index, value, name) => {
    const newLines = [...linesSensitiveData];

    if (name === 'leadTable' && value.includes('.')) {
      const [leadTablename, leadtableField] = value.split('.');
      newLines[index] = {
        ...newLines[index],
        leadTableName: leadTablename,
        leadTableField: leadtableField,
      };
    } if (name ==='utilityParam'){
      newLines[index] = {
        ...newLines[index],
        [name]: value,
        utilityParamConditions: []
      };
    } if (name ==='threshold'){
      const numericValue = parseInt(value, 10);
      if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 100) {
        newLines[index] = {
          ...newLines[index],
          [name]: value
        };
      }
    } else {
      newLines[index] = {
        ...newLines[index],
        [name]: value
      };
    }

    setLinesSensitiveData(newLines);
  };


  const getJSON = () => {
    const json = JSON.stringify(linesSensitiveData, null, 2);
    alert(json); 
  };
  const onSaveChangeOfUtilitySetPage = async (e, clickOnNext) => {
    e.preventDefault();
    if(handleValidationError(linesSensitiveData, utilityId, aiCreation, formData, formDataForAI, )){
      savingData(clickOnNext)
    }else{
      setValidationError(true);
    }
    
  };
const savingData=async(clickOnNext)=>{
  const url = linesSensitiveData.length > 0 ? "auto-utility-schema" : utilityId
    ? `utility-set/${utilityId}`
    : aiCreation
      ? "auto-utility/ai"
      : "utility-set";
  const dto = linesSensitiveData.length > 0 ?
    {
      ...formData,
      ...linesSensitiveData[0],
      configSetId: formDataForAI.configSetId,
      tableNames: formDataForAI.tablesName.split(',')
        .map(name => name.trim())
        .filter(name => name.length > 0),
    }
    : aiCreation
      ? {
        ...formData,
        tableNames: formDataForAI.tablesName
          .split(',')
          .map(name => name.trim())
          .filter(name => name.length > 0),
        configSetId: formDataForAI.configSetId,
      }
      : formData;
  setLoading(true);
  serverAxios.appSafeAxios
    .post(url, dto)
    .then((res) => {
      const receivedData = res.data;
      showToast(receivedData.message, "success");
      if (clickOnNext & !aiCreation) {
        navigate(`/utility-set-table/${receivedData.data.utilitySetId}`);
        return;
      }

      navigate("/utilitySetHomePage");
    })
    .catch((err) => {
      showToast(err.message, "fail");
      console.log("The utility set error ->", err);
    })
    .finally(() => {
      setLoading(false);
    });
}
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (!formData.inputValue1) {
      setIsdisabled(false);
    }
  };
  const handleCutButton = () => {
    setShowAlert(false);
  };

  const isButtonDisabled = !formData.inputValue1;
  const options = configSetData&&configSetData?.map((val) => ({
    value: val.id,
    label: val.configSetName,
  }));

  const handleSelectChange = (selectedOption) => {
    setFormDataForAI((prev) => ({
      ...prev,
      configSetId: selectedOption ? selectedOption.value : '',
    }));
  };

  return (
    <>
      <Helmet>
        <title>Utility Solutions - {REACT_APP_SITE_TITLE} </title>
      </Helmet>
      <div>
        <div>
          <div className="main-section">
            {showAlert && (
              <div className="alert alert-show font-5">
                {alertSign}
                <button onClick={handleCutButton} className="alert-btn">
                  <RxCross2 color="#7F56D9"></RxCross2>
                </button>
              </div>
            )}

            <div className="header-section-maya util-header">
              <div className="page-header">
                <div
                  className="res-back-btn font-5 cursor-pointer"
                  onClick={() => navigate(-1)}
                >
                  <BiArrowBack /> {t("Utility Sets")}
                </div>
                <div className="heading1 head-form font-5">
                  {t("New Utility Set")}
                </div>
              </div>
            </div>
            <form className="w-100" onSubmit={(e) => onSaveChangeOfUtilitySetPage(e,true)}>
              <div className="body-section-maya util-body">
                <div className="content">
                  <div className="form w-100">
                    <div className="input-group-section">
                      <div className="input-section w-50">
                        <label htmlFor="usr" className="level-text font-5">
                          {t("Utility Set Name")} *
                        </label>
                        <input
                          type="text"
                          name="utilitySetName"
                          value={formData.utilitySetName}
                          onChange={handleInputChange}
                          className="input-field-shadow font-4"
                          id="usr"
                        />
                        {validationError && formData.utilitySetName === '' && <p className='text-danger font-4 fs-6'> UtilitySet Name is required </p>}

                      </div>
                      <div className="w-50 input-section ">
                        <label htmlFor="usr" className="level-text font-5">
                          {t("Description")}
                        </label>
                        <textarea
                          type="text"
                          name="description"
                          value={formData.description}
                          onChange={handleInputChange}
                          className="input-field-shadow font-4"
                          id="usr"
                          placeholder={t("Tell us")}
                        />
                      </div>
                      <div className="radio-field w-50">
                        <div className="title font-5">{t("Creation Mode")}</div>
                        <div className="form-check pt-2">
                          <input
                            type="radio"
                            className="form-check-input font-4"
                            id="btn1"
                            name="databaseMode"
                            value={true}
                            checked={aiCreation === false}
                            onChange={() => setAiCreation(false)}
                          />
                          <label className="form-check-label font-4">
                            {" "}
                            {t("Manual")}{" "}
                          </label>
                        </div>
                        <div className="form-check pt-2 w-100">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="btn1"
                            name="databaseMode"
                            value={false}
                            checked={aiCreation === true}
                            onChange={() => setAiCreation(true)}
                          />
                          <label className="form-check-label font-4">
                            {" "}
                            {t("AI Generated")}{" "}
                          </label>
                        </div>
                      </div>
                      {aiCreation && (
                        <>
                          <div className="w-50 d-flex flex-column gap-4">
                          <div className="w-100 input-section ">
                            <label htmlFor="usr" className="level-text font-5">
                              {t("List Of Table")} 
                            </label>
                            <input
                              type="text"
                              name="tablesName"
                              value={formDataForAI.tablesName}
                              onChange={(e) =>
                                setFormDataForAI((prev) => {
                                  return { ...prev, tablesName: e.target.value };
                                })
                              }
                              className="input-field-shadow font-4"
                              id="usr"
                            />
                               {/* {validationError && linesSensitiveData.length===0 &&formDataForAI.tablesName === '' && <p className='text-danger font-4 fs-6'> Tables Name is required </p>} */}

                          </div>
                          <div className=" w-100">
                            <label htmlFor="usr" className="level-text font-5">
                              {t("Config Set")} *
                            </label>
                            {/* <select
                              className="input-field-shadow font-4"
                              value={formDataForAI.configSetId}
                              onChange={(e) =>
                                setFormDataForAI((prev) => {
                                  return { ...prev, configSetId: e.target.value };
                                })
                              }
                            >
                              <option>Select an Option</option>
                              {configSetData &&
                                configSetData.map((val, e) => (
                                  <option key={e} value={val.id}>
                                    {val.configSetName}
                                  </option>
                                ))}
                            </select> */}                           
                              <Select
                                value={options.find(option => option.value === formDataForAI.configSetId)}
                                onChange={handleSelectChange}
                                options={options}
                                placeholder="Select an Option"
                                isSearchable
                                styles={selectDropdownStyle}
                                menuPlacement="auto"
                              />
                              {validationError && formDataForAI.configSetId === '' && <p className='text-danger font-4 fs-6'> Config Set is required </p>}

                          </div>
                        </div>
                          <div className="w-50 cursor-pointer btn-low-purple" onClick={addLineSensitiveData}><BiAddToQueue /> Detect Sensitive Data</div>
                          <div className="w-100 d-flex flex-column gap-4 mb-3 lineWrapper">
                            {linesSensitiveData.map((line, index) => (
                              <div key={index} className="w-100 d-flex align-items-center justify-content-between line-container">
                                <SensitiveData handleInputChange={handleInputChangeSensitiveData} data={line} index={index} required={validationError}/>
                                <div className="cursor-pointer" onClick={() => removeLine(index)}><TiDeleteOutline size={25} /></div>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-100 justify-content-between util-footer">
                <div className="col1">
                  <div className="cancle">
                    <button
                      type="button"
                      className="res-back-btn font-5"
                      onClick={() => {setFormData(initialFormData)
                        setLinesSensitiveData([])
                        setFormDataForAI(initialFormDataForAI)
                        setValidationError(false)
                      }}
                    >
                      <RxCross2 color="#344054" /> {t("Cancel")}
                    </button>
                  </div>
                </div>
                <div className="col3">
                  <button
                    type="submit"
                    className={`btn-low-purple font-5 ${isdisabled ? "disabled" : ""
                      }`}
                    onClick={(e) => onSaveChangeOfUtilitySetPage(e, false)}
                  >
                    <FaRegSave color={isdisabled ? "#D6BBFB" : "#FFFFFF"} />{" "}
                    {utilityId ? t("Update & Close") : t("Save & Close")}
                  </button>

                  <button
                    className={`btn-purple font-5 ${isdisabled ? "disabled " : " "
                      }`}
                  // onClick={(e) => onSaveChangeOfUtilitySetPage(e, true)}
                  >
                    <BsArrowRight color="#FFFFFF" />
                    {utilityId
                      ? t("Update & Next")
                      : aiCreation
                        ? t("Schedule an AI job")
                        : t("Next")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Utility;
