import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getColumnOfJob,
  getJobsByID,
  getJobStepTables,
} from "../../hooks/apis/useAppSafeApi";
import { BiArrowBack } from "react-icons/bi";
import "./ExtendedHistory.scss";
import CircleProgressBar from "../../components/CircleProgressBar/CircleProgressBar";
import TablePro from "../../components/newTable/TablePro";
import MultiColourProgressBar from "../../components/MultiColourProgressBar/MultiColourProgressBar";

import "../../components/ExecutedTable/ExecutedTable.scss";
import Condition from "./Condition";
import { useTranslation } from "react-i18next";

const ExtendedHistory = () => {
  const { t } = useTranslation("history");

  const { id } = useParams();
  const [jobs, setJobs] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tableData, setTableData] = useState({});
  const [selectedStepId, setSelectedStepId] = useState(null);
  const [tableError, setTableError] = useState(null);
  const [selectedStepData, setSelectedStepData] = useState(null);
  const [name, setName] = useState(null);
  const [currentTime, setCurrentTime] = useState(Date.now());

  const [columnData, setColumnData] = useState(null);
  const [selectedTable, setSelectedTable] = useState(null);
  const [columnError, setColumnError] = useState(null);
  const [conditions, setConditions] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState(null);

  const [isOpenCondition, setIsOpenCondition] = useState(false);
  const [isOpenConditionPanel, setIsOpenConditionPanel] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(true);
      try {
        const data = await getJobsByID(id);
        setJobs(data);
        console.log("data:: ", data);
        if (data.steps && data.steps.length > 0) {
          const firstStepId = data.steps[0].id;
          setSelectedStepId(firstStepId);
          const firstStepData = await getJobStepTables(firstStepId);
          setTableData((prev) => ({ ...prev, [firstStepId]: firstStepData }));
          setSelectedStepData(firstStepData);

          // Set the first condition by default
          if (data.steps[0].conditions && data.steps[0].conditions.length > 0) {
            setConditions(data.steps[0].conditions);
            setSelectedCondition(data.steps[0].conditions[0]);
          }
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, [id]);

  // console.log(jobs.steps[0].status);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const fetchTableData = async (stepId) => {
    try {
      setTableError(null);
      const data = await getJobStepTables(stepId);
      setTableData((prev) => ({ ...prev, [stepId]: data }));
    } catch (err) {
      console.error("Failed to fetch table data", err);
      setTableError(err.message);
    }
  };

  useEffect(() => {
    if (jobs.steps) {
      jobs.steps.forEach((step) => {
        fetchTableData(step.id);
      });
    }
  }, [jobs]);

  const handleStepClick = async (stepId) => {
    setSelectedStepId(stepId);
    const step = jobs.steps.find((s) => s.id === stepId);
    // console.log(step.conditions);
    setConditions(step.conditions || []);

    // Select the first condition by default
    if (step.conditions && step.conditions.length > 0) {
      setSelectedCondition(step.conditions[0]);
    }

    fetchTableData(stepId);
    try {
      const data = await getJobStepTables(stepId);
      setSelectedStepData(data);
    } catch (err) {
      console.error("Failed to fetch step data", err);
      setSelectedStepData(null);
    }
  };

  const rowClassName = (row) => {
    return row.id === selectedStepId ? "selected-row" : "";
  };

  const calculateTimeDifference = (startTime, endTime) => {
    const end = endTime || currentTime;
    const difference = end - startTime;
    const minutes = Math.floor(difference / 60000);
    const seconds = ((difference % 60000) / 1000).toFixed(0);
    return `${minutes} min ${seconds < 10 ? "0" : ""}${seconds} sec`;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!jobs || !Object.keys(jobs).length) {
    return <div>No job data available</div>;
  }

  const tableDataToShow = selectedStepData || [];

  const fetchColumnData = async (stepId, tableId) => {
    try {
      const data = await getColumnOfJob(stepId, tableId);
      setColumnData(data);
      setColumnError(null);
    } catch (err) {
      setColumnError(err.message);
      setColumnData(null);
    }
  };

  // console.log(conditions);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };
    return date.toLocaleString("en-US", options);
  };

  const aHeadersForAppSafe = [
    {
      name: t("Table Name"),
      field: "tableName",
      type: "customControl",
      width: "5%",
      fnRenderCustomControl: (item) => {
        return (
          <div
            className="font-4 cursor-pointer"
            onClick={() => {
              setSelectedTable(item);
              fetchColumnData(selectedStepId, item.id);
              setName(item.tableName);
            }}
          >
            {item.tableName}
          </div>
        );
      },
    },
    {
      name: t("Table ID"),
      field: "tableId",
      type: "text",
      width: "5%",
    },
    {
      name: t("#Entries"),
      field: "rowsCount",
      type: "text",
      display: "date",
      width: "5%",
    },
    {
      name: t("Fields"),
      field: "fields",
      type: "text",
      width: "5%",
    },
    {
      name: t("Run Status"),
      field: "status",
      type: "status",
      width: "5%",
    },
    {
      name: t("Run Status"),
      field: "status",
      type: "customControl",
      width: "5%",
      fnRenderCustomControl: (row) => {
        return (
          <MultiColourProgressBar
            redP={(row.recordFailed / row.recordProcessed) * 100}
            // blueP={row.running}
            PurpleP={
              ((row.recordProcessed - row.recordFailed) / row.recordProcessed) *
              100
            }
            // gryP={100 - row.recordFailed}
          />
        );
      },
    },
  ];

  const HeaderForFields = [
    {
      name: t("Table Name"),
      field: "fieldName",
      type: "customControl",
      width: "25%",
      fnRenderCustomControl: (item) => {
        return <div className="font-4 cursor-pointer">{name}</div>;
      },
    },
    {
      name: t("Column Name"),
      field: "columnName",
      type: "text",
      width: "25%",
    },
    {
      name: t("Utility Param"),
      field: "utilityParam",
      type: "text",
      width: "25%",
    },
    {
      name: t("Records Processed"),
      field: "recordProcessed",
      type: "text",
      width: "25%",
    },
    {
      name: t("Records Failed"),
      field: "recordFailed",
      type: "text",
      width: "25%",
    },
    {
      name: t("Utility Param Condition"),
      field: "utilityParamCondition",
      type: "text",
      width: "25%",
    },
    {
      name: t("Privacy Relevance"),
      field: "privacyRelevance",
      type: "text",
      width: "25%",
    },
  ];

  const progress = jobs.recordTotal;
  const recordFailed = (jobs.recordFailed / progress) * 100;
  const recordProcessed = (jobs.recordProcessed / progress) * 100;

  return (
    <>
      <div className="main-section">
        <div className="header-section-maya">
          <div className="page-header">
            <div
              className="cursor-pointer res-back-btn font-5"
              onClick={() => navigate(-1)}
            >
              <BiArrowBack /> {t("Jobs")}
            </div>
            <div className="heading1 font-5">{jobs.name || ""}</div>
          </div>
          <div className="header-button"></div>
        </div>

        <div className="body-section-history">
          <div className="s-runing">
            <div className="sec-1-run">
              <div className="part1">
                <p className="top-head font-4">
                  {t("Processing")} <span>{jobs.recordProcessed || 0}</span> /{" "}
                  <span>{jobs.recordTotal || 0}</span> {t("Values")}
                </p>
                <div className="pro-bar d-flex">
                  {jobs.status === "FINISHED" || jobs.recordProcessed === 0 ? (
                    <CircleProgressBar
                      size={190}
                      progress={
                        jobs.recordProcessed === 0
                          ? 0
                          : (
                              ((jobs.recordProcessed - jobs.recordFailed) /
                                jobs.recordProcessed) *
                              100
                            ).toFixed(2)
                      }
                      trackWidth={15}
                      indicatorWidth={15}
                      circleCount={1}
                      indicatorColor={`#9E77ED`}
                      trackColor={jobs.recordFailed !== 0 ? "#E31B54" : `#ddd`}
                    />
                  ) : (
                    <CircleProgressBar
                      size={190}
                      progress={(jobs.recordProcessed / jobs.recordTotal) * 100}
                      trackWidth={15}
                      indicatorWidth={15}
                      circleCount={3}
                      indicatorColor={`#6941C6`}
                      innerCircleRadiusSpace={20}
                      innerCircleIndicatorColors={[`#9E77ED`, `#E31B54`]}
                      innerCircleIndicatorProgress={[
                        ((jobs.recordProcessed - jobs.recordFailed) /
                          jobs.recordProcessed) *
                          100,
                        (jobs.recordFailed / jobs.recordProcessed) * 100,
                      ]}
                    />
                  )}

                  <div className="progressBar-details w-50">
                    {jobs.status !== "FINISHED" && (
                      <div className="record-list font-4 ">
                        {t("Processed")}:{" "}
                        <div className=" record record-processed"></div>
                      </div>
                    )}
                    <div className="record-list font-4">
                      {t("Failed")}:{"  "}{" "}
                      <div className="record record-failed"></div>
                    </div>
                    <div className="record-list font-4">
                      {t("Success")}:{"  "}{" "}
                      <div className="record record-success"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="part2">
                <div className="part-2-1">
                  <div className="head-text-1 s font-4">{t("Run Name")}</div>
                  <div className="part-2-subtext">
                    <div className="sub-text-1 s font-6">{jobs.name || ""}</div>
                  </div>
                </div>
                <div className="part-2-1 pt-4">
                  <div className="head-text-1 s font-4">{t("Run Type")}</div>
                  <div className="part-2-subtext">
                    <div className="sub-text-1 s font-4">
                      {jobs.executionType || ""}
                    </div>
                  </div>
                </div>
                <div className="part-2-1 pt-4">
                  <div className="head-text-1 s font-4">{t("Started")}</div>
                  <div className="part-2-subtext">
                    <div className="sub-text-1 s font-4">
                      {jobs.createdBy && <>{jobs.createdBy.split("@")[0]}</>}
                    </div>
                    <div className="sub-in-2 s font-4">
                      {jobs.startTime && formatDate(jobs.startTime)}
                    </div>
                  </div>
                </div>
                <div className="part-2-1 pt-4">
                  <div className="head-text-1 s font-4">{t("Finished")}</div>

                  <div>
                    {jobs.steps &&
                      [...jobs.steps].map((step) => (
                        <div
                          key={step.id}
                          onClick={() => handleStepClick(step.id)}
                          className={`part-2-subtext cursor-pointer ${
                            step.id === selectedStepId ? "selected-row" : ""
                          }`}
                        >
                          <div className="sub-text-1 s font-4">
                            Part {step.step} {step.type}
                          </div>
                          <div className="sub-in-2 s font-5">{step.status}</div>
                          <div className="sub-in-2 s font-4">
                            {calculateTimeDifference(
                              step.startTime,
                              step.status === "RUNNING" ? null : step.endTime
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="font-4 padding">
            {jobs.steps && jobs.steps.length} {t("Tables")}
          </div>
          {tableDataToShow.length === 0 && jobs.type !== "UTILITY" ? (
            <div>{t("msg")}</div>
          ) : (
            <>
              <TablePro
                columns={aHeadersForAppSafe}
                data={tableDataToShow}
                minRows={5}
                rowClassName={rowClassName}
              />
              <Condition
                data={conditions}
                isOpenCondition={isOpenCondition}
                setIsOpenCondition={setIsOpenCondition}
              />

              <div className="font-4 padding">
                {columnData ? (
                  <div>
                    <div className="font-4 padding">
                      {columnData.length} {t("Fields")}
                    </div>
                    <TablePro
                      columns={HeaderForFields}
                      data={columnData}
                      minRows={5}
                      rowClassName={rowClassName}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ExtendedHistory;
