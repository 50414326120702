import React from 'react'
import { InputSection } from '../../../components/FieldSection.jsx/field'

const Sharepoint = ({ formDataForSource, handleChange, id, subField, error }) => {
    let siteName = '', directoryPath = '', clientId = '', tenantId = '',clientSecret='';
    if (subField === 'sourceConfig') {
        siteName = formDataForSource?.sourceConfig?.config?.siteName
        directoryPath = formDataForSource?.sourceConfig?.config?.directoryPath;
        clientId = formDataForSource?.sourceConfig?.config?.clientId
        tenantId = formDataForSource?.sourceConfig?.config?.tenantId
        clientSecret = formDataForSource?.sourceConfig?.config?.clientSecret
    } else if (subField === 'targetConfig') {
        siteName = formDataForSource?.targetConfig?.config?.siteName
        directoryPath = formDataForSource?.targetConfig?.config?.directoryPath;
        clientId = formDataForSource?.targetConfig?.config?.clientId
        tenantId = formDataForSource?.targetConfig?.config?.tenantId
        clientSecret = formDataForSource?.targetConfig?.config?.clientSecret   
    }
    return (
        <div className=' d-flex  flex-column gap-3'>
            <InputSection label={"Site Name"} name="siteName"  value={siteName}
                onChange={(e) => handleChange(e, subField, 'config', 'siteName')} required={true} error={error}  />
            <InputSection label={"Directory Path"} name="directoryPath"  value={directoryPath}
                onChange={(e) => handleChange(e, subField, 'config', 'directoryPath')} required={true} error={error}  />
            <InputSection label={"Client Id"} name="clientId"  value={clientId} onChange={(e) => handleChange(e, subField, 'config', 'clientId')} required={true} error={error}  />
            <InputSection label={"Tenant Id"} name="tenantId"  value={tenantId} onChange={(e) => handleChange(e, subField, 'config', 'tenantId')} required={true} error={error}  />
            <InputSection label={"Client Secret"} name="clientSecret"  value={clientSecret} onChange={(e) => handleChange(e, subField, 'config', 'clientSecret')} required={true} error={error}  />

        </div>
    )
}

export default Sharepoint