import React from "react";
import { LuArrowLeftFromLine, LuArrowRightFromLine } from "react-icons/lu";
import { Tooltip } from "react-tooltip";
import logo from "../../logoWhite.png";

const SidebarHeader = ({ isCollapsed, toggleSidebar }) => (
  <div className="sidebar_heading">
    <div className="sidebar_maya">
      <img src={logo} alt="Maya Data Privacy Logo" width="40" height="40" />
      <span className="aboutLogo font-4">Maya Data Privacy</span>
    </div>
    <div className="main_toggle-button" onClick={toggleSidebar}>
      {isCollapsed ? (
        <>
          <LuArrowRightFromLine
            data-tip
            data-for="expandTip"
            className="my-anchor-element"
          />
          <Tooltip
            anchorSelect=".my-anchor-element"
            place="bottom"
            className="example"
          >
            Expand Sidebar
          </Tooltip>
        </>
      ) : (
        <>
          <LuArrowLeftFromLine className="collapseTip" />
          <Tooltip anchorSelect=".collapseTip" place="top">
            Collapse Sidebar
          </Tooltip>
        </>
      )}
    </div>
  </div>
);

export default SidebarHeader;
