import "./App.css";
import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import * as Pages from "./pages";
import * as Components from "./components";
import { NewRunDataProvider } from "./hooks/NewRunContext";
import useAppContext, { AuthState } from "./hooks/useAppContext";
import Dashboard from "./pages/dasboard/Dashboard";
import Chat from "./pages/AiSafe/Chat";

const App = () => {
  const { authState } = useAppContext();

  if (authState === AuthState.loading) {
    return <Components.BusySpinner message={"Loading..."} />;
  }

  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Components.PrivateRoute />}>
          <Route index element={<Navigate to="/landing" replace />} />
          <Route element={<NewRunDataProvider />}>
            <Route exact path="/newrun" element={<Pages.NewRun />} />
            <Route exact path="/edit" element={<Pages.Duplicate />} />
          </Route>
          <Route path="dashboard">
            <Route path="fa" element={<Pages.FileAnonymization />} />
            <Route path="db" element={<Components.DBAnonymization />} />
          </Route>
          <Route path="/dash" element={<Components.Dashboard />} />

          <Route path="/landing" element={<Dashboard />} />
          {/* <Route path="/test2" element={<Components.Test2 />} /> */}
          <Route path="/definitions/settings" element={<Pages.Settings />} />
          <Route
            path="/Configuration-Home"
            element={<Pages.ConfigurationHome />}
          />
          <Route path="/configuration/:id?" element={<Pages.Configuration />} />
          <Route path="utility/:utilityId?" element={<Pages.Utility />} />
          <Route
            path="/utilitySetHomePage"
            element={<Pages.UtilitySetHomePage />}
          />
          <Route exact path="/card" element={<Components.PriceCard />} />
          <Route path="/to-up-coins" element={<Pages.TopUpCoins />} />
          <Route path="/executionset/:id?" element={<Pages.ExecutionSet />} />
          <Route path="/Execution-Home" element={<Pages.ExecutionHome />} />
          <Route path="/history" element={<Pages.History />} />
          <Route path="/history/:id?" element={<Pages.ExtendedHistory />} />
          <Route
            path="/utility-set-table/:encodedUtilityId?"
            element={<Pages.UtilityPageTwo />}
          />
          <Route path="/executedTable" element={<Components.ExecutedTable />} />
          <Route path="/ai-powered" element={<Pages.AiPowered />} />
          <Route
            path="/CircleProgressBar"
            element={<Components.CircleProgressBar />}
          />
          <Route
            path="/linerP"
            element={<Components.MultiColourProgressBar />}
          />
          <Route path="/runing" element={<Pages.Running />} />
          <Route path="/administration" element={<Pages.Administration />} />
          <Route path="/Profile" element={<Pages.Profile />} />
          <Route path="/ai-safe/:id?" element={<Pages.AiSafe />} />
          <Route path="/ai-safe/c/:id?" element={<Chat />} />
        </Route>

        <Route element={<Pages.AuthRoot />}>
          <Route
            path="/Configuration-Home"
            element={<Pages.ConfigurationHome />}
          />
          <Route exact path="/login" element={<Pages.Login />} />
          <Route exact path="/register" element={<Pages.Register />} />
          <Route
            exact
            path="/forgotPassword"
            element={<Pages.ForgotPassword />}
          />
          <Route path="*" element={<Pages.PageNotFound />} />
          <Route exact path="/newrun" element={<Pages.NewRun />} />
        </Route>
      </Routes>
    </div>
  );
};

export default App;
