import React from 'react'
import './Style.css';
import { useNavigate } from "react-router-dom";
import { Col, Row, Button } from 'react-bootstrap';
import { FiEdit2 } from "react-icons/fi";
import { GiTwoCoins } from "react-icons/gi";
import Colone from './Colone'
import Coltwo from './Coltwo';


function Uper() {
  let navigate = useNavigate();
  const purchase_coins = () => {
    //  console.log("function for purchase coin button");
    navigate("/to-up-coins")
  };
  const edit_org = () => {
    // console.log("function for purchase coin button");
 };
  return (
    <div>
       
      <div className='section_one'>
        <h1 className='text1' >Manage Organization</h1>
        <p className='text2'>Manage Organization Details, Credits & Users</p>
      </div>



      <div className='section_two'>
        <Row>
          <Col>
            <p className='text3'>Organisation Information</p>
          </Col>
          <Col className='cltwo'>
            <div className='button_secton'>
              <div className='button_one'>
                <Button className='info1' onClick={edit_org}><FiEdit2 /> Edit Organisation</Button>

              </div>
              <div className='button_two'>
                <Button className='info2' onClick={purchase_coins}><GiTwoCoins /> Purchase Coins</Button>

              </div>
            </div>


          </Col>
        </Row>
      </div>



      <div className='row section_three'>
        <div className="col-7"><Colone/></div> 
        <div className="col-5"><Coltwo/></div> 

      </div>







    </div>
  )
}

export default Uper;
