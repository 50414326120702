import "./TopUpCoins.scss";
// import CheckoutForm from "../../components/checkoutForm/CheckoutForm";
import { useState, useEffect, useContext } from "react";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
import PriceCard from "../../components/priceCard/PriceCard";
import { UserContext } from "../../hooks/UserContext";
import Toast from "../../components/toast/Toast";
import axios from "axios";

const data = [
  {
    name: "Free",
    features: [
      "File Types: CSV",
      "Date Algorithms: Random, Same Year, Consistent, Adult",
      "Text Algorithms: Email, Names, Clear Values",
      "Number Algorithms: Randomization",
    ],
    credits: [{ recordCount: 1000, price: 0 }],
  },
  {
    name: "Standard",
    features: [
      'All from "Free"',
      "File Types: XLSX",
      "Multiple files with consistency",
      "Manage up to 5 Users",
      "Re-use",
    ],
    credits: [
      {
        recordCount: 10000,
        price: { m: { value: 10, priceId: "" }, y: { value: 8, priceId: "" } },
      },
      {
        recordCount: 25000,
        price: { m: { value: 22, priceId: "" }, y: { value: 18, priceId: "" } },
      },
      {
        recordCount: 50000,
        price: { m: { value: 40, priceId: "" }, y: { value: 32, priceId: "" } },
      },
    ],
  },
  {
    name: "Business",
    features: [
      'All from "Standard"',
      "File Types: SAS, PARQUET",
      "Available Algorithms: DP, Phone No, IDs and more",
      "Dedicated Support",
      "User Management",
    ],
  },
];

const TopUpCoins = () => {
  const [paymentYearly, setPaymentYearly] = useState(false);
  const [cardsData, setCardsData] = useState([]);
  const { setLoading } = useContext(UserContext);
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [paymentPlans, setPaymentPlans] = useState([]);

  useEffect(() => {
    updateCardsData();
  }, []);
  useEffect(() => {
    updateCardsData();
  }, [paymentYearly, paymentPlans]);
  useEffect(() => {}, [cardsData]);

  useEffect(() => {
    getPricePlans();
  }, []);

  const getPricePlans = () => {
    //busy dialog on
    setLoading(true);
    axios
      .get(`/mdp/api/price`)
      .then((res) => {
        const aPlans = res.data;
        let aStdPlans, aMonthlySub, aYearlySub;
        if (aPlans.length > 0) {
          aStdPlans = aPlans
            .filter((item) => item.planType === "STANDARD")
            .sort((a, b) => a.coin - b.coin);

          let aCredits = [];
          let tempMap = new Map();
          let tempMapItem;

          aStdPlans.forEach((item) => {
            if (tempMap.has(item.coin)) {
              tempMapItem = tempMap.get(item.coin);
              if (item.recurrence === 1) {
                tempMapItem.price.m = {
                  value: item.totalAmount > 0 ? item.totalAmount / 100 : item.totalAmount,
                  priceId: item.priceId,
                };
              } else if (item.recurrence === 12) {
                tempMapItem.price.y = {
                  value: item.totalAmount > 0 ? item.totalAmount / 100 : item.totalAmount,
                  priceId: item.priceId,
                };
              }
              tempMap.set(item.coin, tempMapItem);
            } else {
              tempMapItem = {
                recordCount: item.coin,
                price: {
                  m: { value: 0, priceId: "" },
                  y: { value: 0, priceId: "" },
                },
              };
              if (item.recurrence === 1) {
                tempMapItem.price.m = {
                  value: item.totalAmount > 0 ? item.totalAmount / 100 : item.totalAmount,
                  priceId: item.priceId,
                };
              } else if (item.recurrence === 12) {
                tempMapItem.price.y = {
                  value: item.totalAmount > 0 ? item.totalAmount / 100 : item.totalAmount,
                  priceId: item.priceId,
                };
              }
              tempMap.set(item.coin, tempMapItem);
            }
          });

          tempMap.forEach((value, key) => {
            aCredits.push(value);
          });

          let tempPaymentPlans = data.map((item) => {
            if (item.name === "Standard") {
              item.credits = aCredits;
            }
            return item;
          });
          setPaymentPlans(tempPaymentPlans);
        }

        // process data, add plans data mapping to cards
      })
      .catch((oError) => {
        console.error(`Reading Payment Plans ::::: ${oError}`);
        setToastMessage("Failed to read Payment Plans data.");
        setShowToast(true);
      })
      .finally(() => {
        //busy dialog of
        setLoading(false);
      });
  };

  const updateCardsData = () => {
    if (paymentPlans.length === 0) {
      return;
    }
    let aData = paymentPlans.map((item) => {
      let aCredits = [];
      let newItem = { ...item };
      if (item.credits && item.credits.length > 0) {
        if (!paymentYearly) {
          //means payment monthly
          aCredits = item.credits.map((credit) => {
            let price;
            let priceId;
            if (credit.price.m) {
              price = credit.price.m.value;
              priceId = credit.price.m.priceId;
            }
            return {
              recordCount: credit.recordCount,
              price: price || credit.price,
              priceId: priceId,
            };
          });
        } else {
          //means payment yearly
          aCredits = item.credits.map((credit) => {
            let price;
            let priceId;
            if (credit.price.y) {
              price = credit.price.y.value;
              priceId = credit.price.y.priceId;
            }
            return {
              recordCount: credit.recordCount,
              price: price || credit.price,
              priceId: priceId,
            };
          });
        }
      }
      // debugger;
      newItem.credits = aCredits;
      return newItem;
    });

    setCardsData([...aData]);
  };

  const handlePaymentPeriodSwitch = (e) => {
    setPaymentYearly(!paymentYearly);
  };

  return (
    <div className="t_wrapper">
      {/* <p className="infoMessage">
        Do not use real card! Top-up process running in test mode.
      </p> */}
      <div className="PricingToggle">
        <span>Monthly</span>
        <input
          type="checkbox"
          id="switch"
          checked={paymentYearly}
          onChange={handlePaymentPeriodSwitch}
        />

        <label htmlFor="switch"></label>
        <span>Annually</span>
      </div>
      <div className="topUpContainerWrapper">
        {cardsData.map((item, index) => {
          return (
            <PriceCard
              key={index}
              name={item.name}
              features={item.features}
              range={item.credits}
            />
          );
        })}
        {/* <PriceCard />
      <PriceCard />
      <PriceCard /> */}
      </div>
      <Toast
        message={toastMessage}
        show={showToast}
        hideToast={() => setShowToast(false)}
      />
    </div>
  );
};

export default TopUpCoins;
