import React from 'react';
import ReviewsProvider from './ReviewsProvider';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ReviewsBar = (props) => {
  const { score } = props;

  // function for calculating the color
  const calcColor = (percent, start, end) => {
    let a = percent / 100,
      b = (end - start) * a,
      c = b + start;

    // return an CSS hsl color string
    // return 'hsl(' + c + ', 100%, 50%)';
  };

  return (
    <ReviewsProvider valueStart={0} valueEnd={score}>
      {(value) => (
        <CircularProgressbar
          value={value}
          text={`${value}% Coins Used`}
           
          circleRatio={0.5} /* Make the circle only 0.7 of the full diameter */
          styles={{
            trail: {
              strokeLinecap: 'butt',
              transform: 'rotate(-80deg)',
              transformOrigin: 'center center',
              stroke: '#d6d6d6',
              strokeLinecap: 'round',
            },
            path: {
              strokeLinecap: 'round',
              transform: 'rotate(-102deg)',
              transformOrigin: 'center center',
              // stroke: calcColor(value, 0, 120),
              stroke: '#7F56D9',
            },
            text: {
              fill: '#6e6e6e',
              fontSize: '9px',
              
            },
          }}
          strokeWidth={9}
        />
      )}
    </ReviewsProvider>
  );
};

export default ReviewsBar;